import { FC, useCallback, useState } from "react";
import { gql, useMutation } from '@apollo/client';
import { Badge, Button } from "@preferral/ui";
import toast from "react-hot-toast";

const IMPORT_AMD_USER = gql`
  mutation ImportAmdUser($amdUserId: Int!) {
    importAmdUser(amdUserId: $amdUserId) {
      errors {
        key
        message
      }
      user {
        id
      }
    }
  }
`;

interface Data {
  importAmdUser: {
    errors?: InputError[];
    user?: {
      id: string;
    }
  }
}

interface Variables {
  amdUserId: number;
}

/**
 * ImportAmdUserButton.
 */

interface ImportAmdUserButtonProps {
  amdUserId: number
};

export const ImportAmdUserButton: FC<ImportAmdUserButtonProps> = props => {
  const { amdUserId } = props;

  const [success, setSuccess] = useState(false);

  const [importAmdUser, { loading: submitting }] = useMutation<Data, Variables>(IMPORT_AMD_USER)

  const submit = useCallback(async () => {
    try {
      const { data } = await importAmdUser({ variables: { amdUserId } });

      if (data?.importAmdUser.errors) {
        const { message } = data?.importAmdUser.errors[0];
        toast.error(`Import user failed: ${message}`)
      } else if (data?.importAmdUser.user) {
        // it worked...
        toast.success("User imported!")
        setSuccess(true);
      }
    } catch (e) {
      toast.error("Something went wrong.");
      console.error(e);
    }
  }, [importAmdUser, amdUserId]);

  if (success) {
    return (
      <Badge color="green">Imported</Badge>
    )
  }

  return (
    <Button
      type="button"
      kind="primary"
      size="xs"
      isLoading={submitting}
      onClick={submit}
    >
      Import User
    </Button>
  );
};
