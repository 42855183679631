import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Modal, ModalHeader } from "components/Modal";
import { Spinner, Button } from "@preferral/ui";
import { AdminSelectField } from "components/formik/AdminSelectField";

const ACCOUNT_CSM = gql`
  query GetAccountCSM($id: UUID4!) {
    account(id: $id) {
      id
      csm {
        id
        firstName
        lastName
      }
    }
  }
`;

interface Data {
  account: {
    id: string;
    csm?: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
}

const ASSIGN_ACCOUNT_CSM = gql`
  mutation AssignAccountCSM($id: UUID4!, $csmId: UUID4!) {
    assignAccountCsm(id: $id, csmId: $csmId) {
      errors {
        key
        message
      }
      account {
        id
      }
    }
  }
`;

interface MutationData {
  assignAccountCsm: {
    errors?: InputError[];
    account?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  csmId: string;
}

interface FormValues {
  csmId: string;
}

interface AssignAccountCSMModalProps {
  accountId: string;
  isOpen: boolean;
  onClose(): void;
  refetchQueries: any;
}

export const AssignAccountCSMModal: FC<AssignAccountCSMModalProps> = (
  props
) => {
  const { accountId, isOpen, onClose, refetchQueries } = props;
  const { loading, data, error } = useQuery<Data>(ACCOUNT_CSM, {
    variables: { id: accountId },
  });

  const [assignCSM] = useMutation<MutationData, MutationVariables>(
    ASSIGN_ACCOUNT_CSM
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      return assignCSM({
        variables: { id: accountId, csmId: values.csmId },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp.data?.assignAccountCsm.errors) {
            setStatus({ errors: resp.data.assignAccountCsm.errors });
          } else if (resp.data?.assignAccountCsm.account) {
            // it worked...
            return onClose();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
          throw new Error(rej);
        }
      );
    },
    [assignCSM, accountId, onClose]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="pencil-alt" title="Assign CSM" onClose={onClose} />
      <div className="p-4">
        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.account ? (
          <p>Failed to load.</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              csmId: data.account.csm?.id || "",
            }}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />

                <AdminSelectField name="csmId" label="CSM" />

                <div className="mt-5 flex justify-end">
                  <span className="rounded-md shadow-sm">
                    <Button type="button" color="gray" onClick={onClose}>
                      Cancel
                    </Button>
                  </span>
                  <span className="rounded-md shadow-sm ml-2">
                    <Button type="submit" color="blue" disabled={isSubmitting}>
                      Update
                    </Button>
                  </span>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
