import React, { FC } from "react";

interface OrganizationProvidersProps {
  organizationId: string;
}

export const OrganizationProviders: FC<OrganizationProvidersProps> = (
  props
) => {
  const { organizationId } = props;
  return (
    <div className="OrganizationProviders">
      <p>OrganizationProviders</p>
      <p>{organizationId}</p>
    </div>
  );
};
