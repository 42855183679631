import { FC } from "react";
import { ArrayHelpers } from "formik";
// import {
//   DropResult,
//   DragDropContext,
//   Droppable,
//   Draggable,
// } from "react-beautiful-dnd";
import { FAIcon, TextInput } from "@preferral/ui";
import { QuestionModel } from "components/Questionnaire";

interface AnswerOptionsProps extends ArrayHelpers {
  prefix: string;
  sectionIndex: number;
  questionIndex: number;
  question: QuestionModel;
  options: string[];
}

export const AnswerOptions: FC<AnswerOptionsProps> = props => {
  const {
    prefix,
    sectionIndex,
    questionIndex,
    question,
    options,
    insert,
    remove,
  } = props;
  // move,

  // const onDragEnd = useCallback(
  //   (result: DropResult) => {
  //     if (result.destination) {
  //       move(result.source.index, result.destination.index);
  //     }
  //   },
  //   [move]
  // );

  return (
    <>
      <label className="text-xs font-semibold text-gray-500">Options:</label>
      <div className="py-4">
        {(options || []).map((option, idx) => (
          <div
            key={`${question.id}--option-${idx}`}
            className="p-1 bg-white flex items-center mb-2 rounded">
            {/* <div {...provided.dragHandleProps}>
                        <Icon icon="reorder" />
                      </div> */}
            <div className="flex-1 mx-2 border-b border-gray-400">
              <TextInput
                name={`${prefix}.sections.${sectionIndex}.questions.${questionIndex}.options.${idx}`}
                placeholder="Option"
                className="border-0 text-sm font-semibold py-1"
                icon="pencil-alt"
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm btn-red-alt"
                onClick={() => remove(idx)}
              >
                <FAIcon icon="trash" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="btn btn-sm btn-purple-alt"
          onClick={() => insert(options.length, "New Option")}
        >
          Add Option
        </button>
      </div>
    </>
  );
};
