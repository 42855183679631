import React, { FC } from "react";
import { FormikProps, ArrayHelpers, FieldArray } from "formik";
import { FAIcon, Icon, TextInput, TextAreaField } from "@preferral/ui";
import { SectionModel, QuestionnaireModel, QuestionModel } from "components/Questionnaire";
import { NewTooltip } from "components/NewTooltip";
import { EditQuestion } from "./EditQuestion";
import { newQuestion } from "./helpers";
import { useState } from "react";
import { Button } from "@preferral/ui";

/**
 * Questions.
 */

interface QuestionsProps extends ArrayHelpers {
  prefix: string;
  openEditingQuestionRule: (sectionIdx: number, questionIdx: number) => void;
  sectionIndex: number;
  questions: QuestionModel[];
  form: FormikProps<QuestionnaireModel>;
}

const Questions: FC<QuestionsProps> = props => {
  const {
    prefix,
    openEditingQuestionRule,
    sectionIndex,
    questions,
    insert,
    remove,
  } = props;

  // const onDragEnd = useCallback(
  //   (result: DropResult) => {
  //     if (result.destination) {
  //       move(result.source.index, result.destination.index);
  //     }
  //   },
  //   [move]
  // );

  const [editingQuestionIds, setEditingQuestionIds] = useState<string[]>([]);

  function editQuestion(questionId: string) {
    setEditingQuestionIds([questionId, ...editingQuestionIds]);
  }

  function finishQuestion(questionId: string) {
    setEditingQuestionIds(editingQuestionIds.filter(qId => qId !== questionId));
  }

  return (
    <>
      <div className="py-4">
        {questions.length === 0 ? (
          <div>
            <p className="px-4 py-6 text-gray-700">
              No questions in this section yet...
            </p>
            <button
              type="button"
              className="border border-gray-400 hover:bg-gray-200 leading-none p-1 px-4 py-2 rounded-full text-blue-600 text-sm focus:outline-none focus:shadow-outline"
              onClick={() => insert(0, newQuestion())}
            >
              <span className="inline-block mr-2 text-center w-4">
                <FAIcon icon="plus" />
              </span>
              Add Question
            </button>
          </div>
        ) : null}
        {questions.map((question, index) => (
          <div key={question.id} className="relative mb-12 flex bg-white border border-gray-300 rounded-lg">
            <div className="py-4 px-2">
              {/* <div {...provided.dragHandleProps}>
                    <Icon icon="reorder" />
                  </div> */}
              <div className="mt-16 w-6 h-6">
                {question.required && (
                  <NewTooltip tip="Required question">
                    <Icon icon="asterisk" color="purple" />
                  </NewTooltip>
                )}
              </div>
              <div className="mt-2 w-6 h-6">
                {!question.defaultVisible && (
                  <NewTooltip tip="Question hidden by default">
                    <Icon icon="view-hidden" color="purple" />
                  </NewTooltip>
                )}
              </div>
              <div className="mt-2 w-6 h-6">
                {question.rules.length > 0 && (
                  <NewTooltip tip="Question has rules">
                    <Icon icon="code" color="purple" />
                  </NewTooltip>
                )}
              </div>
            </div>

            <div className="flex-1 relative">
              {editingQuestionIds.includes(question.id) ? (
                <div>
                  <EditQuestion
                    prefix={prefix}
                    sectionIndex={sectionIndex}
                    index={index}
                    question={question}
                    openEditingQuestionRule={openEditingQuestionRule}
                    onDelete={() => remove(index)}
                  />
                  <Button size="xs" onClick={() => finishQuestion(question.id)}>
                    Finish Edit
                  </Button>
                </div>
              ) : (
                <div>
                  <p>{question.prompt}</p>
                  <Button size="xs" onClick={() => editQuestion(question.id)}>
                    Edit Question
                  </Button>
                </div>
              )}
              <div
                className="absolute mt-2">
                <div className="relative">
                  <div className="-mt-2 absolute bg-gray-300 border-gray-300 border-l h-2 ml-6 top-0" />
                  <button
                    type="button"
                    className="border border-gray-400 hover:bg-gray-200 leading-none p-1 px-4 py-2 rounded-full text-blue-600 text-sm focus:outline-none focus:shadow-outline"
                    onClick={() => insert(index + 1, newQuestion())}
                  >
                    <span className="inline-block mr-2 text-center w-4">
                      <FAIcon icon="plus" />
                    </span>
                    Add Question
                  </button>
                  {index < questions.length - 1 && (
                    <div
                      className="absolute bg-gray-300 border-gray-300 border-l ml-6"
                      style={{ height: 9 }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 right-0 italic pb-2 px-4 text-gray-400 text-xs">
              ID: {question.id}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

/**
 * EditSection.
 */

interface EditSectionProps {
  prefix: string;
  index: number;
  section: SectionModel;
  openEditingQuestionRule(sectionIdx: number, questionIdx: number): void;
  onDeleteSection(): void;
  onAddSection(): void;
};

export const EditSection: FC<EditSectionProps> = React.memo(
  props => {
    const {
      prefix,
      index,
      section,
      openEditingQuestionRule,
      onDeleteSection,
    } = props;

    return (
      <div data-spect-id={section.id} className="EditSection p-4">
        <div className="flex justify-between">
          <div>
            <label className="text-xs font-semibold text-gray-500">
              Section Title
            </label>

            <div className="border-b border-gray-400 w-64">
              <TextInput
                name={`${prefix}.sections.${index}.title`}
                className="border-0 text-3xl font-bold"
                placeholder="Section Title"
                icon="pencil-alt"
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-red-alt"
              onClick={onDeleteSection}
            >
              Delete Section
            </button>
          </div>
        </div>

        <div>
          <TextAreaField
            name={`${prefix}.sections.${index}.text`}
            label="Section Text Body"
            placeholder="Section Text Body"
            indicateOptional
          />
        </div>
        <FieldArray
          name={`${prefix}.sections.${index}.questions`}
          render={(arrayHelpers) => (
            <Questions
              prefix={prefix}
              sectionIndex={index}
              openEditingQuestionRule={openEditingQuestionRule}
              questions={
                arrayHelpers.form.values[prefix].sections[index].questions
              }
              {...arrayHelpers}
            />
          )}
        />
        {/* <button type="button" onClick={onDeleteSection} className="text-red-500">
        Delete
      </button>
      <button type="button" onClick={onAddSection} className="text-blue-500">
        Add
      </button> */}
        {/* <p className="text-xl text-gray-700">{section.title}</p> */}
        {/* {section.questions.map(question => (
        <div key={question.id} className="border border-gray-300 p-4 rounded">
          <EditQuestion question={question} />
        </div>
      ))} */}
      </div>
    );
  }
);
