import { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  TableContainer,
  Table,
  TH,
  TD,
  Spinner,
  FAIcon,
  DropdownButton,
  DropdownItemButton,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { NoResults } from "components/NoResults";
import { UserModel } from "./model";
import { ImpersonateUserModal } from "components/ImpersonateUserModal";
import { UnlockUserModal } from "components/UnlockUserModal";
import { EditUserModal } from "components/EditUserModal";

const PAGE_SIZE = 30;

const ORGANIZATION_USERS = gql`
  query OrganizationUsers(
    $first: Int
    $after: UUID4
    $filter: ListUsersFilter
  ) {
    users(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        firstName
        lastName
        email
        confirmedAt
        insertedAt
        isLight
        lockedFromFailedLoginAttempts
        deactivatedAt
        role {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  users: Paginated<UserModel>;
}

interface Variables {
  first?: number;
  after?: string;
  filter: FilterModel;
}

interface FilterModel {
  searchTerm?: string;
  status?: string;
  organizationId?: string;
}

type ActiveModal =
  | { name: "EDIT_USER"; userId: string }
  | { name: "IMPERSONATE_USER"; userId: string }
  | { name: "UNLOCK_USER", userId: string };

interface OrganizationUsersProps {
  organizationId: string;
}

export const OrganizationUsers: FC<OrganizationUsersProps> = (props) => {
  const { organizationId } = props;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  function closeModal() {
    setActiveModal(null);
  }

  function openEditUserModal(userId: string) {
    setActiveModal({ name: "EDIT_USER", userId });
  }

  function openImpersonateUserModal(userId: string) {
    setActiveModal({ name: "IMPERSONATE_USER", userId });
  }

  function openUnlockUserModal(userId: string) {
    setActiveModal({ name: "UNLOCK_USER", userId });
  }

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    ORGANIZATION_USERS,
    { variables: { first: PAGE_SIZE, filter: { organizationId } } }
  );

  return (
    <div className="_OrganizationUsers bg-gray-100 mt-4 p-4 rounded-2xl text-left">
      <ScreenTitle title={["Organization", "View Organization", "Users"]} />

      {activeModal?.userId ? (
        <>
          <ImpersonateUserModal
            isOpen={activeModal.name === "IMPERSONATE_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
          />
          <EditUserModal
            isOpen={activeModal.name === "EDIT_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
            onSuccess={() => {
              refetch();
              closeModal();
            }}
          />
        </>
      ) : null}

      {activeModal?.userId ? (
        <UnlockUserModal
          isOpen={activeModal.name === "UNLOCK_USER"}
          onClose={closeModal}
          userId={activeModal.userId}
          onSuccess={refetch}
        />
      ) : null}

      <div>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.users ? (
          <p>Failed to load</p>
        ) : (
          <div>
            <div className="flex justify-between px-3 pb-4">
              <div className="_FilterPanel" />
              <button
                type="button"
                className="btn btn-blue"
                onClick={() => console.log("INVITE USER")}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Invite User
              </button>
            </div>
            {data.users.items.length === 0 ? (
              <NoResults icon="users" text="No users" />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH>Actions</TH>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.users.items.map((user) => (
                      <tr key={user.id}>
                        <TD>
                          <p className="text-lg font-semibold flex items-center">
                            {user.firstName} {user.lastName}
                            {user.lockedFromFailedLoginAttempts ? "(Locked)" : null}
                          </p>
                          <p className="text-sm">
                            <span className="text-cool-gray-500 mr-2">
                              <FAIcon icon="envelope" />
                            </span>
                            {user.email}
                          </p>
                        </TD>
                        <TD>
                          <DropdownButton label="Actions">
                            {user.lockedFromFailedLoginAttempts ? (
                              <DropdownItemButton onClick={() => openUnlockUserModal(user.id)}>
                                Unlock Login
                              </DropdownItemButton>
                            ) : null}
                            {!!user.deactivatedAt ? (
                              <DropdownItemButton
                                onClick={() =>
                                  console.log("RE-ACTIVATE USER", user.id)
                                }
                              >
                                Re-activate User
                              </DropdownItemButton>
                            ) : null}
                            <DropdownItemButton
                              onClick={() => openEditUserModal(user.id)}
                            >
                              Edit User
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() =>
                                console.log("RESET PASSWORD", user.id)
                              }
                            >
                              Reset Password
                            </DropdownItemButton>
                            <DropdownItemButton
                              onClick={() => openImpersonateUserModal(user.id)}
                            >
                              Impersonate User
                            </DropdownItemButton>
                            {!user.deactivatedAt ? (
                              <DropdownItemButton
                                color="red"
                                onClick={() =>
                                  console.log("DEACTIVATE", user.id)
                                }
                              >
                                Deactivate User
                              </DropdownItemButton>
                            ) : null}
                          </DropdownButton>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
