import React, { FC } from "react";
import { ProviderSelect } from "components/ProviderSelect";
// import { ProviderOptionType } from "components/ProviderSelect/ProviderSelect";
import { FieldProps, ErrorMessage } from "formik";
import { VerticalField, HorizontalField } from "@preferral/ui";
// import { ValueType } from "react-select/src/types";

export type ProviderSelectInputProps = {
  id?: string;
} & FieldProps;

class ProviderSelectInput extends React.Component<ProviderSelectInputProps> {
  handleChange = (value: any) => {
    const {
      field,
      form: { setFieldValue }
    } = this.props;
    if (value) {
      if (Array.isArray(value)) {
        setFieldValue(
          field.name,
          value.map(v => v.id)
        );
      } else {
        setFieldValue(field.name, value.id);
      }
    } else {
      setFieldValue(field.name, value);
    }
  };

  handleBlur = () => {
    const {
      field,
      form: { setFieldTouched }
    } = this.props;
    setFieldTouched(field.name, true);
  };

  render() {
    const { id, field } = this.props;

    return (
      <>
        <ProviderSelect
          id={id || field.name}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={field.value}
        />
        <ErrorMessage
          component="p"
          name={field.name}
          className="text-sm italic text-red-600"
        />
      </>
    );
  }
}

type ProviderSelectFieldProps = {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
} & ProviderSelectInputProps;

const ProviderSelectField: FC<ProviderSelectFieldProps> = ({
  indicateOptional,
  indicateRequired,
  ...props
}) => {
  return (
    <VerticalField
      id={props.id || props.field.name}
      name={props.field.name}
      label={props.label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ProviderSelectInput {...props} />
    </VerticalField>
  );
};

const HorizontalProviderSelectField: FC<ProviderSelectFieldProps> = ({
  indicateOptional,
  indicateRequired,
  ...props
}) => {
  return (
    <HorizontalField
      id={props.id || props.field.name}
      name={props.field.name}
      label={props.label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ProviderSelectInput {...props} />
    </HorizontalField>
  );
};

export {
  ProviderSelectInput,
  ProviderSelectField,
  HorizontalProviderSelectField
};
