import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

interface AnalyticsScreenProps {}

export const AnalyticsScreen: FC<AnalyticsScreenProps> = () => {
  return (
    <div className="AnalyticsScreen">
      <ScreenTitle title="Analytics" />
      <p>AnalyticsScreen</p>
    </div>
  );
};
