import { FC } from "react";
import { FilterForm, FilterField, Button, TextInput } from '@preferral/ui';

export interface Filter {
  searchTerm: string;
}

export const defaultFilter: Filter = {
  searchTerm: ""
};

interface FilterPanelProps {
  defaultValue?: Filter;
  value: Filter;
  onChange(value: Filter): void;
  isLoading: boolean;
};

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { defaultValue = defaultFilter, value, onChange, isLoading } = props;

  return (
    <FilterForm<Filter>
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="searchTerm" icon="search" label="Search">
          <TextInput
            name="searchTerm"
            icon="search"
            inputProps={{
              placeholder: "Search...",
            }}
          />
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button type="submit" color="blue" disabled={isLoading} isLoading={isLoading}>
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
