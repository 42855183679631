import React, { FC } from "react";
import styled from "styled-components";
import { NavMenu } from "../BillingAdminScreen/NavMenu";
import { QuickEncryptScreen } from "screens/QuickEncryptScreen";
import { Switch, Redirect, Route, useRouteMatch } from "react-router-dom";

const Layout = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
`;

const Sidebar = styled.div`
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  height: 100%;
  overflow: scroll;
`;

const Content = styled.div`
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  height: 100%;
  overflow: scroll;
`;

interface ToolboxScreenProps {}

export const ToolboxScreen: FC<ToolboxScreenProps> = (props) => {
  const match = useRouteMatch();

  return (
    <Layout className="text-left">
      <Sidebar className="pb-20 bg-gray-100 border-gray-300 border-r">
        <NavMenu
          title="Toolbox"
          links={[
            {
              icon: "key",
              path: `${match.path}/encrypt`,
              label: "Encrypt Files",
            },
          ]}
        />
      </Sidebar>
      <Content className="p-10">
        <Switch>
          <Route exact path={match.path}>
            <Redirect to={`${match.url}/encrypt`} />
          </Route>
          <Route path={`${match.path}/encrypt`}>
            <QuickEncryptScreen />
          </Route>
          <Route path="*">
            <h1>Page not found.</h1>
          </Route>
        </Switch>
      </Content>
    </Layout>
  );
};
