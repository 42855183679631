import React, { FC } from "react";
import { Redirect, useRouteMatch, Switch, Route } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { NavTabs, NavTabLink } from "components/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { Spinner } from "@preferral/ui";
import { AdminDetails } from "./AdminDetails";
import { AdminLoginAttempts } from "./AdminLoginAttempts";
import { AdminCSMAccounts } from "./AdminCSMAccounts";

const ADMIN = gql`
  query FetchAdmin($id: UUID4!) {
    admin(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

interface Data {
  admin: AdminModel;
}

interface Variables {
  id: string;
}

interface AdminModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface RouteParams {
  adminId: string;
}

interface AdminShowRootProps {}

const AdminShowRoot: FC<AdminShowRootProps> = (props) => {
  const match = useRouteMatch<RouteParams>();

  return <Redirect to={`${match.url}/details`} />;
};

interface AdminShowScreenProps {}

export const AdminShowScreen: FC<AdminShowScreenProps> = (props) => {
  const match = useRouteMatch<RouteParams>();
  const { adminId } = match.params;

  const { loading, data, error } = useQuery<Data, Variables>(ADMIN, {
    variables: { id: adminId },
  });
  return (
    <div className="AdminShowScreen p-4">
      <ScreenTitle title={["Admins", "View Admin"]} />

      <div className="container mx-auto p-3 rounded-2xl shadow-xl text-left">
        {loading ? (
          <Spinner />
        ) : error || !data?.admin ? (
          <p>Failed to load.</p>
        ) : (
          <>
            <div className="_header">
              <div className="flex items-center">
                <h2 className="font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                  {data.admin.firstName} {data.admin.lastName}
                </h2>
              </div>
            </div>
            <div className="mt-4">
              <NavTabs>
                <NavTabLink to={`${match.url}/details`}>Details</NavTabLink>
                <NavTabLink to={`${match.url}/login_attempts`}>
                  Login Attempts
                </NavTabLink>
                <NavTabLink to={`${match.url}/csm_accounts`}>
                  CSM Accounts
                </NavTabLink>
              </NavTabs>
            </div>
          </>
        )}
      </div>

      <Switch>
        <Route exact path={match.path}>
          <AdminShowRoot />
        </Route>
        <Route path={`${match.path}/details`}>
          <AdminDetails adminId={adminId} />
        </Route>
        <Route path={`${match.path}/users`}>
          <AdminLoginAttempts adminId={adminId} />
        </Route>
        <Route path={`${match.path}/billing`}>
          <AdminCSMAccounts adminId={adminId} />
        </Route>
      </Switch>
    </div>
  );
};
