import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Redirect, useRouteMatch, Switch, Route } from "react-router-dom";
import { Spinner, FAIcon } from "@preferral/ui";
import { NavTabs, NavTabLink } from "components/NavTabs/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { AccountDetails } from "./AccountDetails/AccountDetails";
import { AccountStatusBadge } from "../AccountsScreen";
import { AccountBilling } from "./AccountBilling";

const ACCOUNT = gql`
  query FetchAccount($id: UUID4!) {
    account(id: $id) {
      id
      name
      status
      billingEmail
      trialFinishAt
      insertedAt
      aristamdOrganizationId
      accountTags {
        id
        color
        label
      }
    }
  }
`;

interface AccountTag {
  id: string;
  color: string;
  label: string;
}

interface Account {
  id: string;
  name: string;
  status: string;
  billingEmail: string;
  trialFinishAt: string;
  aristamdOrganizationId?: number;
  accountTags: AccountTag[];
}

interface Data {
  account: Account;
}

interface RouteParams {
  accountId: string;
}

interface AccountShowRootProps { }

const AccountShowRoot: FC<AccountShowRootProps> = (props) => {
  const match = useRouteMatch<RouteParams>();

  return <Redirect to={`${match.url}/details`} />;
};

interface AccountShowScreenProps { }

export const AccountShowScreen: FC<AccountShowScreenProps> = (props) => {
  const match = useRouteMatch<RouteParams>();
  const { accountId } = match.params;

  const { loading, data, error } = useQuery<Data>(ACCOUNT, {
    variables: { id: accountId },
  });

  return (
    <div className="AccountShowScreen p-4">
      <ScreenTitle title="Accounts » View Account" />

      <div className="container mx-auto p-3 rounded-2xl shadow-xl text-left">
        {loading ? (
          <Spinner />
        ) : error || !data?.account ? (
          <>
            <p>Failed to load.</p>
          </>
        ) : (
          <>
            <div className="_header">
              <div className="flex items-center">
                <h2 className="font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                  {data.account.name}
                </h2>
                <div className="ml-4">
                  <AccountStatusBadge status={data.account.status} />
                </div>
              </div>
              <div className="p-2 flex">
                <span className="text-cool-gray-400 mr-2">
                  <FAIcon icon="tag" />
                </span>
              </div>
            </div>
            <div className="mt-4">
              <NavTabs>
                <NavTabLink to={`${match.url}/details`}>Details</NavTabLink>
                <NavTabLink to={`${match.url}/billing`}>Billing</NavTabLink>
              </NavTabs>
            </div>
          </>
        )}
      </div>

      <Switch>
        <Route exact path={match.path}>
          <AccountShowRoot />
        </Route>
        <Route path={`${match.path}/details`}>
          <AccountDetails accountId={accountId} />
        </Route>
        <Route path={`${match.path}/billing`}>
          <AccountBilling accountId={accountId} />
        </Route>
      </Switch>
    </div>
  );
};
