import { FC, useCallback } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { gql } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/client';
import { Button, SelectField, Spinner, TextField } from '@preferral/ui';
import { FormStatusErrors } from 'components/formik/FormStatusErrors';
import toast from 'react-hot-toast';
import { Modal, ModalHeader } from "components/Modal";

const FETCH_USER = gql`
  query GetUserAndRoles($id: UUID4!) {
    timeZoneNames
    user(id: $id) {
      id
      firstName
      lastName
      email
      timeZoneName
      aristamdUserId
      role {
        id
        alias
        name
      }
    }
    roles {
      id
      alias
      name
    }
  }
`;

interface Data {
  timeZoneNames: string[];
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    timeZoneName: string;
    aristamdUserId: string | null;
    role: RoleModel;
  }
  roles: RoleModel[];
}

interface Variables {
  id: string;
}

const UPDATE_USER = gql`
  mutation UpdateUser($id: UUID4!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      errors {
        key
        message
      }
      user {
        id
      }
    }
  }
`;

interface MutationData {
  updateUser: {
    errors?: InputError[];
    user?: { id: string };
  }
}

interface MutationVariables {
  id: string;
  input: UserInput;
}

interface UserInput extends Omit<FormValues, "aristamdUserId"> {
  aristamdUserId?: number;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  timeZoneName: string;
  aristamdUserId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    roleId: Yup.string().required("Required"),
    timeZoneName: Yup.string().required("Required"),
  })
  .required();

interface RoleModel {
  id: string;
  alias: string;
  name: string;
}

interface EditUserModalProps {
  userId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const EditUserModal: FC<EditUserModalProps> = props => {
  const { isOpen, onClose, userId, onSuccess } = props;

  const { data, error, loading } = useQuery<Data, Variables>(FETCH_USER, { variables: { id: userId } });

  const [updateUser] = useMutation<MutationData, MutationVariables>(UPDATE_USER);

  const onSubmit = useCallback(async (values: FormValues, formikHelpers) => {
    const { setStatus, setSubmitting } = formikHelpers;
    setStatus({ errors: null });

    const userInput: UserInput = {
      ...values,
      aristamdUserId: values.aristamdUserId ? +values.aristamdUserId : undefined
    };

    if (!userInput.aristamdUserId) delete userInput.aristamdUserId;

    const variables = {
      id: userId,
      input: userInput
    };

    try {
      const { data } = await updateUser({ variables });

      if (data?.updateUser.errors) {
        setStatus({ errors: data.updateUser.errors });
      } else if (data?.updateUser.user) {
        // it worked...
        toast.success("User updated.");
        return onSuccess();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.");
    }
    setSubmitting(false);
  }, [userId, updateUser, onSuccess])

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader title="Edit User" icon="user-edit" onClose={onClose} />

      <div className="p-4">
        {
          loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.user ? (
            <p>Failed to load.</p>
          ) : (
            <Formik<FormValues>
              initialValues={{
                firstName: data.user.firstName,
                lastName: data.user.lastName,
                email: data.user.email,
                timeZoneName: data.user.timeZoneName,
                roleId: data.user.role.id,
                aristamdUserId: data.user.aristamdUserId || ""
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleSubmit, status }) => (
                <form onSubmit={handleSubmit}>
                  <FormStatusErrors status={status} />

                  <div className="grid grid-cols-2 gap-3">
                    <TextField
                      name="firstName"
                      label="First Name"
                    />

                    <TextField
                      name="lastName"
                      label="Last Name"
                    />
                  </div>

                  <div className="mt-3">
                    <TextField
                      name="email"
                      label="Email Address"
                    />
                  </div>

                  <div className="mt-3">
                    <TextField
                      name="aristamdUserId"
                      label="AristaMD Legacy User ID"
                    />
                  </div>

                  <div className="mt-3">
                    <SelectField
                      name="timeZoneName"
                      label="Time Zone"
                      options={data.timeZoneNames.map(tz => ({ value: tz, label: tz }))}
                    />
                  </div>

                  <div className="mt-3">
                    <SelectField
                      name="roleId"
                      label="Role"
                      options={data.roles.map(r => ({ value: r.id, label: r.name }))}
                    />
                  </div>

                  <div className="pt-6 flex justify-center">
                    <Button
                      className="mr-2"
                      type="button"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      kind="primary"
                      color="blue"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )
        }
      </div>
    </Modal>
  );
};
