import { FC, useState, useRef, useCallback, useEffect } from "react";
import { Modal, ModalHeader } from "components/Modal";
import ReactSignaturePad from "react-signature-canvas";
import { VerticalField, InputText } from "@preferral/ui";
import XIcon from '@heroicons/react/outline/XIcon';
import PencilIcon from '@heroicons/react/outline/PencilIcon';
import { trimCanvas } from "./trimCanvas";

/**
 * Tab.
 */

interface TabProps {
  active: boolean;
  onClick(): void;
}

const Tab: FC<TabProps> = (props) => {
  const { active, onClick, children } = props;

  return (
    <button
      role="tab"
      aria-selected={active}
      className={`border border-transparent font-semibold hover:bg-blue-100 hover:border-gray-200 hover:shadow-inner inline-block px-3 px-6 py-1 rounded rounded-full text-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue ${active ? "shadow-inner" : ""
        }`}
      style={
        active
          ? {
            borderColor: "#fff",
            backgroundColor: "#bee3f8",
            color: "#2b6cb0",
          }
          : undefined
      }
      onClick={onClick}
    >
      {children}
    </button>
  );
};

/**
 * TypeSignature.
 */

interface TypeSignatureProps {
  onSave(base64png: string): void;
  onCancel(): void;
}

const TypeSignature: FC<TypeSignatureProps> = (props) => {
  const { onSave, onCancel } = props;
  const [typedName, setTypedName] = useState("");
  const sigPad = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (sigPad.current) {
      const context = sigPad.current.getContext("2d");
      if (context) {
        // clear the text
        context.clearRect(0, 0, sigPad.current.width, sigPad.current.height);

        if (typedName.trim() !== "") {
          fitTextOnCanvas(sigPad.current, typedName, "cursive");
        }
      }
    }
  }, [typedName]);

  const handleSave = useCallback(() => {
    if (sigPad.current) {
      const copy = document.createElement("canvas");
      copy.width = sigPad.current.width;
      copy.height = sigPad.current.height;
      copy.getContext("2d")?.drawImage(sigPad.current, 0, 0);
      // then trim it
      trimCanvas(copy);

      if (copy.width > 1 && copy.height > 1) {
        onSave(copy.toDataURL("image/png"));
      } else {
        onSave("");
      }
    }
  }, [onSave]);

  return (
    <div>
      <VerticalField label="Type Name">
        <InputText
          icon="signature"
          value={typedName}
          onChange={setTypedName}
          inputProps={{ autoFocus: true }}
        />
      </VerticalField>
      <VerticalField label="Preview" className="mt-4">
        <div className="relative bg-gray-50 border rounded-lg shadow-inner">
          <div className="absolute w-full px-4 py-8 pointer-events-none bottom-0">
            <div className="w-full border-b-2 border-cool-gray-800">
              <XIcon className="h-8 w-8 flex-0" />
            </div>
          </div>
          <div style={{ marginLeft: 52, marginBottom: 29 }}>
            <canvas ref={sigPad} style={{ width: 300, height: 100 }} />
          </div>
        </div>
      </VerticalField>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            onClick={handleSave}
          >
            Save
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            onClick={onCancel}
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  );
};

/**
 * DrawSignature.
 */

interface DrawSignatureProps {
  onSave(base64png: string): void;
  onCancel(): void;
}

const DrawSignature: FC<DrawSignatureProps> = (props) => {
  const { onSave, onCancel } = props;
  const sigPad = useRef<ReactSignaturePad | null>(null);

  const handleSave = useCallback(() => {
    if (sigPad.current) {
      const trimmedCanvas = sigPad.current?.getTrimmedCanvas();
      if (trimmedCanvas.width > 1 && trimmedCanvas.height > 1) {
        onSave(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
      } else {
        onSave("");
      }
    }
  }, [onSave]);

  const handleClear = useCallback(() => {
    sigPad.current?.clear();
  }, []);

  return (
    <div className="pt-6">
      <div className="relative bg-cool-gray-50 border rounded-lg shadow-inner">
        <div className="absolute w-full px-4 py-8 pointer-events-none bottom-0">
          <div className="w-full border-b-2 border-cool-gray-800">
            <XIcon className="h-8 w-8 flex-0" />
          </div>
        </div>
        <ReactSignaturePad
          ref={sigPad}
          canvasProps={{ style: { width: "100%", height: 200 } }}
        />
      </div>
      <div className="p-2 text-center">
        <button
          type="button"
          className="btn btn-sm btn-blue-alt"
          onClick={handleClear}
        >
          Clear
        </button>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            onClick={handleSave}
          >
            Save
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            onClick={onCancel}
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  );
};

/**
 * SignaturePadModal.
 */

interface SignaturePadModalProps {
  isOpen: boolean;
  onClose(): void;
  onSave(base64png: string): void;
}

const SignaturePadModal: FC<SignaturePadModalProps> = (props) => {
  const { isOpen, onClose, onSave } = props;
  const [currentTab, setCurrentTab] = useState<"DRAW" | "TYPE">("DRAW");

  const handleSave = useCallback(
    (base64png: string) => {
      onSave(base64png);
      onClose();
    },
    [onClose, onSave]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="signature" title="Sign" onClose={onClose} />
      <div className="px-4 pt-5 pb-4 overflow-hidden">
        <div className="flex items-center justify-around">
          <Tab
            active={currentTab === "DRAW"}
            onClick={() => setCurrentTab("DRAW")}
          >
            Draw Signature
          </Tab>
          <p className="px-4 text-xs text-cool-gray-500">OR</p>
          <Tab
            active={currentTab === "TYPE"}
            onClick={() => setCurrentTab("TYPE")}
          >
            Type Signature
          </Tab>
        </div>
        {currentTab === "DRAW" ? (
          <DrawSignature onSave={handleSave} onCancel={onClose} />
        ) : (
          <TypeSignature onSave={handleSave} onCancel={onClose} />
        )}
      </div>
    </Modal>
  );
};

const codes = {
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  END: 35,
  HOME: 36,
};

/**
 * SignaturePad.
 */

interface SignaturePadProps {
  id?: string;
  value: string;
  onChange?(base64png: string): void;
  onBlur?(e: any): void;
  editable?: boolean;
  className?: string;
}

export const SignaturePad: FC<SignaturePadProps> = (props) => {
  const {
    id,
    value,
    onChange,
    editable = true,
    onBlur,
    className = "",
  } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  const handleChange = useCallback(
    (base64png: string) => {
      if (onChange) return onChange(base64png);
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (!modalOpen) {
        event.persist();
        let flag = false;

        switch (event.keyCode) {
          case codes.SPACE:
          case codes.RETURN:
            setModalOpen(true);
            flag = true;
            break;
          case codes.ESC:
          case codes.END:
            handleChange("");
            flag = true;
            break;
          default:
            break;
        }

        if (flag) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    },
    [modalOpen, handleChange, setModalOpen]
  );

  return (
    <>
      <SignaturePadModal
        isOpen={modalOpen}
        onClose={closeModal}
        onSave={handleChange}
      />
      <div id={id} className={`_SignaturePad ${className}`}>
        <div className="border-b-2 border-gray-800 flex items-end">
          <XIcon className="h-8 w-8 flex-0" />
          {!!value ? (
            <div className="flex-1 p-1">
              <img className="max-w-full max-h-24" src={value} alt="signature" />
            </div>
          ) : (
            <div
              tabIndex={editable ? 0 : -1}
              className={`_click-to-sign flex-1 m-1 px-4 py-6 text-center text-blue-500 font-bold cursor-pointer focus:outline-none focus:shadow-outline rounded-xl border-2 border-dashed border-blue-300`}
              onClick={editable ? openModal : undefined}
              onKeyDown={editable ? handleKeyDown : undefined}
              onBlur={onBlur}
            >
              <p className="flex items-center justify-center">
                <PencilIcon className="mr-1 text-blue-400 w-6 h-6" />
                <span>Click to sign</span>
              </p>
            </div>
          )}
        </div>
        {value && editable && (
          <div className="text-center p-2">
            <button
              type="button"
              className="btn btn-blue-alt btn-sm mx-auto"
              onClick={openModal}
            >
              Click to edit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

function fitTextOnCanvas(
  canvas: HTMLCanvasElement,
  text: string,
  fontFace: string
) {
  const ctx = canvas.getContext("2d");

  if (ctx) {
    // start with large font size
    let fontSize = 32;

    // lower the font size until the text fits the canvas
    do {
      fontSize--;
      ctx.font = `${fontSize}px ${fontFace}`;
    } while (
      ctx.measureText(text).width > canvas.width ||
      fontSize > canvas.height
    );

    // draw the text
    ctx.fillText(text, 0, canvas.height - fontSize);
  }
}
