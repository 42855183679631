import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "components/FAIcon";

type CircleIconProps = {
  icon: IconProp;
  size?: number;
  iconSize?: number;
  color?: AppColor;
};

const CircleIcon: React.FC<CircleIconProps> = ({
  icon,
  size,
  iconSize,
  color = "blue"
}) => {
  return (
    <div
      className={`h-10 w-10 flex justify-center items-center bg-${color}-200 text-${color}-700 rounded-full`}
      style={!!size ? { width: size, height: size } : {}}
    >
      {!!iconSize ? (
        <span style={{ fontSize: iconSize }}>
          <FAIcon icon={icon} />
        </span>
      ) : (
        <FAIcon icon={icon} />
      )}
    </div>
  );
};

export { CircleIcon };
