import { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";
import styled from "styled-components";
import { Switch, useRouteMatch, Redirect, Route, Link } from "react-router-dom";
import { PlansScreen } from "screens/PlansScreen/PlansScreen";
import { NavMenu } from "./NavMenu";

const Layout = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
`;

const Sidebar = styled.div`
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  height: 100%;
  overflow: scroll;
`;

const Content = styled.div`
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  height: 100%;
  overflow: scroll;
`;

interface BillingAdminScreenProps { }

export const BillingAdminScreen: FC<BillingAdminScreenProps> = (props) => {
  const match = useRouteMatch();

  return (
    <>
      <ScreenTitle title="Billing" />
      <Layout className="text-left">
        <Sidebar className="pb-20 bg-gray-100 border-gray-300 border-r">
          <NavMenu
            title="Billing"
            links={[
              {
                icon: "credit-card",
                path: `${match.path}/plans`,
                label: "Plans",
              },
              {
                icon: "credit-card",
                path: `${match.path}/addons`,
                label: "Addons",
              },
              {
                icon: "credit-card",
                path: `${match.path}/coupons`,
                label: "Coupons",
              },
            ]}
          />
        </Sidebar>
        <Content className="p-10">
          <Switch>
            <Route exact path={match.path}>
              <Redirect to={`${match.url}/plans`} />
            </Route>
            <Route path={`${match.path}/plans`}>
              <PlansScreen />
            </Route>
            <Route path="*">
              <h1>Page not found.</h1>
            </Route>
          </Switch>
        </Content>
      </Layout>
    </>
  );
};
