import isNil from "lodash.isnil";
import cloneDeep from "lodash.clonedeep";

import {
  ActionModel,
  ConditionalModel,
  OperatorModel,
  Predicate,
  QuestionModel,
  QuestionnaireModel,
  RuleModel,
  SomeAnswer
} from "./types";

export const answerKindsForQuestionKind: Record<
  QuestionModel["kind"],
  SomeAnswer["_kind"]
> = {
  address: "address",
  checkboxes: "string_list",
  date: "date",
  dropdown: "string",
  medication_list: "medication_list",
  number: "number",
  email: "email",
  phone_number: "phone_number",
  preferred_provider: "provider_id",
  radio: "string",
  signature: "base64png",
  text: "string",
  tooth_selector: "teeth"
};

interface RuleEvaluationOptions {
  getAnswerValue(questionId: string): SomeAnswer["value"] | null;
}

export function getQuestion(
  questionId: string,
  template: QuestionnaireModel
): QuestionModel | undefined {
  let question: QuestionModel | undefined;
  for (let section of template.sections) {
    question = section.questions.find(q => q.id === questionId);
    if (question) return question;
  }
  return undefined;
}

export function questionRuleState(
  question: QuestionModel,
  options: RuleEvaluationOptions
) {
  const actions = evaluateRules(question, options);
  const isVisible = actions.reduce(visibilityReducer, question.defaultVisible);
  const isRequired = actions.reduce(requirementReducer, question.required);
  return { isVisible, isRequired };
}

function evaluateRules(
  question: QuestionModel,
  options: RuleEvaluationOptions
): ActionModel[] {
  return question.rules
    .filter(rule => predicateMatch(rule, options))
    .flatMap(rule => rule.thenActions);
}

function predicateMatch(
  rule: RuleModel,
  options: RuleEvaluationOptions
): boolean {
  return evaluate(rule.conditionalGroup, options);
}

function evaluate(
  predicate: Predicate,
  options: RuleEvaluationOptions
): boolean {
  if (predicate._type === "CG") {
    /** Conditional Group */
    if (predicate.operator === "AND") {
      return predicate.conditionals.every(c => evaluate(c, options));
    } else {
      return predicate.conditionals.some(c => evaluate(c, options));
    }
  } else {
    /** Conditional */
    return evaluateConditional(predicate, options);
  }
}

function evaluateConditional(
  predicate: ConditionalModel,
  options: RuleEvaluationOptions
): boolean {
  if (predicate.valueSource.source === "QUESTIONNAIRE") {
    const { getAnswerValue } = options;
    const answerValue = getAnswerValue(predicate.valueSource.meta.questionId);
    return checkPredicate(
      predicate.operator,
      answerValue,
      predicate.compareValue
    );
  }
  return false;
}

function checkPredicate(
  operator: OperatorModel,
  value1: SomeAnswer["value"] | null,
  value2: any
): boolean {
  switch (operator) {
    case "!=":
      return value1 !== value2;
    case "<":
      return !isNil(value1) && +value1 < +value2;
    case "<=":
      return !isNil(value1) && !isNil(value2) && +value1 <= +value2;
    case "==":
      return value1 === value2;
    case ">":
      return !isNil(value1) && +value1 > +value2;
    case ">=":
      return !isNil(value1) && +value1 >= +value2;
    case "does not include":
      return ![value1].flat().includes(value2);
    case "includes":
      return [value1].flat().includes(value2);
    default:
      return false;
  }
}

function visibilityReducer(initialValue: boolean, action: string): boolean {
  switch (action) {
    case "HIDE":
      return false;
    case "UNHIDE":
      return true;
    default:
      return initialValue;
  }
}

function requirementReducer(initialValue: boolean, action: string): boolean {
  switch (action) {
    case "REQUIRE":
      return true;
    case "UNREQUIRE":
      return false;
    default:
      return initialValue;
  }
}

/**
 * Prepares a client-side questionnaire for the server.
 * This involves stingifying certain JSON fields, like conditionals and answers.
 *
 * @param questionnaire QuestionnaireModel
 */
export function questionnaireForServer(
  questionnaire: QuestionnaireModel
): QuestionnaireModel {
  const qq = cloneDeep<QuestionnaireModel>(questionnaire);
  traverse(qq, x => {
    delete x.__typename;
    if (
      x.conditionalGroup &&
      typeof x.conditionalGroup === "object" &&
      x.conditionalGroup !== null
    ) {
      x.conditionalGroup = JSON.stringify(x.conditionalGroup);
    }
    if (x.answer && typeof x.answer === "object" && x.answer !== null) {
      x.answer = JSON.stringify(x.answer);
    }
    if (x.config && typeof x.config === "object" && x.config !== null) {
      x.config = JSON.stringify(x.config);
    }
  });
  return qq;
}

export function removeAnswers(
  questionnaire: QuestionnaireModel
): QuestionnaireModel {
  const q = cloneDeep<QuestionnaireModel>(questionnaire);

  for (let section of q.sections) {
    for (let question of section.questions) {
      question.answer = null;
    }
  }
  return q;
}

function traverse(x: any, action: (x: any) => void) {
  if (isArray(x)) {
    traverseArray(x, action);
  } else if (typeof x === "object" && x !== null) {
    action(x);
    traverseObject(x, action);
  } else {
  }
}

function traverseArray(arr: any[], action: (x: any) => void) {
  arr.forEach(function(x: any) {
    traverse(x, action);
  });
}

function traverseObject(obj: any, action: (x: any) => void) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      traverse(obj[key], action);
    }
  }
}

function isArray(o: any) {
  return Object.prototype.toString.call(o) === "[object Array]";
}
