import React, { FC } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
// import {} from "components/formik/LocationSearchField";
// import {} from "components/LocationSearchSelect/LocationSearchSelect";
import { Avatar, Spinner, ImageObject, FAIcon } from "@preferral/ui";
// import { NoResults } from "components/NoResults";
import { LocationSearchSelectField } from "components/formik/LocationSearchField";
import { LocationReferenceOptionModel } from "components/LocationSearchSelect/LocationSearchSelect";

const NPPES_PROVIDER = gql`
  query NPPESProviderLocations($id: UUID4!) {
    nppesProvider(id: $id) {
      id
      npi
      nameWithAppellation
      avatar(size: "small") {
        base64
        src
        srcSet
      }
      specialties {
        id
        name
      }
      locations {
        id
        name
        streetAddress
        city
        stateAbbreviation
        zip
      }
    }
  }
`;

interface Data {
  nppesProvider: NPPESProvider;
}

interface Variables {
  id: string;
}

interface NPPESLocation {
  id: string;
  name: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
}

interface NPPESProvider {
  id: string;
  npi: string;
  nameWithAppellation: string;
  specialties: {
    id: string;
    name: string;
  }[];
  locations: NPPESLocation[];
  avatar: ImageObject;
}

const ADD_NPPES_PROVIDER_MAPPING = gql`
  mutation AddNPPESProviderMapping(
    $nppesProviderId: UUID4!
    $nppesLocationId: UUID4!
  ) {
    addNppesProviderMapping(
      nppesProviderId: $nppesProviderId
      nppesLocationId: $nppesLocationId
    ) {
      errors {
        key
        message
      }
      nppesProvider {
        id
      }
    }
  }
`;

interface MutationData {
  addNppesProviderMapping: {
    errors?: InputError[];
    nppesProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  nppesProviderId: string;
  nppesLocationId: string;
}

const REMOVE_NPPES_PROVIDER_MAPPING = gql`
  mutation RemoveNPPESProviderMapping(
    $nppesProviderId: UUID4!
    $nppesLocationId: UUID4!
  ) {
    removeNppesProviderMapping(
      nppesProviderId: $nppesProviderId
      nppesLocationId: $nppesLocationId
    ) {
      errors {
        key
        message
      }
      nppesProvider {
        id
      }
    }
  }
`;

interface RemoveMutationData {
  removeNppesProviderMapping: {
    errors?: InputError[];
    nppesProvider?: {
      id: string;
    };
  };
}

interface RemoveMutationVariables {
  nppesProviderId: string;
  nppesLocationId: string;
}

interface EditNPPESProviderMappingsFormProps {
  nppesProviderId: string;
  nppesOrganizationId: string;
}

export const EditNPPESProviderMappingsForm: FC<EditNPPESProviderMappingsFormProps> = (
  props
) => {
  const { nppesProviderId, nppesOrganizationId } = props;
  const { data, loading, error } = useQuery<Data, Variables>(NPPES_PROVIDER, {
    variables: {
      id: nppesProviderId,
    },
    fetchPolicy: "network-only",
  });

  // NB: Should be refetched after `addMapping` or `removeMapping`.
  const refetchQueries = [
    {
      query: NPPES_PROVIDER,
      variables: {
        id: nppesProviderId,
      },
    },
  ];

  const [addMapping] = useMutation<MutationData, MutationVariables>(
    ADD_NPPES_PROVIDER_MAPPING
  );

  const [removeMapping] = useMutation<
    RemoveMutationData,
    RemoveMutationVariables
  >(REMOVE_NPPES_PROVIDER_MAPPING);

  return (
    <div className="EditNPPESProviderMappingsForm">
      {loading ? (
        <Spinner />
      ) : error || !(data && data.nppesProvider) ? (
        <p>Failed to load.</p>
      ) : (
        <>
          <div className="_provider max-w-lg mx-auto">
            <div className="flex items-center p-4 rounded-2xl shadow-lg">
              <Avatar
                size="sm"
                alt={data.nppesProvider.nameWithAppellation}
                image={data.nppesProvider.avatar}
              />
              <div className="ml-5">
                <p className="font-black text-gray-800 text-lg">
                  {data.nppesProvider.nameWithAppellation}
                </p>
                <p className="leading-none text-gray-600 text-xs italic">
                  NPI: {data.nppesProvider.npi}
                </p>
                <p>
                  {data.nppesProvider.specialties.map((s) => s.name).join(", ")}
                </p>
              </div>
            </div>
          </div>
          <div className="_current-locations">
            <div className="flex mt-6 -mx-4">
              <div className="flex-1 px-4">
                <h3 className="font-bold text-gray-700 text-xl">
                  Current Locations
                </h3>
                {data.nppesProvider.locations.length === 0 ? (
                  <div className="_NoResults">
                    <div className="bg-gray-300 flex h-20 inline-block items-center justify-center mt-6 mx-auto rounded-full text-xl sm:text-2xl lg:text-4xl text-gray-600 text-white w-20">
                      <FAIcon icon="map-marker-alt" />
                    </div>
                    <p className="mt-4 text-center text-gray-600">
                      No Locations
                    </p>
                  </div>
                ) : (
                  <ul className="mt-3">
                    {data.nppesProvider.locations.map((loc) => (
                      <li
                        key={loc.id}
                        className="border-b border-gray-300 flex items-center py-2"
                      >
                        <div className="bg-blue-200 border-2 border-blue-400 flex h-10 items-center justify-center ml-4 rounded-full text-blue-700 w-10">
                          <FAIcon icon="map-marker-alt" />
                        </div>
                        <div className="ml-4 flex-1">
                          <p className="font-semibold text-lg text-gray-800">
                            {loc.name}
                          </p>
                          <p className="text-xs text-gray-600">
                            {loc.streetAddress}
                            {loc.streetAddressLine2 &&
                              `, ${loc.streetAddressLine2}`}
                          </p>
                          <p className="text-xs text-gray-600">
                            {loc.city}, {loc.stateAbbreviation}, {loc.zip}
                          </p>
                        </div>
                        <div className="ml-4">
                          <button
                            type="button"
                            className="btn btn-sm btn-red-alt"
                            onClick={() => {
                              removeMapping({
                                variables: {
                                  nppesProviderId,
                                  nppesLocationId: loc.id,
                                },
                                refetchQueries,
                              }).then((resp) => {
                                if (
                                  resp?.data?.removeNppesProviderMapping?.errors
                                ) {
                                  // toast.error(resp.data.removeNppesProviderMapping.errors[0].message)
                                } else if (
                                  resp?.data?.removeNppesProviderMapping
                                    ?.nppesProvider
                                ) {
                                  // it worked
                                  // toast.success("Provider unmapped from location")
                                }
                              });
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="flex-1 px-4">
                <div className="border p-4 rounded rounded-lg shadow">
                  <h3 className="font-bold text-gray-700 text-lg">
                    Add Location
                  </h3>
                  <Formik<{
                    selectedLocation: LocationReferenceOptionModel | null;
                  }>
                    initialValues={{
                      selectedLocation: null,
                    }}
                    onSubmit={(values, { setSubmitting, setStatus }) => {
                      setStatus({ errors: null });
                      if (values.selectedLocation) {
                        return addMapping({
                          variables: {
                            nppesProviderId,
                            nppesLocationId: values.selectedLocation.id,
                          },
                          refetchQueries,
                        }).then(
                          (resp) => {
                            if (resp?.data?.addNppesProviderMapping?.errors) {
                              setStatus({
                                errors:
                                  resp.data.addNppesProviderMapping.errors,
                              });
                            } else if (
                              resp?.data?.addNppesProviderMapping?.nppesProvider
                            ) {
                              // it worked
                            }
                            setSubmitting(false);
                          },
                          (rej) => {
                            setStatus({
                              errors: [
                                { key: "", message: "Something went wrong." },
                              ],
                            });
                          }
                        );
                      }
                      return;
                    }}
                  >
                    {({ status, handleSubmit, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <FormStatusErrors status={status} />
                        <div className="mt-3">
                          <LocationSearchSelectField
                            label="Location"
                            name="selectedLocation"
                            filter={{
                              organizationId: nppesOrganizationId,
                            }}
                          />
                        </div>
                        <div className="mt-3 flex justify-end">
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="btn btn-blue"
                          >
                            Add Location
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
