import { FC, ReactNode, useCallback } from "react";
import { useField, ErrorMessage } from "formik";
import { LocationSearchSelect } from "components/LocationSearchSelect";
import {
  LocationSearchFilter,
  LocationReferenceOptionModel
} from "components/LocationSearchSelect/LocationSearchSelect";
import { VerticalField, HorizontalField } from "@preferral/ui";

/**
 * LocationSearchSelectInput.
 */

interface LocationSearchSelectInputProps {
  id?: string;
  name: string;
  filter?: LocationSearchFilter;
  placeholder?: ReactNode;
}

export const LocationSearchSelectInput: FC<LocationSearchSelectInputProps> = props => {
  const { id, name, placeholder, filter } = props;

  const [field, , helpers] = useField<
    LocationReferenceOptionModel | undefined | null
  >(name);
  const { value } = field;
  const { setValue, setTouched } = helpers;

  const handleChange = useCallback(
    (value: any) => {
      setValue(value);
    },
    [setValue]
  );

  const handleBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  return (
    <>
      <LocationSearchSelect
        id={id || name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        placeholder={placeholder}
        filter={filter}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * LocationSearchSelectField.
 */

interface LocationSearchSelectFieldProps
  extends LocationSearchSelectInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const LocationSearchSelectField: FC<LocationSearchSelectFieldProps> = props => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <LocationSearchSelectInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalLocationSearchSelectField: FC<LocationSearchSelectFieldProps> = props => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <LocationSearchSelectInput {...rest} />
    </HorizontalField>
  );
};
