import React, { FC } from "react";

interface OrganizationOverviewProps {
  organizationId: string;
}

export const OrganizationOverview: FC<OrganizationOverviewProps> = (props) => {
  const { organizationId } = props;
  return (
    <div className="OrganizationOverview">
      <p>OrganizationOverview</p>
      <p>{organizationId}</p>
    </div>
  );
};
