import { FC, useCallback } from "react";
import { useField, ErrorMessage } from "formik";
import { FileUploader, FileUploaderProps } from "components/FileUploader";
import { VerticalField, HorizontalField, ServerFileModel } from "@preferral/ui";

/**
 * FileUploadInput.
 */

interface FileUploadInputProps
  extends Omit<FileUploaderProps, "onUploadSuccess" | "onUploadRemoved"> {
  name: string;
  id?: string;
}

export const FileUploadInput: FC<FileUploadInputProps> = (props) => {
  const { id, name, ...rest } = props;

  const [field, , helpers] = useField(name);
  const { value = [] } = field;
  const { setValue } = helpers;

  const handleUploadSuccess = useCallback(
    (fileUpload: ServerFileModel) => {
      if (!value.includes(fileUpload.id)) {
        setValue([fileUpload.id, ...value]);
      }
    },
    [value, setValue]
  );

  const handleUploadRemoved = useCallback(
    (fileUpload: ServerFileModel) => {
      setValue(value.filter((id: string) => id !== fileUpload.id));
    },
    [value, setValue]
  );

  return (
    <>
      <FileUploader
        {...rest}
        onUploadSuccess={handleUploadSuccess}
        onUploadRemoved={handleUploadRemoved}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * FileUploadField.
 */

interface FileUploadFieldProps extends FileUploadInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const FileUploadField: FC<FileUploadFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <FileUploadInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalFileUploadField: FC<FileUploadFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <FileUploadInput {...rest} />
    </HorizontalField>
  );
};
