import React from "react";
import { Toaster } from "react-hot-toast";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";
import { NoMatchScreen } from "screens/NoMatchScreen/NoMatchScreen";
import { SSORedirectScreen } from "screens/SSORedirectScreen";

const App: React.FC = () => {
  return (
    <div className="_App antialiased">
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/sign_in" />
        </Route>
        <Route exact path="/auth/sso_redirect">
          <SSORedirectScreen />
        </Route>
        <Route path="/auth">
          <UnauthenticatedApp />
        </Route>
        <PrivateRoute path="/admin">
          <AuthenticatedApp />
        </PrivateRoute>
        <Route path="*">
          <NoMatchScreen />
        </Route>
      </Switch>
      <Toaster position="top-center" />
    </div>
  );
};

export default App;
