import { FC, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import { Badge, Spinner, ToggleSwitch } from "@preferral/ui";
import { NoResults } from "components/NoResults";
import toast from "react-hot-toast";

const ORGANIZATION_FEATURE_FLAGS = gql`
  query OrganizationFeatureFlags($organizationId: UUID4!) {
    organization(id: $organizationId) {
      id
      featureFlags {
        feature
        enabled
      }
    }
  }
`;

interface Data {
  organization: {
    id: string;
    featureFlags: FeatureFlagModel[];
  };
}

interface Variables {
  organizationId: string;
}

export interface FeatureFlagModel {
  feature: string;
  enabled: boolean;
}

const SET_FEATURE_FLAG = gql`
  mutation SetFeatureFlag(
    $organizationId: UUID4!
    $feature: String!
    $enabled: Boolean!
  ) {
    setFeatureFlag(
      organizationId: $organizationId
      feature: $feature
      enabled: $enabled
    ) {
      errors {
        key
        message
      }
      organization {
        id
        featureFlags {
          feature
          enabled
        }
      }
    }
  }
`;

interface MutationData {
  setFeatureFlag: {
    errors?: InputError[];
    organization?: {
      id: string;
      featureFlags: FeatureFlagModel[];
    };
  };
}

interface MutationVariables {
  organizationId: string;
  feature: string;
  enabled: boolean;
}

const featureLabels = {
  patient_forms: {
    label: "Patient Forms",
    isBeta: false,
    description: "Create and send digital patient forms and packets."
  },
  econsults: {
    label: "eConsults",
    isBeta: true,
    description: "Submit and review eConsult requests to specialists."
  },
  fax_inboxes: {
    label: "Fax Inboxes",
    isBeta: true,
    description: "Receive faxes in-app."
  },
  analytics: {
    label: "Analytics",
    isBeta: false,
    description: "View and manage analytics."
  },
  text_messaging: {
    label: "Text Messaging",
    isBeta: false,
    description: "Create and send text messages in-app."
  },
  referrals: {
    label: "Referrals",
    isBeta: false,
    description: "Create and manage referrals."
  },
  fake_eligibility_notification: {
    label: "Fake Eligibility Notifications",
    isBeta: false,
    description: "Display a fake eligibility notification on referral questions step for demo purposes."
  }
};

export function featureLabel(featureFlag: FeatureFlagModel): string {
  return featureLabels[featureFlag.feature]?.label || featureFlag.feature;
}

/**
 * OrganizationFeatures.
 */

interface OrganizationFeaturesProps {
  organizationId: string;
}

export const OrganizationFeatures: FC<OrganizationFeaturesProps> = props => {
  const { organizationId } = props;

  const [updatingFlags, setUpdatingFlags] = useState<string[]>([]);

  function addUpdatingFlag(feature: string) {
    setUpdatingFlags(
      updatingFlags.includes(feature)
        ? updatingFlags
        : [feature, ...updatingFlags]
    );
  }

  function removeUpdatingFlag(feature: string) {
    setUpdatingFlags(updatingFlags.filter(f => f !== feature));
  }

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    ORGANIZATION_FEATURE_FLAGS,
    { variables: { organizationId } }
  );

  const [setFeatureFlagMut] = useMutation<MutationData, MutationVariables>(
    SET_FEATURE_FLAG
  );

  async function setFeatureFlag(featureName: string, enabled: boolean) {
    addUpdatingFlag(featureName);
    try {
      const { data } = await setFeatureFlagMut({
        variables: {
          organizationId,
          feature: featureName,
          enabled
        }
      });

      if (data?.setFeatureFlag.errors) {
        toast.error(data.setFeatureFlag.errors[0].message);
      } else {
        // it worked...
        toast.success("Feature Flag Updated.");
        removeUpdatingFlag(featureName);
        refetch();
      }
    } catch (e) {
      toast.error("Something went wrong.");
      console.error(e);
    }
  }

  return (
    <>
      <ScreenTitle title={["Organization", "View Organization", "Features"]} />

      <div className="_OrganizationFeatures bg-gray-100 mt-4 p-4 rounded-2xl text-left">
        <div className="max-w-xl mx-auto w-full">
          <h2 className="font-bold mt-4 text-gray-800 text-lg">Feature Flags</h2>
          <p className="pt-5 text-gray-700">Use these toggles to enable or disable certain features for the organization.</p>
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.organization ? (
            <p>Failed to load.</p>
          ) : (
            <div className="pt-4">
              {data.organization.featureFlags.length === 0 ? (
                <NoResults icon="gear" text="No Features to Toggle" />
              ) : (
                <>
                  {data.organization.featureFlags.map(f => (
                    <div
                      key={f.feature}
                      className="py-4 border-t flex items-center justify-between"
                    >
                      <span className="flex-grow flex flex-col">
                        <span className="font-medium text-gray-900">
                          {featureLabel(f)}
                          {featureLabels[f.feature]?.isBeta ? (
                            <Badge color="purple" className="ml-2">Beta</Badge>
                          ) : null}
                        </span>
                        {featureLabels[f.feature]?.description ? (
                          <span
                            className="text-sm text-gray-500"
                            id="availability-description"
                          >
                            {featureLabels[f.feature]?.description}
                          </span>
                        ) : null}
                      </span>
                      <ToggleSwitch
                        id={f.feature}
                        loading={updatingFlags.includes(f.feature)}
                        checked={f.enabled}
                        onChange={() => setFeatureFlag(f.feature, !f.enabled)}
                        showLabel
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
