import React from "react";

import "./DetailList.css";

type DetailItemProps = {
  label: string;
  children: React.ReactNode;
};

const DetailItem: React.FC<DetailItemProps> = ({ label, children }) => {
  return (
    <div className="DetailListItem flex items-baseline">
      <div
        className="text-xs font-semibold text-gray-700 text-right"
        style={{ minWidth: 96 }}
      >
        {label}
      </div>
      <div className="ml-4 text-lg text-gray-900 flex-1">{children}</div>
    </div>
  );
};

type DetailListProps = {
  children: React.ReactNode;
};

const DetailList: React.FC<DetailListProps> = ({ children }) => {
  return <div className="DetailList">{children}</div>;
};

export { DetailList, DetailItem };
