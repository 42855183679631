import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { SelectInput, VerticalField, HorizontalField } from "@preferral/ui";

const LIST_COUNTIES = gql`
  query ListCounties($first: Int, $after: UUID4, $filter: CountiesFilter) {
    counties(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        fipsCode
        name
      }
    }
  }
`;

interface County {
  id: string;
  name: string;
  fipsCode: string;
}

interface CountiesFilter {
  zip: string;
}

interface Data {
  counties: {
    cursor?: string;
    endOfList: boolean;
    items: County[];
  };
}

interface Variables {
  first?: number;
  after?: string;
  filter: CountiesFilter;
}

interface CountySelectInputProps {
  id?: string;
  zip: string;
  name: string;
  className?: string;
  placeholder?: string;
}

export const CountySelectInput: FC<CountySelectInputProps> = (props) => {
  const { zip, ...rest } = props;
  const [debouncedZip] = useDebounce(zip, 400);
  const { data, loading } = useQuery<Data, Variables>(LIST_COUNTIES, {
    variables: { filter: { zip: debouncedZip } },
  });

  return (
    <SelectInput
      {...rest}
      isLoading={loading}
      options={
        data?.counties?.items.map((c) => ({
          label: c.name,
          value: c.fipsCode,
        })) || []
      }
    />
  );
};

interface CountySelectFieldProps extends CountySelectInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const CountySelectField: FC<CountySelectFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <CountySelectInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalCountySelectField: FC<CountySelectFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <CountySelectInput {...rest} />
    </HorizontalField>
  );
};
