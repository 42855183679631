import { FC, useState, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { AccountStatusModel } from "screens/AccountsScreen/FilterPanel";
import {
  DetailList,
  DetailItem,
  Spinner,
  CopyText,
  FAIcon,
  Button,
  CircleIcon,
  JsonDebugger,
  Badge
} from "@preferral/ui";
import { UpdateAccountInfoModal } from "./UpdateAccountInfoModal";
import { AssignAccountCSMModal } from "./AssignAccountCSMModal";
import { lightFormat, parseISO } from "date-fns";
import {
  SubscriptionStatusModel,
  RecurrenceModel,
} from "../AccountBilling/model";
import { Link } from "react-router-dom";
import { featureLabel } from "screens/OrganizationShowScreen/OrganizationFeatures";

const ACCOUNT = gql`
  query GetAccount($id: UUID4!) {
    account(id: $id) {
      id
      name
      status
      billingEmail
      trialFinishAt
      insertedAt
      numFullUsers
      numLightUsers
      stripeCustomerPageUrl
      salesforceAccountPageUrl
      aristamdOrganizationSettingsPageUrl
      aristamdOrganizationId
      organizations {
        id
        name
        featureFlags {
          feature
          enabled
        }
      }
      accountTags {
        id
        color
        label
      }
      csm {
        id
        email
        firstName
        lastName
      }
      subscription {
        id
        status
        recurrence
        planLicense {
          id
          quantity
        }
        plan {
          id
          name
          slug
        }
        addons {
          id
          name
          slug
        }
      }
    }
  }
`;

interface Data {
  account: {
    id: string;
    name: string;
    status: AccountStatusModel;
    billingEmail: string;
    trialFinishAt?: string;
    insertedAt: string;
    numFullUsers: number;
    numLightUsers: number;
    stripeCustomerPageUrl?: string;
    salesforceAccountPageUrl?: string;
    aristamdOrganizationSettingsPageUrl?: string;
    aristamdOrganizationId?: number;
    accountTags: AccountTag[];
    csm?: CSMModel;
    subscription: SubscriptionModel;
    organizations: {
      id: string;
      name: string;
      featureFlags: {
        feature: string;
        enabled: boolean;
      }[];
    }[];
  };
}

const REMOVE_CSM = gql`
  mutation RemoveAccountCSM($id: UUID4!) {
    removeAccountCsm(id: $id) {
      errors {
        key
        message
      }
      account {
        id
      }
    }
  }
`;

interface RemoveCSMData {
  removeAccountCsm: {
    errors?: InputError[];
    account?: {
      id: string;
    };
  };
}

interface CSMModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface AccountTag {
  id: string;
  color: string;
  label: string;
}

interface SubscriptionModel {
  id: string;
  status: SubscriptionStatusModel;
  recurrence: RecurrenceModel;
  planLicense: {
    id: string;
    quantity: number;
  };
  plan: {
    id: string;
    name: string;
    slug: string;
  };
  addons: {
    id: string;
    name: string;
  }[];
}

/**
 * Card.
 */
const Card: FC<{ className?: string }> = (props) => {
  const { className = "", ...rest } = props;
  return (
    <div
      className={`_Card rounded-lg shadow-lg p-4 bg-white ${className}`}
      {...rest}
    />
  );
};

/**
 * CurrentCSM.
 */

interface CurrentCSMProps {
  csm: CSMModel;
}

const CurrentCSM: FC<CurrentCSMProps> = (props) => {
  const { csm } = props;
  const { firstName, lastName, email } = csm;
  const initial = firstName.slice(0, 1);

  return (
    <div className="_CurrentCSM border border-cool-gray-200 flex inline-block mx-auto p-2 rounded-lg shadow-sm">
      <p className="bg-indigo-200 flex h-8 items-center justify-center leading-none rounded-lg shadow-inner text-indigo-700 text-lg w-8">
        {initial}
      </p>
      <div className="ml-3 mr-4">
        <p className="flex-1 leading-tight text-gray-800 text-left text-sm truncate">
          {firstName} {lastName}
        </p>
        <a href={`mailto:${email}`} className="text-xs text-blue-500 underline">
          {email}
        </a>
      </div>
    </div>
  );
};

type ActiveModal = "EDIT_ACCOUNT" | "ASSIGN_CSM";

interface AccountDetailsProps {
  accountId: string;
}

export const AccountDetails: FC<AccountDetailsProps> = (props) => {
  const { accountId } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const { data, loading, error } = useQuery<Data>(ACCOUNT, {
    variables: { id: accountId },
  });

  const [removeCSM] = useMutation<RemoveCSMData>(REMOVE_CSM);

  const refetchQueries = [{ query: ACCOUNT, variables: { id: accountId } }];

  return (
    <>
      <UpdateAccountInfoModal
        isOpen={activeModal === "EDIT_ACCOUNT"}
        onClose={closeModal}
        accountId={accountId}
        refetchQueries={refetchQueries}
      />
      <AssignAccountCSMModal
        isOpen={activeModal === "ASSIGN_CSM"}
        onClose={closeModal}
        accountId={accountId}
        refetchQueries={refetchQueries}
      />
      <div className="AccountDetails container mx-auto bg-gray-100 mt-4 md:p-4 rounded-2xl text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.account ? (
          <p>Failed to load</p>
        ) : (
          <div className="md:px-4 grid lg:grid-cols-3 gap-4">
            <div>
              <Card>
                <div className="flex justify-between items-center">
                  <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8 truncate">
                    Account Info
                  </h4>
                  <Button
                    kind="tertiary"
                    size="xs"
                    color="indigo"
                    onClick={() => setActiveModal("EDIT_ACCOUNT")}
                  >
                    <span className="mr-2">
                      <FAIcon icon="pencil-alt" />
                    </span>{" "}
                    Edit Info
                  </Button>
                </div>
                <div className="py-6">
                  <DetailList>
                    <DetailItem label="ID">
                      <p className="text-xs">
                        <CopyText
                          text={data.account.id}
                          label={data.account.id}
                        />
                      </p>
                    </DetailItem>
                    <DetailItem label="Billing Email">
                      <a
                        href={`mailto:${data.account.billingEmail}`}
                        className="text-sm text-blue-500 underline"
                      >
                        {data.account.billingEmail}
                      </a>
                    </DetailItem>
                    <DetailItem label="Stripe URL">
                      {data.account.stripeCustomerPageUrl ? (
                        <a
                          href={data.account.stripeCustomerPageUrl}
                          target="_blank"
                          rel="noref noreferrer nofollow"
                          className="text-sm text-blue-500 underline"
                        >
                          Stripe Page
                          <span className="ml-2">
                            <FAIcon icon="external-link-alt" />
                          </span>
                        </a>
                      ) : (
                        "-"
                      )}
                    </DetailItem>
                    <DetailItem label="Salesforce URL">
                      {data.account.salesforceAccountPageUrl ? (
                        <a
                          href={data.account.salesforceAccountPageUrl}
                          target="_blank"
                          rel="noref noreferrer nofollow"
                          className="text-sm text-blue-500 underline"
                        >
                          Salesforce Page
                          <span className="ml-2">
                            <FAIcon icon="external-link-alt" />
                          </span>
                        </a>
                      ) : (
                        "-"
                      )}
                    </DetailItem>
                    <DetailItem label="AristaMD URL">
                      {
                        data.account.aristamdOrganizationSettingsPageUrl ? (
                          <a
                            href={data.account.aristamdOrganizationSettingsPageUrl}
                            target="_blank"
                            rel="noref noreferrer nofollow"
                            className="text-sm text-blue-500 underline"
                          >
                            AristaMD Page
                            <span className="ml-2">
                              <FAIcon icon="external-link-alt" />
                            </span>
                          </a>
                        ) : (
                          "-"
                        )
                      }
                    </DetailItem>
                    <DetailItem label="Created At">
                      <p>
                        {lightFormat(
                          parseISO(data.account.insertedAt),
                          "M/d/yy - h:mm a"
                        )}
                      </p>
                    </DetailItem>
                  </DetailList>
                </div>
              </Card>
              <Card className="mt-3">
                <div className="flex justify-between items-center">
                  <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8 truncate">
                    Organizations
                  </h4>
                </div>

                {data.account.organizations.map((o) => (
                  <Link
                    key={o.id}
                    to={`/admin/organizations/${o.id}`}
                    className="mt-2 border border-cool-gray-200 duration-100 flex font-semibold hover:bg-blue-100 hover:shadow-md items-center p-4 rounded-lg shadow text-blue-700 text-lg transition-colors"
                  >
                    <CircleIcon icon="hospital-alt" color="blue" />
                    <span className="ml-3">{o.name}</span>
                  </Link>
                ))}
              </Card>
            </div>
            <div>
              <Card>
                <div className="flex justify-between items-center">
                  <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8">
                    Subscription
                  </h4>
                </div>
                <div className="py-6">
                  {data.account.subscription ? (
                    <DetailList>
                      <DetailItem label="Plan">
                        <p className="leading-tight">
                          {data.account.subscription.plan.name}
                        </p>
                        <p className="text-xs text-cool-gray-600 italic font-light">
                          {data.account.subscription.plan.slug}
                        </p>
                      </DetailItem>
                      <DetailItem label="Interval">
                        <p className="capitalize">
                          {data.account.subscription.recurrence}
                        </p>
                      </DetailItem>
                      {/* <DetailItem label="MRR (Est.)">$200/mo</DetailItem> */}
                      <DetailItem label="Full Users">
                        {data.account.numFullUsers}{" "}
                        <span className="text-small text-cool-gray-500 uppercase">
                          used
                        </span>{" "}
                        / {data.account.subscription.planLicense.quantity}{" "}
                        licensed
                      </DetailItem>
                      <DetailItem label="Light Users">1 / 2</DetailItem>
                      <DetailItem label="Referral Forms">3 / 4</DetailItem>
                      <DetailItem label="Patient Forms">1 / 2</DetailItem>
                      <DetailItem label="Addons">
                        <ul className="leading-normal list-disc px-4 text-sm">
                          {data.account.subscription.addons.map((addon) => (
                            <li key={addon.id}>{addon.name}</li>
                          ))}
                        </ul>
                      </DetailItem>
                    </DetailList>
                  ) : (
                    <p>Account does not have a subscription</p>
                  )}
                </div>
              </Card>

              <Card className="mt-3">
                <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8">
                  Feature Flags
                </h4>
                <div className="py-4">
                  {data.account.organizations.map(o => (
                    <div key={o.id}>
                      <div className="flex items-center justify-between">
                        <h5 className="font-semibold text-lg text-gray-700">{o.name}</h5>
                        <Link to={`/admin/organizations/${o.id}/features`} className="text-sm text-indigo-500 hover:text-indigo-600">
                          <span className="mr-2">
                            <FAIcon icon="pencil-alt" />
                          </span>
                          Edit
                        </Link>
                      </div>
                      <div className="pt-3">
                        {o.featureFlags.map(f => (
                          <div key={f.feature} className="flex py-1">
                            <p>{featureLabel(f)}</p>
                            <div className="flex-grow border-b border-dotted" />
                            {f.enabled ? (
                              <Badge color="green">On</Badge>
                            ) : (
                              <Badge color="gray">Off</Badge>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
            <div className="rounded-lg shadow-lg p-4 bg-white">
              <div className="flex justify-between items-center">
                <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8">
                  Customer Success Manager
                </h4>
                <Button
                  kind="tertiary"
                  size="xs"
                  color="indigo"
                  onClick={() => setActiveModal("ASSIGN_CSM")}
                >
                  <span className="mr-2">
                    <FAIcon icon="pencil-alt" />
                  </span>{" "}
                  Edit CSM
                </Button>
              </div>
              <div className="py-6">
                {data.account.csm ? (
                  <div className="text-center">
                    <CurrentCSM csm={data.account.csm} />
                    <Button
                      kind="tertiary"
                      size="xs"
                      color="red"
                      onClick={() =>
                        removeCSM({
                          variables: { id: accountId },
                          refetchQueries,
                        }).then((resp) => {
                          if (resp.data?.removeAccountCsm.account) {
                            // it worked...
                            // toast.success("CSM Removed")
                          } else {
                            // toast.error("Something went wrong.")
                          }
                        })
                      }
                      className="mt-2"
                    >
                      <span className="mr-2">
                        <FAIcon icon="times" />
                      </span>{" "}
                      Remove CSM
                    </Button>
                  </div>
                ) : (
                  <div className="text-center">
                    <p className="bg-cool-gray-100 inline mx-auto p-3 rounded-lg text-cool-gray-600">
                      None Assigned
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="mt-4">
          <JsonDebugger data={data} />
        </div>
      </div>
    </>
  );
};
