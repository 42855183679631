import React, { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { TableContainer, Table, TH, TD, Spinner, FAIcon, DropdownButton, DropdownItemButton } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { Modal, ModalHeader } from "components/Modal";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { NoResults } from "components/NoResults";
import { NewNPPESLocationForm } from "./NewNPPESLocationForm";
import { EditNPPESLocationForm } from "./EditNPPESLocationForm";
import { NPPESLocation } from "./model";

const PAGE_SIZE = 30;

const LOCATIONS = gql`
  query LocationsForNPPESOrg(
    $first: Int
    $after: UUID4
    $filter: ListNppesLocationsFilter
  ) {
    nppesLocations(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        name
        npi
        phone {
          raw
          formatted
        }
        hqPhone {
          raw
          formatted
        }
        phoneExtension
        fax {
          raw
          formatted
        }
        faxExtension
        streetAddress
        streetAddressLine2
        city
        stateAbbreviation
        state {
          id
          name
        }
        zip
        county {
          id
          name
        }
        taxonomies {
          id,
          name,
          taxonomyCode
        }
        timeZoneName
        verifiedData
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  nppesLocations: {
    cursor?: string;
    endOfList: boolean;
    items: NPPESLocation[];
  };
}

interface Variables {
  after?: string;
  first: number;
  filter?: Filter;
}

interface Filter {
  searchTerm?: string;
  nppesOrganizationId?: string;
}

type ActiveModal = { type: "NEW" | "EDIT", locationId?: string };

interface NPPESOrganizationLocationsProps {
  nppesOrganizationId: string;
}

export const NPPESOrganizationLocations: FC<NPPESOrganizationLocationsProps> = (
  props
) => {
  const { nppesOrganizationId } = props;

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const { data, loading, error } = useQuery<Data, Variables>(LOCATIONS, {
    variables: {
      first: PAGE_SIZE,
      filter: {
        nppesOrganizationId,
      },
    },
  });

  return (
    <div className="NPPESOrganizationLocations bg-gray-100 mt-4 p-4 rounded-2xl text-left">
      <ScreenTitle title="NPPES Organizations » View Organization » Locations" />
      <Modal isOpen={activeModal?.type === "NEW"} onRequestClose={closeModal}>
        <ModalHeader
          icon="map-marker-alt"
          title="Add Location"
          onClose={closeModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <NewNPPESLocationForm
            closeModal={closeModal}
            nppesOrganizationId={nppesOrganizationId}
            refetchQueries={[
              {
                query: LOCATIONS,
                variables: {
                  first: PAGE_SIZE,
                  filter: {
                    nppesOrganizationId,
                  },
                },
              },
            ]}
          />
        </div>
      </Modal>
      {activeModal && activeModal.locationId && <Modal isOpen={activeModal?.type === "EDIT"} onRequestClose={closeModal}>
        <ModalHeader
          icon="map-marker-alt"
          title="Edit Location"
          onClose={closeModal}
        />
        <div className="px-8 pt-6 pb-8 flex flex-col">
          <EditNPPESLocationForm
            closeModal={closeModal}
            nppesLocationId={activeModal.locationId}
            refetchQueries={[
              {
                query: LOCATIONS,
                variables: {
                  first: PAGE_SIZE,
                  filter: {
                    nppesOrganizationId,
                  },
                },
              },
            ]}
          />
        </div>
      </Modal>}
      <div>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !(data && data.nppesLocations) ? (
          <p>Failed to load.</p>
        ) : (
          <div>
            <div className="flex justify-between px-3 pb-4">
              <div className="_FilterPanel" />
              <button
                type="button"
                className="btn btn-blue"
                onClick={() => setActiveModal({ type: "NEW" })}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Location
              </button>
            </div>
            {data.nppesLocations.items.length === 0 ? (
              <NoResults icon="map-marker-alt" text="No locations" />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH>Address</TH>
                      <TH>Actions</TH>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.nppesLocations.items.map((location) => (
                      <tr key={location.id}>
                        <TD>{location.name}</TD>
                        <TD>
                          {format(parseISO(location.insertedAt), "M/d/yy")}
                        </TD>
                        <TD>
                          <DropdownButton>
                            <DropdownItemButton onClick={() => {
                              setActiveModal({ type: "EDIT", locationId: location.id });
                            }}>
                              Edit Location
                            </DropdownItemButton>
                          </DropdownButton>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
