import React, { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Avatar,
  FAIcon,
  Spinner,
  ImageObject,
  Table,
  TH,
  TD,
} from "@preferral/ui";
import { Modal, ModalHeader } from "components/Modal";
import { NewNPPESProviderForm } from "./NewNPPESProviderForm";
import { NoResults } from "components/NoResults";
import { Link } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";

const PAGE_SIZE = 30;

const LIST_NPPES_PROVIDERS = gql`
  query ListNPPESProviders(
    $first: Int
    $after: UUID4
    $filter: ListNppesProvidersFilter
  ) {
    nppesProviders(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        npi
        nameWithAppellation
        avatar(size: "thumbnail") {
          base64
          src
          srcSet
        }
        primarySpecialty {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  nppesProviders: Paginated<NPPESProvider>;
}

interface NPPESProvider {
  id: string;
  npi: string;
  nameWithAppellation: string;
  avatar: ImageObject;
  primarySpecialty?: Specialty;
}

interface Specialty {
  id: string;
  name: string;
}

/**
 * ProviderRow.
 */

interface ProviderRowProps {
  provider: NPPESProvider;
}

const ProviderRow: FC<ProviderRowProps> = (props) => {
  const { provider } = props;

  return (
    <li>
      <Link
        to={`/admin/nppes_providers/${provider.id}`}
        className="block hover:bg-gray-50 border-b border-gray-200 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
      >
        <div className="flex items-center px-2 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <Avatar size="thumb" image={provider.avatar} />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                  {provider.nameWithAppellation}
                  <span className="italic ml-2 text-gray-400 text-xs">
                    NPI: {provider.npi}
                  </span>
                </div>
                {provider.primarySpecialty && (
                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                    <span className="mr-2">
                      <FAIcon icon="stethoscope" />
                    </span>
                    <span className="truncate">
                      {provider.primarySpecialty.name}
                    </span>
                  </div>
                )}
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-gray-900">
                    Applied on
                    <time dateTime="2020-01-07">January 7, 2020</time>
                  </div>
                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                    <svg
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Completed phone screening
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

type ProviderModalModel = "NEW_PROVIDER" | "EDIT_PROVIDER";

interface NPPESProvidersScreenProps { }

export const NPPESProvidersScreen: FC<NPPESProvidersScreenProps> = () => {
  const [currentModal, setCurrentModal] = useState<ProviderModalModel | null>(
    null
  );

  const { data, loading, error } = useQuery<Data>(LIST_NPPES_PROVIDERS, {
    variables: { first: PAGE_SIZE },
  });

  const openNewModal = () => setCurrentModal("NEW_PROVIDER");
  const closeModal = () => setCurrentModal(null);

  return (
    <div className="_ProvidersScreen">
      <ScreenTitle title="Providers" />
      <Modal
        isOpen={currentModal === "NEW_PROVIDER"}
        onRequestClose={closeModal}
      >
        <ModalHeader
          icon="user-doctor"
          title="New NPPES Provider"
          onClose={closeModal}
        />
        <div className="p-4">
          <NewNPPESProviderForm refetchQueries={[]} onSuccess={closeModal} />
        </div>
      </Modal>
      <div className="p-4 flex justify-end">
        <button type="button" className="btn btn-blue" onClick={openNewModal}>
          <FAIcon icon="plus" />
          <span className="hidden md:visible ml-2">New Provider</span>
        </button>
      </div>
      <div className="mt-4 lg:px-4">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.nppesProviders ? (
          <p>Failed to load</p>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            {data.nppesProviders.items.length === 0 ? (
              <NoResults icon="user-doctor" text="No Results" />
            ) : (
              <>
                <ul>
                  {data.nppesProviders.items.map((prov) => (
                    <ProviderRow key={prov.id} provider={prov} />
                  ))}
                </ul>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
