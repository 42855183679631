import { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

/**
 * NavTabs
 */

interface NavTabsProps { }

export const NavTabs: FC<NavTabsProps> = props => {
  const { children } = props;

  return <ul className="flex flex-wrap">{children}</ul>;
};

/**
 * NavTabLink
 */

type NavTabLinkProps = NavLinkProps;

export const NavTabLink: FC<NavTabLinkProps> = props => {
  return (
    <li className="mr-2">
      <NavLink
        {...props}
        className="border border-transparent font-semibold hover:bg-blue-100 hover:border-gray-200 hover:shadow-inner inline-block px-3 px-6 py-1 rounded rounded-full text-blue-500"
        activeClassName="shadow-inner"
        activeStyle={{
          borderColor: "#fff",
          backgroundColor: "#bee3f8",
          color: "#2b6cb0"
        }}
      />
    </li>
  );
};
