import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Spinner, Button } from "@preferral/ui";
import { SubscriptionModel, SubscriptionStatusModel } from "./model";

const ACCOUNT_BILLING = gql`
  query GetAccountBilling($id: UUID4!) {
    account(id: $id) {
      id
      numFullUsers
      subscription {
        id
        status
        billedExternally
        stripeId
        collectionMethod
        currentPeriodEnd
        cancelAtPeriodEnd
        card {
          brand
          address_line1
          address_line2
          address_city
          address_state
          address_country
          address_zip
          exp_month
          exp_year
          last4
          name
          funding
        }
        bank {
          id
          bankName
          last4
        }
        recurrence
        planLicense {
          id
          quantity
          stripePlan {
            id
            nickname
          }
          plan {
            id
            name
            slug
          }
        }
        addonLicenses {
          id
          stripePlan {
            id
            nickname
          }
          addon {
            id
            name
          }
        }
      }
    }
  }
`;

interface Data {
  account: {
    id: string;
    subscription: SubscriptionModel;
  };
}

const RENEW_SUBSCRIPTION = gql`
  mutation RenewSubscription($id: UUID4!) {
    renewSubscription(id: $id) {
      errors {
        key
        message
      }
      subscription {
        id
        status
      }
    }
  }
`;

interface RenewSubscriptionData {
  renewSubscription: {
    errors?: InputError[];
    subscription?: {
      id: string;
      status: SubscriptionStatusModel;
    };
  };
}

interface RenewSubscriptionVariables {
  id: string;
}

/**
 * AccountBilling.
 */

interface AccountBillingProps {
  accountId: string;
}

export const AccountBilling: FC<AccountBillingProps> = (props) => {
  const { accountId } = props;
  const { data, loading, error } = useQuery<Data>(ACCOUNT_BILLING, {
    variables: { id: accountId },
  });

  const subscription = data?.account.subscription;

  const isCancelled = Boolean(subscription?.cancelAtPeriodEnd);

  const [renewSubscription, { loading: isRenewing }] = useMutation<
    RenewSubscriptionData,
    RenewSubscriptionVariables
  >(RENEW_SUBSCRIPTION);

  const doRenewSubscription = useCallback(() => {
    if (subscription?.id) {
      return renewSubscription({
        variables: { id: subscription.id },
        refetchQueries: [
          {
            query: ACCOUNT_BILLING,
            variables: { id: accountId },
          },
        ],
      });
    }
    return null;
  }, [subscription?.id, renewSubscription]);

  const goToUpdatePlan = () => { };

  return (
    <div className="_AccountBilling container mx-auto bg-gray-100 mt-4 md:p-4 rounded-2xl text-left">
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !subscription ? (
        <p>Failed to load</p>
      ) : (
        <>
          <div className="md:px-4 grid lg:grid-rows-2 lg:grid-cols-2 gap-4">
            <div className="rounded-xl shadow-lg p-4 lg:p-8 bg-white col-span-2">
              <div className="_CurrentPlanHeader flex justify-between">
                <div>
                  <p className="font-semibold text-cool-gray-700">
                    Current Preferral plan{" "}
                    {isCancelled ? "" : `(billed ${subscription.recurrence})`}
                  </p>
                  <h3 className="text-2xl font-black text-cool-gray-800 leading-tight">
                    {subscription.planLicense.plan.name}
                  </h3>
                  <p className="leading-tight text-xs text-cool-gray-500 italic font-light">
                    {subscription.planLicense.plan.slug}
                  </p>
                </div>
                <div>
                  {isCancelled ? (
                    <button
                      onClick={doRenewSubscription}
                      disabled={isRenewing}
                      type="button"
                      color="blue"
                    >
                      Re-enable subscription
                    </button>
                  ) : (
                    <Button
                      type="button"
                      color="blue"
                      size="sm"
                      onClick={goToUpdatePlan}
                    >
                      Update Plan
                    </Button>
                  )}
                </div>
              </div>

              {/* <LicenseDetailLine
                totalCount={subscription.planLicense.quantity}
                mainText={`${subscription.planLicense.quantity} ${
                  subscription.planLicense.plan.name
                } user ${maybePluralize(
                  "license",
                  subscription.planLicense.quantity
                )}`}
                assignCount={}
              /> */}
            </div>
          </div>
          <pre className="text-xs">{JSON.stringify(subscription, null, 2)}</pre>
        </>
      )}
    </div>
  );
};
