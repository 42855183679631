import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  SelectInput,
  VerticalField,
  HorizontalField,
  SelectInputProps,
} from "@preferral/ui";

const LIST_ADMINS = gql`
  query ListAdmins($first: Int, $after: UUID4, $filter: ListAdminsFilter) {
    admins(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        firstName
        lastName
      }
    }
  }
`;

interface Data {
  admins: Paginated<AdminModel>;
}

interface Variables {
  first?: number;
  after?: string;
  filter?: AdminsFilterModel;
}

interface AdminModel {
  id: string;
  firstName: string;
  lastName: string;
}

interface AdminsFilterModel {
  searchTerm?: string;
}

export type AdminSelectInputProps = Omit<SelectInputProps, "options">;

export const AdminSelectInput: FC<AdminSelectInputProps> = (props) => {
  const { data, loading } = useQuery<Data, Variables>(LIST_ADMINS);
  const options =
    data?.admins.items.map((a) => ({
      label: `${a.firstName} ${a.lastName}`,
      value: a.id,
    })) || [];
  return <SelectInput options={options} isLoading={loading} {...props} />;
};

interface AdminSelectFieldProps extends AdminSelectInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const AdminSelectField: FC<AdminSelectFieldProps> = (props) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <AdminSelectInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalAdminSelectField: FC<AdminSelectFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;
  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <AdminSelectInput {...rest} />
    </HorizontalField>
  );
};
