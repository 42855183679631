import React from "react";
import { ScreenTitle } from "context/ScreenTitle";

type PlansScreenProps = {};

const PlansScreen: React.FC<PlansScreenProps> = () => {
  return (
    <div className="PlansScreen">
      <ScreenTitle title="Plans" />
      <p>PlansScreen</p>
    </div>
  );
};

export { PlansScreen };
