import { FC, useState, useCallback } from "react";
import { InputTextArea, Button, VerticalField } from '@preferral/ui';

interface ImportQuestionnaireJSONFormProps {
  onSave(value: any): void;
};

export const ImportQuestionnaireJSONForm: FC<ImportQuestionnaireJSONFormProps> = props => {
  const { onSave } = props;
  const [templateJSON, setTemplateJSON] = useState("")
  const [error, setError] = useState("");

  const handleSave = useCallback(
    () => {
      try {
        const template = JSON.parse(templateJSON);
        return onSave(template);
      } catch (e) {
        console.error(e);
        setError((e && e.message) || JSON.stringify(e));
      }
    }, [templateJSON, onSave, setError]
  );

  return (
    <div className="_ImportQuestionnaireJSONForm">
      {error ? (
        <p className="py-2 text-sm text-red-500">{error}</p>
      ) : null}
      <div className="mt-3">
        <VerticalField label="Template JSON">
          <InputTextArea
            minRows={10}
            maxRows={30}
            value={templateJSON}
            onChange={setTemplateJSON}
            className="form-input w-full font-mono text-xs text-green-400 bg-gray-800"
            inputProps={{ placeholder: "Paste JSON here..." }}
          />
        </VerticalField>
      </div>
      <div className="mt-6 text-center">
        <Button
          type="button"
          color="blue"
          kind="primary"
          onClick={handleSave}
        >
          Import Template
        </Button>
      </div>
    </div>
  );
};
