import { FC } from "react";
import { FilterForm, FilterField, Button, SelectInput, TextInput } from "@preferral/ui";

export type AccountStatusModel = "trial" | "trial_ended" | "active" | "grace_period" | "suspended" | "deactivated";

export interface Filter {
  status: AccountStatusModel | "ALL";
  searchTerm?: string;
  accountTagIds?: string[];
};

interface FilterPanelProps {
  value: Filter;
  onChange(filter: Filter): void;
  isLoading: boolean;
}

interface AccountStatusOption {
  value: AccountStatusModel | "ALL";
  label: string;
};

const statusOptions: AccountStatusOption[] = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "trial",
    label: "Trial",
  },
  {
    value: "trial_ended",
    label: "Trial Ended",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "grace_period",
    label: "Grace Period",
  },
  {
    value: "suspended",
    label: "Suspended",
  },
  {
    value: "deactivated",
    label: "Deactivated",
  },
];

export const defaultFilter: Filter = {
  status: "ALL",
  searchTerm: ""
};

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value = defaultFilter, onChange, isLoading } = props;

  return (
    <FilterForm<Filter>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="status" icon="filter" label="Account Status">
          <div className="w-48">
            <SelectInput name="status" options={statusOptions} />
          </div>
        </FilterField>

        <FilterField htmlFor="searchTerm" icon="search" label="Search">
          <TextInput
            name="searchTerm"
            icon="search"
            inputProps={{
              placeholder: "Search...",
            }}
          />
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button type="submit" color="blue" disabled={isLoading} isLoading={isLoading}>
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  )
};
