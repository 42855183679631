import { FC, useCallback, useState } from "react";
import { gql } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { useFormik, FormikProvider } from "formik";
import { FAIcon, Icon, JsonDebugger, PillTab, PillTabs, Spinner } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  QUESTIONNAIRE_FRAGMENT,
  questionnaireForServer,
  QuestionnaireModel,
  Form as PreviewForm
} from "components/Questionnaire";
import {
  QuestionnaireTemplateEditor,
  defaultReferralForm
} from "components/QuestionnaireTemplateEditor";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";

const GET_SPECIALTY_REFERRAL_FORM = gql`
  query GetSpecialtyReferralForm($id: UUID4!) {
    specialty(id: $id) {
      id
      name
      taxonomyCode
      parentSpecialty {
        id
        name
      }
      organization {
        id
        name
      }
      referralForm {
        ...QuestionnaireFields
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface Data {
  specialty: {
    id: string;
    name: string;
    taxonomyCode: string;
    parentSpecialty: null | {
      id: string;
      name: string;
    };
    organization: null | {
      id: string;
      name: string;
    };
    referralForm: null | QuestionnaireModel;
  };
}

interface Variables {
  id: string;
}

const UPDATE_SPECIALTY_REFERRAL_FORM = gql`
  mutation UpdateSpecialtyReferralForm(
    $id: UUID4!
    $input: SpecialtyInput!
  ) {
    updateSpecialty(id: $id, input: $input) {
      errors {
        key
        message
      }
      specialty {
        id
        referralForm {
          ...QuestionnaireFields
        }
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

interface MutationData {
  updateSpecialty: {
    errors?: InputError[];
    specialty?: {
      id: string;
      referralForm: QuestionnaireModel;
    };
  };
}

interface MutationVariables {
  id: string;
  input: { referralForm: QuestionnaireModel };
}

interface FormValues {
  referralForm: QuestionnaireModel;
}

const BackToLink: React.FC<{ to: string; label: string }> = ({ to, label }) => {
  return (
    <Link
      to={to}
      className="text-blue-600 border-b border-blue-600 border-dashed"
    >
      <span className="mr-2">
        <FAIcon icon="chevron-left" />
      </span>{" "}
      {label}
    </Link>
  );
};

/**
 * EditReferralFormForm.
 */

interface SaveQuestionnaireTemplateResult {
  errors?: InputError[]
  specialty?: { id: string, referralForm: QuestionnaireModel }
}

interface EditReferralFormFormProps {
  questionnaireTemplate: QuestionnaireModel
  save(template: QuestionnaireModel): Promise<SaveQuestionnaireTemplateResult | null | undefined>
  onSuccess?(): void
}

type ActiveTab = 'EDITOR' | 'LIVE_PREVIEW';

const EditReferralFormForm: FC<EditReferralFormFormProps> = (props) => {
  const { questionnaireTemplate, save, onSuccess } = props

  const [activeTab, setActiveTab] = useState<ActiveTab>(
    'EDITOR',
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions

      setStatus({ errors: null })

      return save(values.referralForm).then(
        (resp) => {
          if (resp?.errors) {
            setStatus({ errors: resp.errors })
          } else if (resp?.specialty?.id) {
            // it worked...
            toast.success('Referral form updated')
            if (onSuccess) {
              onSuccess()
            }
          }
          setSubmitting(false)
        },
        (err) => {
          console.error(err);
          setStatus({
            errors: [{ key: '', message: 'Something went wrong' }],
          })
        },
      )
    },
    [save, onSuccess],
  )

  const formikBag = useFormik<FormValues>({
    initialValues: {
      referralForm: questionnaireTemplate,
    },
    onSubmit,
  })

  const { values, status, handleSubmit } = formikBag

  return (
    <>
      <PillTabs activeTab={activeTab} onChange={setActiveTab as (value: ActiveTab) => void}>
        <PillTab tab="EDITOR">
          Editor
        </PillTab>
        <PillTab tab="LIVE_PREVIEW">
          Live Preview
        </PillTab>
      </PillTabs>

      {
        activeTab === 'LIVE_PREVIEW' ? (
          <PreviewForm template={values.referralForm} />
        ) : (
          <FormikProvider value={formikBag}>
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <QuestionnaireTemplateEditor name="referralForm" />

              <div className="flex justify-around p-4">
                <button type="submit" className="btn btn-blue">
                  Save
                </button>
              </div>
            </form>
          </FormikProvider>
        )
      }
    </>
  )
}

interface SpecialtyReferralFormEditScreenProps { }

interface RouteParams {
  specialtyId: string;
}

export const SpecialtyReferralFormEditScreen: FC<SpecialtyReferralFormEditScreenProps> = props => {
  const { specialtyId } = useParams<RouteParams>();

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    GET_SPECIALTY_REFERRAL_FORM,
    { variables: { id: specialtyId } }
  );

  const [updateReferralFormTemplate] = useMutation<
    MutationData,
    MutationVariables
  >(UPDATE_SPECIALTY_REFERRAL_FORM);

  const saveTemplate = useCallback(
    (questionnaireTemplate: QuestionnaireModel) => {
      return updateReferralFormTemplate({
        variables: {
          id: specialtyId,
          input: { referralForm: questionnaireForServer(questionnaireTemplate) }
        }
      }).then(resp => resp.data?.updateSpecialty);
    },
    [specialtyId, updateReferralFormTemplate]
  );

  return (
    <>
      <ScreenTitle title={["Specialty", "Referral Form Template"]} />
      <div className="_SpecialtyReferralFormEditScreen">
        <BackToLink
          to={`/admin/specialties`}
          label="Back to Specialties"
        />
        <div className="mt-4 bg-white border border-gray-300 p-4 rounded-lg shadow-md">
          {loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !data?.specialty ? (
            <h1>Failed to load</h1>
          ) : (
            <>
              <div className="p-5 flex items-center justify-between">
                {/* <div>
                <img src="org-logo-TODO" />
              </div> */}
                <div>
                  <p className="font-semibold text-3xl text-gray-900">
                    {data.specialty.name}{' '}
                    <span className="font-light text-2xl text-gray-600">
                      Referral Form
                    </span>
                  </p>
                  {/* <p className="font-semibold pt-3 text-gray-700">
                    {data.specialty.department.organization.name}
                  </p> */}
                  {/* {data.specialty.department.name !==
                    data.specialty.department.organization.name ? (
                    <p className="font-semibold text-gray-700">
                      <span className="font-normal text-gray-600 text-xs">
                        Department:
                      </span>{' '}
                      {data.specialty.department.name}
                    </p>
                  ) : null} */}
                </div>
                <div>
                  <div className="flex items-center">
                    <Icon icon="widget-add" />
                    <p className="font-semibold ml-2 text-teal-600 text-xl uppercase">
                      Form Builder
                    </p>
                  </div>
                </div>
              </div>

              <EditReferralFormForm
                questionnaireTemplate={
                  data.specialty.referralForm || defaultReferralForm
                }
                save={saveTemplate}
                onSuccess={refetch}
              />
            </>
          )}
        </div>
        <JsonDebugger data={data} />
      </div>
    </>
  );
};
