import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FAIcon } from "@preferral/ui";
// import "./NavMenu.css";

interface LinkModel {
  icon: IconProp;
  path: string;
  label: string;
  exact?: boolean;
}

interface NavMenuProps {
  title: string;
  links: LinkModel[];
}

export const NavMenu: FC<NavMenuProps> = (props) => {
  const { title, links } = props;
  return (
    <div className="NavMenu mt-10 mx-auto w-48 text-left">
      <h5 className="mb-3 lg:mb-2 text-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs">
        {title}
      </h5>
      <ul>
        {links.map((link) => (
          <li key={link.path} className="mb-3 lg:mb-1">
            <NavLink
              key={link.path}
              to={link.path}
              exact={link.exact}
              className="px-2 -mx-2 py-1 transition-fast relative block hover:translate-r-2px hover:text-gray-900 hover:bg-blue-200 text-sm text-gray-600 font-medium rounded-lg"
              activeClassName="text-blue-700 bg-blue-200"
            >
              <span className="rounded-lg absolute inset-0 bg-teal-200 opacity-0" />
              <span className="relative flex items-center">
                <span className="text-sm w-6 mr-1 text-center inline-block">
                  <FAIcon icon={link.icon} />
                </span>
                {link.label}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
