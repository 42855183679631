import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
// import logoSrc from "./logo.svg";
import logoSrc from "./aristamd_logo.svg";
import { useScreenTitle } from "context/ScreenTitle";
import { CurrentAdminMenu } from "components/CurrentAdminMenu";
import EmojiHappyIcon from '@heroicons/react/outline/EmojiHappyIcon';
import CreditCardIcon from '@heroicons/react/outline/CreditCardIcon';
import UsersIcon from '@heroicons/react/outline/UsersIcon';
import UserGroupIcon from '@heroicons/react/outline/UserGroupIcon';
import UserCircleIcon from '@heroicons/react/outline/UserCircleIcon';
import OfficeBuildingIcon from '@heroicons/react/outline/OfficeBuildingIcon';
import ShareIcon from '@heroicons/react/outline/ShareIcon';
import ChartPieIcon from '@heroicons/react/outline/ChartPieIcon';
import ExclamationIcon from '@heroicons/react/outline/ExclamationIcon';
import DotsHorizontalIcon from '@heroicons/react/outline/DotsHorizontalIcon';
import CameraIcon from '@heroicons/react/outline/CameraIcon';
import { ReactComponent as StethoscopeIcon } from 'images/icons/stethoscope.svg';

/**
 * TopNav.
 */

interface TopNavProps {
  sidebarOpen: boolean;
  setSidebarOpen(isOpen: boolean): void;
}

const TopNav: FC<TopNavProps> = (props) => {
  const { setSidebarOpen } = props;
  const { titleString, shortTitle } = useScreenTitle();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16">
      <button
        onClick={() => setSidebarOpen(true)}
        className="px-4 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
      >
        <svg
          className="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          <div className="w-full flex md:ml-0">
            <div className="flex items-center text-gray-400 w-full">
              <h2 className="hidden md:inline-block font-bold md:mx-4 md:text-lg text-base text-gray-700 whitespace-no-wrap">
                {titleString}
              </h2>
              <h2 className="inline-block md:hidden font-bold md:mx-4 md:text-lg text-base text-gray-700 whitespace-no-wrap">
                {shortTitle}
              </h2>
            </div>
          </div>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* <button className="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500">
            <svg
              className="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button> */}
          <div className="ml-3 relative">
            <CurrentAdminMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * SidebarNavLink.
 */
interface SidebarNavLinkProps {
  to: string;
  className?: string;
}

const SidebarNavLink: FC<SidebarNavLinkProps> = (props) => {
  const { to, className = "", children } = props;

  return (
    <NavLink
      to={to}
      className={`mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-700 rounded-md hover:text-blue-700 hover:bg-blue-100 focus:outline-none focus:bg-blue-100 transition ease-in-out duration-150 ${className}`}
      activeClassName="text-blue-800 bg-blue-100 hover:bg-blue-100 shadow-inner"
    >
      {children}
    </NavLink>
  );
};

/**
 * SidebarLinks.
 */
interface SidebarLinksProps {
  className?: string;
}

const SidebarLinks: FC<SidebarLinksProps> = (props) => {
  const { className = "" } = props;
  return (
    <nav className={`flex-1 px-2 py-4 ${className}`}>
      <h5 className="px-2 font-bold lg:mb-4 lg:text-xs text-gray-500 text-sm tracking-wide uppercase">
        Admin
      </h5>
      <SidebarNavLink to="/admin/accounts">
        <EmojiHappyIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Accounts
      </SidebarNavLink>

      <SidebarNavLink to="/admin/users">
        <UserGroupIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Users
      </SidebarNavLink>

      <SidebarNavLink to="/admin/nppes_providers">
        <UserCircleIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Providers
      </SidebarNavLink>

      <SidebarNavLink to="/admin/npi_avatars">
        <CameraIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        NPI Avatars
      </SidebarNavLink>

      <SidebarNavLink to="/admin/specialties">
        <StethoscopeIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Specialties
      </SidebarNavLink>

      <SidebarNavLink to="/admin/nppes_organizations">
        <OfficeBuildingIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Organizations
      </SidebarNavLink>

      <SidebarNavLink to="/admin/networks">
        <ShareIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Networks
      </SidebarNavLink>

      <SidebarNavLink to="/admin/analytics">
        <ChartPieIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Analytics
      </SidebarNavLink>

      <SidebarNavLink to="/admin/toolbox">
        <DotsHorizontalIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Tool Box
      </SidebarNavLink>

      <h5 className="px-2 font-bold mt-6 lg:mb-4 lg:text-xs text-gray-500 text-sm tracking-wide uppercase">
        Super Admin
      </h5>
      <SidebarNavLink to="/admin/billing">
        <CreditCardIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Billing
      </SidebarNavLink>

      <SidebarNavLink to="/admin/admins">
        <UsersIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Admins
      </SidebarNavLink>

      <SidebarNavLink to="/admin/data_integrity">
        <ExclamationIcon className="mr-3 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150" />
        Data Integrity Mgmt
      </SidebarNavLink>
    </nav>
  );
};

/**
 * Sidebar.
 */

interface SidebarProps {
  isOpen: boolean;
  onClose(): void;
}

const Sidebar: FC<SidebarProps> = (props) => {
  const { isOpen, onClose } = props;

  return (
    <>
      {/* Off-canvas menu for mobile */}
      <div
        onClick={onClose}
        className={`fixed inset-0 z-30 bg-gray-600 opacity-0 pointer-events-none transition-opacity ease-linear duration-300 ${isOpen
          ? "opacity-75 pointer-events-auto"
          : "opacity-0 pointer-events-none"
          }`}
      />
      <div
        className={`fixed inset-y-0 left-0 flex flex-col z-40 max-w-xs w-full bg-white transform ease-in-out duration-300 ${isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
      >
        <div className="absolute top-0 right-0 -mr-14 p-1">
          {isOpen ? (
            <button
              onClick={onClose}
              className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
            >
              <svg
                className="h-6 w-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : null}
        </div>
        <div className="bg-white border-r flex flex-shrink-0 h-16 items-center px-4">
          <img className="h-8 w-auto" src={logoSrc} alt="Preferral Logo" />
        </div>
        <div className="flex-1 h-0 overflow-y-auto">
          <SidebarLinks />
          {/* <nav className="px-2 py-4">
            <div className="flex justify-center">
              <span className="inline-flex rounded-md shadow-sm">
                <Link
                  to="/cw/p2p_request_wizard/new"
                  className="inline-flex items-center px-4 py-2 font-bold border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gold-600 hover:bg-gold-500 focus:outline-none focus:border-gold-700 focus:shadow-outline-yellow active:bg-gold-700 transition ease-in-out duration-150"
                >
                  <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
                  New P2P Request
                </Link>
              </span>
            </div>
            <NavLink
              to="/cw/dashboard"
              className="mt-4 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-navy-300 hover:text-white hover:bg-navy-700 focus:outline-none focus:text-white focus:bg-navy-700 transition ease-in-out duration-150"
              activeClassName="text-white bg-gray-900 hover:bg-gray-900"
            >
              <svg
                className="mr-4 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
                />
              </svg>
              Dashboard
            </NavLink>
            <NavLink
              to="/cw/requests"
              className="mt-1 group flex items-center px-2 py-2 text-base leading-6 font-medium rounded-md text-navy-300 hover:text-white hover:bg-navy-700 focus:outline-none focus:text-white focus:bg-navy-700 transition ease-in-out duration-150"
              activeClassName="text-white bg-gray-900 hover:bg-gray-900"
            >
              <svg
                className="mr-4 h-6 w-6 text-navy-300 group-hover:text-navy-300 group-focus:text-navy-300 transition ease-in-out duration-150"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              My P2P Requests
            </NavLink>
          </nav> */}
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0 bg-gray-50">
        <div className="flex flex-col w-64 border-r border-gray-200">
          <div className="flex items-center h-16 flex-shrink-0 px-4">
            <img
              className="h-8 w-auto mx-auto"
              src={logoSrc}
              alt="Preferral Logo"
            />
          </div>
          <div className="h-0 flex-1 flex flex-col overflow-y-auto">
            <SidebarLinks />
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * PortalLayout.
 */

interface PortalLayoutProps { }

export const PortalLayout: FC<PortalLayoutProps> = (props) => {
  const { children } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div
      className="_PortalLayout h-screen flex overflow-hidden"
      style={{ backgroundColor: "#f2f6fa" }}
    >
      <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <TopNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          {children}
        </main>
      </div>
    </div>
  );
};
