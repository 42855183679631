import React from "react";
import { ScreenTitle } from "context/ScreenTitle";

type NoMatchScreenProps = {};

const NoMatchScreen: React.FC<NoMatchScreenProps> = () => {
  return (
    <div className="NoMatchScreen">
      <ScreenTitle title="Preferral Admin - 404" />
      <p>NoMatchScreen</p>
    </div>
  );
};

export { NoMatchScreen };
