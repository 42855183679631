import { FC } from "react";
import { FilterForm, FilterField, Button, TextInput } from "@preferral/ui";

export interface Filter {
  searchTerm?: string;
  hasConverted?: boolean | null;
  hasReceivedReferrals?: boolean | null;
  isInboundSource?: boolean | null;
  stateAbbreviations?: string[] | null;
  specialtyIds?: string[];
}

export const defaultFilter: Filter = {
  searchTerm: "",
  hasConverted: null,
  hasReceivedReferrals: null,
  isInboundSource: null,
  stateAbbreviations: [],
  specialtyIds: []
};

interface FilterPanelProps {
  value: Filter;
  onChange(filter: Filter): void;
  isLoading: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value, onChange, isLoading = false } = props;

  return (
    <FilterForm<Filter>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="searchTerm" icon="search" label="Search">
          <TextInput
            name="searchTerm"
            inputProps={{
              placeholder: "Search..."
            }}
          />
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button type="submit" color="blue" disabled={isLoading} isLoading={isLoading}>
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
