import React, { FC, useCallback } from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { Button, TextField } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import toast from "react-hot-toast";
import { gql, useMutation } from "@apollo/client";

const CREATE_NETWORK_SUBGROUP = gql`
  mutation CreateNetworkSubgroup(
    $networkId: UUID4!
    $input: NetworkSubgroupInput!
  ) {
    createNetworkSubgroup(networkId: $networkId, input: $input) {
      errors {
        key
        message
      }
      networkSubgroup {
        id
      }
    }
  }
`;

interface MutationData {
  createNetworkSubgroup: {
    errors?: InputError[];
    networkSubgroup?: {
      id: string;
    };
  };
}

interface MutationVariables {
  networkId: string;
  input: FormValues;
}

interface FormValues {
  name: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    name: Yup.string().required("Required"),
  })
  .required();

interface NewNetworkSubgroupFormProps {
  networkId: string;
  onSuccess(): void;
}

export const NewNetworkSubgroupForm: FC<NewNetworkSubgroupFormProps> = (
  props
) => {
  const { networkId, onSuccess } = props;
  const [createNetworkSubgroup] = useMutation<MutationData, MutationVariables>(
    CREATE_NETWORK_SUBGROUP
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await createNetworkSubgroup({
          variables: { networkId, input: values },
        });

        if (data?.createNetworkSubgroup.errors) {
          setStatus({ errors: data.createNetworkSubgroup.errors });
        } else if (data?.createNetworkSubgroup.networkSubgroup) {
          // it worked...
          toast.success("Success!");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [networkId, createNetworkSubgroup, onSuccess]
  );
  return (
    <Formik<FormValues>
      initialValues={{
        name: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="mt-3">
            <TextField name="name" label="Name" />
          </div>

          <div className="flex items-center justify-end mt-3">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Create Subnetwork
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
