import { FC, useCallback } from "react";
import { gql, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { FormStatusErrors } from 'components/formik/FormStatusErrors';
import { ProviderSearchSelectField } from "components/formik/ProviderSearchField";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";
import toast from "react-hot-toast";

const ADD_NPPES_PROVIDER_EMPLOYMENT = gql`
  mutation AddNPPESProviderEmployment($input: NPPESProviderEmploymentInput!) {
    addNppesProviderEmployment(input: $input) {
      errors {
        key
        message
      }
      nppesProvider {
        id
      }
    }
  }
`;

interface MutationData {
  addNppesProviderEmployment: {
    errors?: InputError[];
    nppesProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: {
    nppesProviderId: string;
    nppesOrganizationId: string;
    nppesLocationIds: string[];
  }
}

interface FormValues {
  nppesProvider: ProviderReferenceModel | null;
  nppesLocationIds: string[];
}


interface ProviderSearchAddFormProps {
  onSuccess?(): void;
  nppesOrganizationId: string;
};

export const ProviderSearchAddForm: FC<ProviderSearchAddFormProps> = props => {
  const { onSuccess, nppesOrganizationId } = props;

  const [addProvider] = useMutation<MutationData, MutationVariables>(
    ADD_NPPES_PROVIDER_EMPLOYMENT
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      const input: MutationVariables["input"] = {
        nppesOrganizationId,
        nppesProviderId: values.nppesProvider!.id,
        nppesLocationIds: values.nppesLocationIds
      };

      addProvider({
        variables: { input }
      }).then(
        (resp) => {
          if (resp?.data?.addNppesProviderEmployment?.errors) {
            setStatus({ errors: resp.data.addNppesProviderEmployment.errors });
          } else if (resp?.data?.addNppesProviderEmployment?.nppesProvider) {
            // it worked
            toast.success("Provider added.");
            if (onSuccess) {
              onSuccess();
            }
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
        }
      );
    },
    [addProvider, onSuccess]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        nppesProvider: null,
        nppesLocationIds: []
      }}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form id="_ProviderSearchAddForm" onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <div className="mt-3">
            <ProviderSearchSelectField
              label="Provider"
              name="nppesProvider"
              filter={{ kinds: ["nppes"] }}
              placeholder="Search for provider..."
            />
          </div>

          <div className="mt-3 flex items-center justify-end">
            <button
              type="submit"
              className="btn btn-blue"
              disabled={isSubmitting}
            >
              Add Provider
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
