import { FC } from "react";
import { FilterForm, FilterField, SelectInput, TextInput, Button } from '@preferral/ui';
import { gql, useQuery } from '@apollo/client';

export interface Filter {
  searchTerm: string;
  nppesLocationId?: string;
}

export const defaultFilter: Filter = {
  searchTerm: "",
  nppesLocationId: ""
};

interface FilterPanelProps {
  value: Filter;
  defaultValue?: Filter;
  onChange(value: Filter): void;
  isLoading: boolean;
  nppesOrganizationId: string;
}

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value, defaultValue = defaultFilter, onChange, isLoading, nppesOrganizationId } = props;

  // const { data, loading, error } = useQuery<Data, Variables>(NPPES_PROVIDER_FILTER_DATA, { variables: { nppesOrganizationId }});

  const nppesLocationOptions: any[] = [];

  return (
    <FilterForm<Filter>
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="nppesLocationId" icon="filter" label="Location">
          <div className="w-64">
            <SelectInput name="nppesLocationId" options={nppesLocationOptions} />
          </div>
        </FilterField>

        <FilterField htmlFor="searchTerm" icon="search" label="Search">
          <TextInput
            name="searchTerm"
            placeholder="Search..."
            icon="search"
          />
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button type="submit" color="blue" disabled={isLoading} isLoading={isLoading}>
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
