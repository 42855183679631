import React, { FC } from "react";

interface UserActivityProps {
  userId: string;
}

export const UserActivity: FC<UserActivityProps> = (props) => {
  const { userId } = props;
  return (
    <div className="UserActivity">
      <p>UserActivity</p>
      <p>{userId}</p>
    </div>
  );
};
