import React, { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/AuthContext";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
// import { FormStatusErrors } from "components/formik/FormStatusErrors";
// import { TextField } from "components/formik/TextField";
// import { useToast } from "layouts/PortalLayout/Toast";

const MUTATION = gql`
  mutation LoginAdmin($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
        admin {
          id
          email
          firstName
          lastName
          lastSignInAt
        }
      }
    }
  }
`;

interface MutationData {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
      admin: {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        lastSignInAt: string;
      };
    };
  };
}

interface MutationVariables {
  email: string;
  password: string;
}

type FormValues = {
  email: string;
  password: string;
};

type SignInFormProps = {
  refetchQueries: any;
};

const SignInForm: FC<SignInFormProps> = () => {
  const [signIn] = useMutation<MutationData, MutationVariables>(MUTATION);
  const { login } = useAuth();

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      signIn({
        variables: values,
      }).then(
        (resp) => {
          if (resp?.data?.login.errors) {
            setStatus({ errors: resp.data.login.errors });
          } else if (resp?.data?.login?.session?.token) {
            // it worked
            return login(resp.data.login.session.token);
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [signIn, login]
  );

  return (
    <div className="SignInForm m-8">
      <p className="font-bold text-2xl text-blue-900 text-left">Sign In</p>
      <Formik<FormValues>
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .trim()
            .required("Required"),
          password: Yup.string().required("Required"),
        })}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form className="max-w-xs mx-auto my-8" onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <Field
                className="form-input w-full"
                name="email"
                placeholder="Email"
                autoFocus
              />
            </div>
            <div className="mt-3">
              <Field
                className="form-input w-full"
                name="password"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { SignInForm };
