import React, { FC } from "react";
import { ScreenTitle } from "context/ScreenTitle";

interface DataIntegrityManagementScreenProps {}

export const DataIntegrityManagementScreen: FC<DataIntegrityManagementScreenProps> = () => {
  return (
    <div className="_DataIntegrityManagementScreen">
      <ScreenTitle title="Data Integrity" />
      <p>DataIntegrityManagementScreen</p>
    </div>
  );
};
