import { FC, useState, useCallback } from "react";
import { Modal, ModalHeader } from 'components/Modal';
import { PillTabs, PillTab } from '@preferral/ui'
import { ProviderSearchAddForm } from './ProviderSearchAddForm';
import { ProviderCreateAddForm } from './ProviderCreateAddForm';

interface AddProviderModalProps {
  nppesOrganizationId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess?(): void;
};

type ActiveTab = "SEARCH" | "ENTER";

export const AddProviderModal: FC<AddProviderModalProps> = props => {
  const { nppesOrganizationId, isOpen, onClose, onSuccess: onSuccessProp } = props;

  const [activeTab, setActiveTab] = useState<ActiveTab>("SEARCH");

  const onSuccess = useCallback(() => {
    if (onSuccessProp) {
      onSuccessProp();
    }
    onClose();
  }, [onClose, onSuccessProp])

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader
        icon="user-doctor"
        title="Add Provider"
        onClose={onClose}
      />
      <div className="flex justify-center">
        <PillTabs
          activeTab={activeTab}
          onChange={setActiveTab as (tab: string) => void}
        >
          <PillTab tab="SEARCH">Search</PillTab>
          <PillTab tab="ENTER">Create</PillTab>
        </PillTabs>
      </div>
      <div className="p-4">
        {
          activeTab === "SEARCH" ? (
            <ProviderSearchAddForm nppesOrganizationId={nppesOrganizationId} onSuccess={onSuccess} />
          ) : (
            <ProviderCreateAddForm onSuccess={onSuccess} />
          )
        }
      </div>
    </Modal>
  );
};
