import React, { FC, useCallback, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { NPPESProviderCard } from "./NPPESProviderCard";

const REMOVE_PROVIDER_EMPLOYMENT = gql`
  mutation RemoveNPPESProviderEmployment(
    $nppesProviderId: UUID4!
    $nppesOrganizationId: UUID4!
  ) {
    removeNppesProviderEmployment(
      nppesOrganizationId: $nppesOrganizationId
      nppesProviderId: $nppesProviderId
    ) {
      errors {
        key
        message
      }
      nppesProvider {
        id
      }
    }
  }
`;

interface MutationData {
  removeNppesProviderEmployment: {
    errors?: InputError[];
    nppesProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  nppesProviderId: string;
  nppesOrganizationId: string;
}

interface RemoveNPPESProviderFormProps {
  nppesProviderId: string;
  nppesOrganizationId: string;
  refetchQueries: any;
  closeModal(): void;
}

export const RemoveNPPESProviderForm: FC<RemoveNPPESProviderFormProps> = (
  props
) => {
  const {
    nppesProviderId,
    nppesOrganizationId,
    refetchQueries,
    closeModal,
  } = props;
  const [status, setStatus] = useState<{ errors?: InputError[] }>({});
  const [removeProviderMut, { loading: mutLoading }] = useMutation<
    MutationData,
    MutationVariables
  >(REMOVE_PROVIDER_EMPLOYMENT);

  const removeProvider = useCallback(() => {
    setStatus({});
    return removeProviderMut({
      variables: {
        nppesProviderId,
        nppesOrganizationId,
      },
      refetchQueries,
    }).then(
      (resp) => {
        if (resp?.data?.removeNppesProviderEmployment?.errors) {
          setStatus({ errors: resp.data.removeNppesProviderEmployment.errors });
        } else if (resp?.data?.removeNppesProviderEmployment?.nppesProvider) {
          // it worked
          // toast.success("Provider Removed");
          closeModal();
        }
      },
      (rej) => {
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      }
    );
  }, [
    refetchQueries,
    setStatus,
    closeModal,
    removeProviderMut,
    nppesProviderId,
    nppesOrganizationId,
  ]);

  return (
    <div>
      <NPPESProviderCard nppesProviderId={nppesProviderId} />
      <FormStatusErrors status={status} />
      <div className="mt-8 flex justify-end">
        <button
          type="button"
          className="mr-2 btn btn-gray-alt"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          disabled={mutLoading}
          type="button"
          className="btn btn-red"
          onClick={removeProvider}
        >
          Remove Provider
        </button>
      </div>
    </div>
  );
};
