import { FC } from "react";
import { PortalLayout } from "layouts/PortalLayout";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { AccountsScreen } from "screens/AccountsScreen/AccountsScreen";
import { AccountShowScreen } from "screens/AccountShowScreen/AccountShowScreen";
import { UsersScreen } from "screens/UsersScreen/UsersScreen";
import { UserShowScreen } from "screens/UserShowScreen";
import { AnalyticsScreen } from "screens/AnalyticsScreen/AnalyticsScreen";
import { NoMatchScreen } from "screens/NoMatchScreen/NoMatchScreen";
import { OrganizationShowScreen } from "screens/OrganizationShowScreen";
import { NPPESProvidersScreen } from "screens/NPPESProvidersScreen";
import { NPPESProviderShowScreen } from "screens/NPPESProviderShowScreen";
import { NPPESOrganizationsScreen } from "screens/NPPESOrganizationsScreen/NPPESOrganizationsScreen";
import { NPPESOrganizationShowScreen } from "screens/NPPESOrganizationShowScreen/NPPESOrganizationShowScreen";
import { NetworksScreen } from "./screens/NetworksScreen";
import { NetworkShowScreen } from "./screens/NetworkShowScreen";
import { AdminsScreen } from "screens/AdminsScreen";
import { AdminShowScreen } from "screens/AdminShowScreen";
import { BillingAdminScreen } from "screens/BillingAdminScreen";
import { DataIntegrityManagementScreen } from "screens/DataIntegrityManagementScreen";
import { ToolboxScreen } from "screens/ToolboxScreen";
import { NPIAvatarsScreen } from "screens/NPIAvatarsScreen";
import { SpecialtyReferralFormEditScreen } from "screens/SpecialtyReferralFormEditScreen";
import { SpecialtiesScreen } from "screens/SpecialtiesScreen";

interface AuthenticatedAppProps { }

const AuthenticatedApp: FC<AuthenticatedAppProps> = () => {
  const match = useRouteMatch();

  return (
    <div className="_AuthenticatedApp">
      <PortalLayout>
        <Switch>
          <Route exact path={`${match.path}/accounts`}>
            <AccountsScreen />
          </Route>
          <Route path={`${match.path}/accounts/:accountId`}>
            <AccountShowScreen />
          </Route>
          <Route exact path={`${match.path}/admins`}>
            <AdminsScreen />
          </Route>
          <Route path={`${match.path}/admins/:adminId`}>
            <AdminShowScreen />
          </Route>
          <Route path={`${match.path}/billing`}>
            <BillingAdminScreen />
          </Route>
          <Route exact path={`${match.path}/users`}>
            <UsersScreen />
          </Route>
          <Route path={`${match.path}/users/:userId`}>
            <UserShowScreen />
          </Route>
          <Route path={`${match.path}/organizations/:organizationId`}>
            <OrganizationShowScreen />
          </Route>
          <Route exact path={`${match.path}/nppes_providers`}>
            <NPPESProvidersScreen />
          </Route>
          <Route path={`${match.path}/nppes_providers/:nppesProviderId`}>
            <NPPESProviderShowScreen />
          </Route>
          <Route path={`${match.path}/npi_avatars`}>
            <NPIAvatarsScreen />
          </Route>
          <Route exact path={`${match.path}/specialties`}>
            <SpecialtiesScreen />
          </Route>
          <Route path={`${match.path}/specialties/:specialtyId/referral_form`}>
            <SpecialtyReferralFormEditScreen />
          </Route>

          <Route exact path={`${match.path}/nppes_organizations`}>
            <NPPESOrganizationsScreen />
          </Route>
          <Route
            path={`${match.path}/nppes_organizations/:nppesOrganizationId`}
          >
            <NPPESOrganizationShowScreen />
          </Route>
          <Route exact path={`${match.path}/networks`}>
            <NetworksScreen />
          </Route>
          <Route path={`${match.path}/networks/:networkId`}>
            <NetworkShowScreen />
          </Route>
          <Route path={`${match.path}/analytics`}>
            <AnalyticsScreen />
          </Route>
          <Route path={`${match.path}/data_integrity`}>
            <DataIntegrityManagementScreen />
          </Route>
          <Route path={`${match.path}/toolbox`}>
            <ToolboxScreen />
          </Route>
          <Route path="*">
            <NoMatchScreen />
          </Route>
        </Switch>
      </PortalLayout>
    </div>
  );
};

export { AuthenticatedApp };
