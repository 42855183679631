import { FC } from "react";
import { FilterForm, FilterField, TextInput, SelectInput, Button } from "@preferral/ui";

export type UserStatusModel = "trial" | "active";

export interface Filter {
  searchTerm?: string;
  status?: UserStatusModel | "ALL";
}

interface UserStatusOption {
  value: UserStatusModel | "ALL";
  label: string;
};

const statusOptions: UserStatusOption[] = [
  {
    value: "ALL",
    label: "All",
  },
  {
    value: "trial",
    label: "Trial",
  },
  {
    value: "active",
    label: "Active",
  },
];

export const defaultFilter: Filter = {
  status: "ALL",
  searchTerm: ""
};

interface FilterPanelProps {
  value: Filter;
  onChange(value: Filter): void;
  isLoading: boolean;
}

export const FilterPanel: FC<FilterPanelProps> = (props) => {
  const { value, onChange, isLoading } = props;

  return (
    <FilterForm<Filter>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="status" icon="filter" label="Status">
          <div className="w-48">
            <SelectInput name="status" options={statusOptions} />
          </div>
        </FilterField>

        <FilterField htmlFor="searchTerm" icon="search" label="Search">
          <TextInput
            name="searchTerm"
            placeholder="Search..."
            icon="search"
          />
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button type="submit" color="blue" disabled={isLoading} isLoading={isLoading}>
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};
