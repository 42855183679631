import { FC, useCallback, useMemo, useState } from "react";
import { gql } from 'graphql.macro'
import { useQuery, useMutation } from '@apollo/client';
import { Avatar, Button, FAIcon, ImageObject, makeAppendItems, Spinner, Table, TableContainer, TD, TH, VerticalField } from "@preferral/ui";
import { mmDdYyyy } from 'lib/dateFormatters';
import { defaultFilter, FilterModel, FilterPanel, removeUnusedFilterKeys, removeVoidKeys } from "./FilterPanel";
import { NoResults } from "components/NoResults";
import { ScreenTitle } from "context/ScreenTitle";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { NewNPIAvatarModal } from "./NewNPIAvatarModal";

const PAGE_SIZE = 50;

const LIST_NPI_AVATARS = gql`
  query ListNPIAvatars($first: Int, $after: UUID4, $filter: ListAvatarsFilter) {
    npiAvatars(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        npi
        small {
          base64
          src
          srcSet
        }
        insertedAt
      }
    }
  }
`;

interface Data {
  npiAvatars: {
    cursor: number;
    endOfList: boolean;
    items: AvatarModel[];
  }
}

interface Variables {
  first?: number;
  after?: string;
  filter?: FilterModel;
}

interface AvatarModel {
  id: string;
  npi: string;
  small: ImageObject;
  insertedAt: string;
}

type ActiveModal = "CREATE_NPI_AVATAR";

interface NPIAvatarsScreenProps { };

export const NPIAvatarsScreen: FC<NPIAvatarsScreenProps> = props => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), [setActiveModal]);
  const openNewModal = useCallback(() => setActiveModal("CREATE_NPI_AVATAR"), [setActiveModal]);

  const [rawFilter, setFilter] = useState<FilterModel>(defaultFilter);

  const filter = useMemo(() => removeUnusedFilterKeys(removeVoidKeys(rawFilter)), [rawFilter]);
  const { data, loading, error, fetchMore, refetch } = useQuery<Data, Variables>(LIST_NPI_AVATARS, { variables: { first: PAGE_SIZE, filter } });

  return (
    <>
      <ScreenTitle title="NPI Avatars" />

      <NewNPIAvatarModal
        isOpen={activeModal === "CREATE_NPI_AVATAR"}
        onClose={closeModal}
        onSuccess={() => {
          refetch();
          closeModal();
        }}
      />

      <div className="_NPIAvatarsScreen container mx-auto">
        <div className="flex items-start justify-between py-4 px-2 lg:px-4">
          <FilterPanel value={filter} onChange={setFilter} isLoading={loading} />

          <VerticalField label={ZERO_WIDTH_SPACE}>
            <Button type="button" color="blue" onClick={openNewModal}>
              <span className="mr-2">
                <FAIcon icon="plus" />
              </span>
              Add NPI Avatar
            </Button>
          </VerticalField>
        </div>
        {
          loading ? (
            <div className="p-8 text-center">
              <Spinner />
            </div>
          ) : error || !data?.npiAvatars ? (
            <p>Failed to load.</p>
          ) : data.npiAvatars.items.length === 0 ? (
            <NoResults icon="user-md" text="No Results" />
          ) : (
            <div className="px-2 lg:px-4 pb-8">
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>NPI</TH>
                      <TH>Created At</TH>
                      <TH>Actions</TH>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.npiAvatars.items.map((n) => (
                      <tr key={n.id}>
                        <TD>
                          <div className="flex items-center gap-3">
                            <Avatar size="sm" image={n.small} />
                            {n.npi}
                          </div>
                        </TD>
                        <TD>
                          {mmDdYyyy(n.insertedAt)}
                        </TD>
                        <TD className="w-40">Actions</TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data.npiAvatars.items.length > 0 ? (
                  <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                    {data.npiAvatars.endOfList ? (
                      <p>End of List</p>
                    ) : (
                      <Button
                        type="button"
                        size="sm"
                        kind="secondary"
                        color="blue"
                        className="w-full"
                        onClick={() => {
                          fetchMore({
                            query: LIST_NPI_AVATARS,
                            variables: {
                              first: PAGE_SIZE,
                              after: data.npiAvatars.cursor,
                              filter,
                            },
                            updateQuery,
                          });
                        }}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            </div>
          )
        }
      </div>
    </>
  );
};

const updateQuery = makeAppendItems<Data>("npiAvatars");
