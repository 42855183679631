import React, { FC, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import {
  defaultFilter,
  FilterPanel,
  Filter,
  AccountStatusModel,
} from "./FilterPanel";
import {
  Spinner,
  TableContainer,
  Table,
  TH,
  TD,
  FAIcon,
  Button,
  VerticalField,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { ZERO_WIDTH_SPACE } from "@preferral/common";

const now = new Date();

const PAGE_SIZE = 50;

const LIST_ACCOUNTS = gql`
  query ListAccounts($first: Int, $after: UUID4, $filter: ListAccountsFilter) {
    accounts(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        name
        status
        billingEmail
        trialFinishAt
        insertedAt
        accountTags {
          id
          color
          label
        }
        csm {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const SYNC_ORGS = gql`
  mutation SyncOrganizations {
    syncOrganizations
  }
`;

type Account = {
  id: string;
  name: string;
  status: string;
  billingEmail: string;
  trialFinishAt: string;
  accountTags: {
    id: string;
    color: string;
    label: string;
  }[];
  csm?: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

type Data = {
  accounts: {
    endOfList: boolean;
    cursor?: string;
    items: Account[];
  };
};

/**
 * Badge.
 */

interface BadgeProps {
  color: AppColor;
  className?: string;
}

const Badge: FC<BadgeProps> = props => {
  const { color, children } = props;

  return (
    <p
      className={`bg-${color}-100 border border-${color}-200 text-${color}-600 capitalize font-semibold inline-block leading-none ml-2 px-2 py-1 rounded-full text-xs`}
    >
      {children}
    </p>
  );
};

/**
 * AccountStatusBadge
 */
export interface AccountStatusBadgeProps {
  status: string;
}

export const AccountStatusBadge: FC<AccountStatusBadgeProps> = props => {
  const { status } = props;
  const colorForStatus: Record<AccountStatusModel, AppColor> = {
    trial: "blue",
    trial_ended: "orange",
    active: "green",
    grace_period: "orange",
    suspended: "red",
    deactivated: "gray",
  };
  return <Badge color={colorForStatus[status] || "blue"}>{status}</Badge>;
};

type AccountsScreenProps = {};

const AccountsScreen: FC<AccountsScreenProps> = () => {
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const { loading, data, error } = useQuery<Data>(LIST_ACCOUNTS, {
    variables: {
      first: PAGE_SIZE,
      filter,
    },
  });

  const [syncOrgs, { loading: syncLoading }] = useMutation(SYNC_ORGS);

  const onSyncOrgs = async () => {
    const { data } = await syncOrgs();
    toast.success(data.syncOrganizations);
  };

  return (
    <div className="AccountsScreen">
      <ScreenTitle title="Accounts" />
      <div className="container mx-auto">
        <div
          spec-id="accounts-table-control-panel"
          className="flex justify-between p-4"
        >
          <FilterPanel
            value={filter}
            onChange={setFilter}
            isLoading={loading}
          />
          <div>{/* Maybe add a "Create Account" button here? */}</div>
          <VerticalField label={ZERO_WIDTH_SPACE}>
            <Button
              onClick={() => {
                onSyncOrgs();
              }}
              type="button"
              color="blue"
              disabled={syncLoading}
              isLoading={syncLoading}
            >
              {syncLoading ? "Syncing..." : "Sync Organizations"}
            </Button>
          </VerticalField>
        </div>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data || (data && !data.accounts) ? (
          <p>Failed to load</p>
        ) : (
          <div className="pb-8 lg:px-4">
            <TableContainer>
              <Table>
                <thead>
                  <tr className="text-sm">
                    <TH className="px-3 py-2">Account Name</TH>
                    <TH>Tags</TH>
                    <TH>Plan</TH>
                    <TH>CSM</TH>
                    {/* <TH>Trial Ends At</TH> */}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data.accounts.items.map(account => (
                    <tr key={account.id}>
                      <TD>
                        <div className="flex items-center">
                          <Link
                            to={`/admin/accounts/${account.id}`}
                            className="font-bold text-blue-700"
                          >
                            {account.name}
                          </Link>
                          <AccountStatusBadge status={account.status} />
                        </div>
                        <p className="leading-tight pt-1 text-gray-500 text-xs">
                          <span className="mr-2 text-gray-400">
                            <FAIcon icon="envelope" />
                          </span>
                          {account.billingEmail}
                        </p>
                      </TD>
                      <TD>
                        <ul>
                          {account.accountTags.map(tag => (
                            <li
                              id={tag.id}
                              style={{ backgroundColor: tag.color }}
                            >
                              {tag.label}
                            </li>
                          ))}
                        </ul>
                      </TD>
                      <TD>Pro</TD>
                      <TD>
                        {account.csm ? (
                          <div>
                            {account.csm.firstName} {account.csm.lastName}
                          </div>
                        ) : (
                          <p className="text-gray-400">Not assigned</p>
                        )}
                      </TD>
                      {/* <TD>
                        {format(parseISO(account.trialFinishAt), "yyyy-MM-dd")}
                        <p>
                          {formatDistance(parseISO(account.trialFinishAt), now)}
                        </p>
                      </TD> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {data.accounts.items.length > 0 && data.accounts.endOfList ? (
                <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                  <p>End of List</p>
                </div>
              ) : null}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export { AccountsScreen };
