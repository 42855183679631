import { FC, useState } from "react";
import toast from 'react-hot-toast';
import { Drawer, DrawerHeader } from 'components/Drawer';
import { PillTabs, PillTab, CopyButton } from '@preferral/ui';
import { ImportQuestionnaireJSONForm } from "./ImportQuestionnaireJSONForm"


interface ImportExportJSONDrawerProps {
  isOpen: boolean;
  onClose(): void;
  template?: any[];
  onImport(sections: any[]): void;
};

type ActiveTab = "EXPORT" | "IMPORT";

export const ImportExportJSONDrawer: FC<ImportExportJSONDrawerProps> = props => {
  const { isOpen, onClose, onImport, template } = props;
  const [activeTab, setActiveTab] = useState<ActiveTab>("EXPORT");

  return (
    <Drawer
      className="_ImportExportJSONDrawer"
      isOpen={isOpen}
      onRequestClose={onClose}
      width="md"
    >
      <DrawerHeader
        icon="copy"
        title="Import/Export Sections"
        onClose={onClose}
      />

      <div className="p-4">
        <div className="flex justify-center">
          <PillTabs activeTab={activeTab} onChange={setActiveTab as (value: ActiveTab) => void}>
            <PillTab tab="EXPORT">Export</PillTab>
            <PillTab tab="Import">Import</PillTab>
          </PillTabs>
        </div>

        {
          activeTab === "EXPORT" ? (
            <>
              <div className="flex flex-col py-4">
                <div className="text-center">
                  <CopyButton text={JSON.stringify(template, null, 2)} size="sm">
                    Click to Copy
                  </CopyButton>
                </div>
                <pre className="mt-4 shadow-inner flex-grow p-1 font-mono text-xs text-green-400 bg-gray-800 rounded-lg">
                  {JSON.stringify(template, null, 2)}
                </pre>
              </div>
            </>
          ) : (
            <ImportQuestionnaireJSONForm
              onSave={(template) => {
                onImport(template);
                toast.success("Imported!");
                onClose();
              }}
            />
          )
        }
      </div>
    </Drawer>
  );
};
