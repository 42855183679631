import { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { FilterPanel, Filter, defaultFilter } from "./FilterPanel";
import { Modal, ModalHeader } from "components/Modal";
import { NewNPPESOrganizationForm } from "./NewNPPESOrganizationForm";
import { VerticalField, Button, Spinner, FAIcon, TableContainer, Table, TH, TD } from "@preferral/ui";
import { ZERO_WIDTH_SPACE } from '@preferral/common'
import { ScreenTitle } from "context/ScreenTitle";

const PAGE_SIZE = 30;

const LIST_NPPES_ORGANIZATIONS = gql`
  query ListNPPESOrganizations(
    $first: Int
    $after: UUID4
    $filter: ListNppesOrganizationsFilter
  ) {
    nppesOrganizations(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        npi
        name
      }
    }
  }
`;

interface NPPESOrganization {
  id: string;
  npi?: string;
  name: string;
}

interface Data {
  nppesOrganizations: {
    endOfList: boolean;
    cursor?: string;
    items: NPPESOrganization[];
  };
}

type ModalModel = "NEW" | "EDIT";

interface NPPESOrganizationsScreenProps { }

export const NPPESOrganizationsScreen: FC<NPPESOrganizationsScreenProps> = () => {
  const [currentModal, setCurrentModal] = useState<ModalModel | null>(null);

  const openNewModal = useCallback(() => {
    setCurrentModal("NEW");
  }, [setCurrentModal]);

  const closeModal = useCallback(() => {
    setCurrentModal(null);
  }, [setCurrentModal]);

  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const { loading, data, error } = useQuery<Data>(LIST_NPPES_ORGANIZATIONS, {
    variables: {
      first: PAGE_SIZE,
      filter,
    },
  });

  return (
    <div className="NPPESOrganizationsScreen">
      <ScreenTitle title="NPPES Organizations" />
      <Modal isOpen={currentModal === "NEW"} onRequestClose={closeModal}>
        <ModalHeader
          icon="hospital-alt"
          title="New NPPES Organization"
          onClose={closeModal}
        />
        <div className="p-4">
          <NewNPPESOrganizationForm
            refetchQueries={[
              {
                query: LIST_NPPES_ORGANIZATIONS,
                variables: {
                  first: PAGE_SIZE,
                  filter,
                },
              },
            ]}
            onSuccess={closeModal}
          />
        </div>
      </Modal>
      <div className="container mx-auto">
        <div
          spec-id="nppes-organizations-control-panel"
          className="flex justify-between items-start py-4 px-2 lg:px-4"
        >
          <FilterPanel value={filter} onChange={setFilter} isLoading={loading} />

          <div spec-id="nppes-organizations-actions">
            <VerticalField label={ZERO_WIDTH_SPACE}>
              <Button type="button" color="blue" onClick={openNewModal}>
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                New Organization
              </Button>
            </VerticalField>
          </div>
        </div>

        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data || (data && !data.nppesOrganizations) ? (
          <p>Failed to load.</p>
        ) : (
          <div className="pb-8 lg:px-4">
            <TableContainer>
              <Table>
                <thead>
                  <tr className="text-sm">
                    <TH className="px-3 py-2">Name</TH>
                    <TH />
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data.nppesOrganizations.items.map((org) => (
                    <tr key={org.id}>
                      <TD className="px-3 py-2">
                        <Link
                          to={`/admin/nppes_organizations/${org.id}`}
                          className="font-bold text-blue-700"
                        >
                          {org.name}
                        </Link>
                      </TD>
                      <TD>Actions</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {data.nppesOrganizations.items.length > 0 &&
                data.nppesOrganizations.endOfList ? (
                <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                  <p>End of List</p>
                </div>
              ) : null}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};
