import React, { FC } from "react";
import { FormikProps, ArrayHelpers } from "formik";
import {
  QuestionnaireModel,
  SectionModel,
} from "components/Questionnaire";
import { NoResults } from "components/NoResults";
import { DetailList, DetailItem } from "components/DetailList";
import { EditRule } from "./EditRule";
import { newRule } from '../helpers';

interface RulesEditorProps extends ArrayHelpers {
  prefix: string;
  sectionIndex: number;
  questionIndex: number;
  form: FormikProps<QuestionnaireModel>;
};

export const RulesEditor: FC<RulesEditorProps> = props => {
  const {
    prefix,
    insert,
    remove,
    sectionIndex,
    questionIndex,
    form: { values }
  } = props;

  const sections = prefix ? values[prefix].sections : values.sections;
  const section: SectionModel = sections[sectionIndex];
  const question = section.questions[questionIndex];
  return (
    <div className="RulesEditor px-4 pb-8">
      <DetailList>
        <DetailItem label="Section:">{section.title}</DetailItem>
        <DetailItem label="Question:">{question.prompt}</DetailItem>
      </DetailList>
      <div className="mt-4">
        <button
          type="button"
          className="btn btn-sm btn-gray border"
          onClick={() => insert(0, newRule())}
        >
          New Rule
        </button>
      </div>
      {question.rules.length === 0 ? (
        <NoResults icon="code" text="No Rules" />
      ) : (
        question.rules.map((rule, idx) => (
          <div key={rule.id} className="mt-4">
            <EditRule
              prefix={prefix}
              name={`${prefix}.sections.${sectionIndex}.questions.${questionIndex}.rules.${idx}`}
              rule={rule}
              onDelete={() => remove(idx)}
            />
          </div>
        ))
      )}
    </div>
  );
};
