import React, { FC } from "react";
import ReactModal from "react-modal";
import cx from "classnames";

type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => any;
  className?: string;
  overlayClassName?: string;
  closeTimeoutMS?: number;
};

export const Modal: FC<ModalProps> = (props) => {
  const {
    isOpen,
    onRequestClose,
    className,
    overlayClassName,
    children,
    closeTimeoutMS = 150,
  } = props;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cx("Modal w-full max-w-lg px-2 focus:outline-none", className)}
      overlayClassName={cx("Overlay", overlayClassName)}
      closeTimeoutMS={closeTimeoutMS}
    >
      <div className="bg-white shadow-md rounded-lg">{children}</div>
    </ReactModal>
  );
};
