import React, { FC } from "react";
import { FieldArray } from "formik";
import { SelectInput, FAIcon } from "@preferral/ui";
import { RuleModel, ActionModel } from "components/Questionnaire";

interface ActionOption {
  value: ActionModel;
  label: string;
};

const actionOptions: ActionOption[] = [
  { value: "UNHIDE", label: "Make Question Visible" },
  { value: "HIDE", label: "Hide Question" },
  { value: "REQUIRE", label: "Make Question Required" },
  { value: "UNREQUIRE", label: "Make Question Optional" },
];

interface EditActionsProps {
  namePrefix: string;
  rule: RuleModel;
};

export const EditActions: FC<EditActionsProps> = props => {
  const {
    namePrefix,
    rule,
  } = props;

  return (
    <div className="EditActions">
      <FieldArray
        name={`${namePrefix}.thenActions`}
        render={(arrayHelpers) => {
          return (
            <div>
              {rule.thenActions.map((action, idx) => (
                <div key={idx} className="flex items-center mt-1">
                  {idx > 0 ? (
                    <span className="mr-2 font-semibold text-gray-700">
                      AND
                    </span>
                  ) : null}
                  <div className="w-64 text-sm">
                    <SelectInput
                      name={`${namePrefix}.thenActions.${idx}`}
                      options={actionOptions}
                    />
                  </div>
                  {idx > 0 && (
                    <button
                      type="button"
                      className="ml-2 btn btn-red-alt"
                      onClick={() => arrayHelpers.remove(idx)}
                    >
                      <FAIcon icon="trash" />
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="mt-2 btn btn-sm btn-purple-alt"
                onClick={() =>
                  arrayHelpers.insert(rule.thenActions.length, "show")
                }
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Action
              </button>
            </div>
          );
        }}
      />
    </div>
  );
};
