import { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, SelectField, Spinner, TextField, ToggleSwitchField } from '@preferral/ui';
import { SpecialtyModel } from '../SpecialtiesScreen';
import toast from "react-hot-toast";

const GET_SPECIALTY = gql`
  query GetSpecialty($id: UUID4!) {
    specialty(id: $id) {
      id
      name
      taxonomyCode
      ageClassification
      ribbonUuid
      curated
      defaultSearchMode
      parentSpecialty {
        id
        name
        taxonomyCode
      }
      isCustom
      organization {
        id
        name
      }
    }
  }
`;

interface Data {
  specialty: SpecialtyModel;
}

const UPDATE_SPECIALTY = gql`
  mutation UpdateSpecialtyReferralForm(
    $id: UUID4!
    $input: SpecialtyInput!
  ) {
    updateSpecialty(id: $id, input: $input) {
      errors {
        key
        message
      }
      specialty {
        id
      }
    }
  }
`;

interface MutationData {
  updateSpecialty: {
    errors?: InputError[];
    specialty?: {
      id: string;
    };
  }
}

interface MutationVariables {
  id: string;
  input: FormValues;
}

interface FormValues {
  defaultSearchMode: string;
  name: string;
  taxonomyCode: string;
  ageClassification: null | string;
  curated: boolean;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    name: Yup.string()
      .trim()
      .required("Required"),
    taxonomyCode: Yup.string()
      .trim()
      .required("Required"),
  })
  .required();

const searchModeOptions = [
  { value: "location", label: "Location" },
  { value: "provider", label: "Provider" }
];

const ageClassificationOptions = [
  { value: "pediatric", label: "Pediatric" },
  { value: "adult", label: "Adult" },
  { value: "any", label: "Any" }
];

/**
 * EditSpecialtyForm.
 */

interface EditSpecialtyFormProps {
  specialtyId: string;
  onSuccess(): void;
};

export const EditSpecialtyForm: FC<EditSpecialtyFormProps> = props => {
  const { specialtyId, onSuccess } = props;

  const { data, loading, error } = useQuery<Data>(GET_SPECIALTY, { variables: { id: specialtyId } });

  const [updateSpecialty] = useMutation<MutationData, MutationVariables>(
    UPDATE_SPECIALTY
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      try {
        const { data } = await updateSpecialty({ variables: { id: specialtyId, input: values } });
        if (data?.updateSpecialty.errors) {
          setStatus({ errors: data.updateSpecialty.errors });
        } else if (data?.updateSpecialty.specialty) {
          // it worked...
          toast.success("Success");
          return onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong.");
      }
      setSubmitting(false);
    },
    [specialtyId, updateSpecialty, onSuccess]
  );

  return (
    <div className="_EditSpecialtyForm">
      {
        loading ? (
          <div className="p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data?.specialty ? (
          <p>Failed to load.</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              defaultSearchMode: data.specialty.defaultSearchMode,
              name: data.specialty.name,
              taxonomyCode: data.specialty.taxonomyCode,
              ageClassification: data.specialty.ageClassification,
              curated: data.specialty.curated
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />

                <TextField name="name" label="Name" autoFocus />

                <div className="mt-3">
                  <TextField name="taxonomyCode" label="Taxonomy Code" />
                </div>

                <div className="mt-3">
                  <SelectField
                    name="defaultSearchMode"
                    label="Default Search Mode"
                    options={searchModeOptions} />
                </div>

                <div className="mt-3">
                  <SelectField
                    name="ageClassification"
                    label="Age Classification"
                    indicateOptional
                    options={ageClassificationOptions} />
                </div>

                <div className="mt-3">
                  <ToggleSwitchField
                    name="curated"
                    label="Referrable?"
                    toggleSwitchProps={{ showLabel: true }}
                  />
                </div>

                <div className="flex items-center justify-end mt-3 py-4">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    kind="primary"
                    color="blue"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )
      }
    </div>
  );
};
