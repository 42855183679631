import React from "react";
import ReactModal from "react-modal";
import cx from "classnames";

type DrawerWidth =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "full";

type DrawerProps = {
  isOpen: boolean;
  onRequestClose: () => any;
  width: DrawerWidth;
  className?: string;
  overlayClassName?: string;
  closeTimeoutMS?: number;
};

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onRequestClose,
  width = "sm",
  overlayClassName,
  className,
  children
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={{
        base: cx(
          className,
          "z-10 fixed inset-y-0 right-0 m-0 w-full shadow-lg transition-transform overflow-y-auto translate-x-full",
          {
            "bg-white": !(className && className.includes("bg-")),
            "max-w-xs": width === "xs",
            "max-w-sm": width === "sm",
            "max-w-md": width === "md",
            "max-w-lg": width === "lg",
            "max-w-xl": width === "xl",
            "max-w-2xl": width === "2xl",
            "max-w-3xl": width === "3xl",
            "max-w-4xl": width === "4xl",
            "max-w-5xl": width === "5xl",
            "max-w-6xl": width === "6xl",
            "max-w-full": width === "full"
          }
        ),
        afterOpen: cx({
          "ease-out transition-slow translate-x-0": isOpen
        }),
        beforeClose: "ease-in transition-slow translate-x-full"
      }}
      overlayClassName={cx("Overlay", overlayClassName)}
      closeTimeoutMS={300}
    >
      <div className="relative z-10">
        <div>{children}</div>
      </div>
    </ReactModal>
  );
};

export { Drawer };
