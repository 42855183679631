import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner, Avatar, ImageObject } from "@preferral/ui";

const NPPES_PROVIDER = gql`
  query nppesProvider($id: UUID4!) {
    nppesProvider(id: $id) {
      id
      npi
      nameWithAppellation
      avatar(size: "small") {
        base64
        src
        srcSet
      }
      specialties {
        id
        name
      }
    }
  }
`;

interface Data {
  nppesProvider: {
    id: string;
    npi: string;
    nameWithAppellation: string;
    avatar: ImageObject;
    specialties: {
      id: string;
      name: string;
    }[];
  };
}

interface Variables {
  id: string;
}

interface NPPESProviderCardProps {
  nppesProviderId: string;
}

export const NPPESProviderCard: FC<NPPESProviderCardProps> = (props) => {
  const { nppesProviderId } = props;
  const { data, loading, error } = useQuery<Data, Variables>(NPPES_PROVIDER, {
    variables: {
      id: nppesProviderId,
    },
  });

  return (
    <div className="NPPESProviderCard rounded-2xl flex items-center p-4 shadow-lg">
      {loading ? (
        <div className="p-4">
          <Spinner />
        </div>
      ) : error || !(data && data.nppesProvider) ? (
        <div className="p-8">
          <p>Failed to load.</p>
        </div>
      ) : (
        <>
          <Avatar
            size="sm"
            alt={data.nppesProvider.nameWithAppellation}
            image={data.nppesProvider.avatar}
          />
          <div className="ml-5">
            <p className="font-black text-gray-800 text-lg">
              {data.nppesProvider.nameWithAppellation}
            </p>
            <p className="leading-none text-gray-600 text-xs italic">
              NPI: {data.nppesProvider.npi}
            </p>
            <p>
              {data.nppesProvider.specialties.map((s) => s.name).join(", ")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
