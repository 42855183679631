import React, { FC } from 'react'
import { HorizontalNumberField, required, Validator } from '@preferral/ui'
import { NumberQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface NumberQuestionFieldProps {
  question: NumberQuestion
}

export const NumberQuestionField: FC<NumberQuestionFieldProps> = (props) => {
  const { question } = props

  const { name, isRequired, isVisible } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_NumberQuestionField">
      <HorizontalNumberField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
      />
    </div>
  )
}
