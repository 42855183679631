import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Redirect, useRouteMatch, Switch, Route } from "react-router-dom";
import { Spinner, CircleIcon } from "@preferral/ui";
import { NavTabs, NavTabLink } from "components/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { OrganizationOverview } from "./OrganizationOverview/OrganizationOverview";
import { OrganizationUsers } from "./OrganizationUsers/OrganizationUsers";
import { OrganizationLocations } from "./OrganizationLocations/OrganizationLocations";
import { OrganizationProviders } from "./OrganizationProviders/OrganizationProviders";
import { OrganizationForms } from "./OrganizationForms/OrganizationForms";
import { OrganizationAMDUsers } from "./OrganizationAMDUsers";
import { OrganizationFeatures } from './OrganizationFeatures';

const ORGANIZATION = gql`
  query GetOrganization($id: UUID4!) {
    organization(id: $id) {
      id
      name
      aristamdOrganizationId
      insertedAt
      updatedAt
    }
  }
`;

interface Data {
  organization: OrganizationModel;
}

interface Variables {
  id: string;
}

interface OrganizationModel {
  id: string;
  name: string;
  aristamdOrganizationId: number | null;
  insertedAt: string;
  updatedAt: string;
}

interface RouteParams {
  organizationId: string;
}

interface OrganizationShowScreenProps { }

export const OrganizationShowScreen: FC<OrganizationShowScreenProps> = (
  props
) => {
  const match = useRouteMatch<RouteParams>();
  const { organizationId } = match.params;
  const { data, loading, error } = useQuery<Data, Variables>(ORGANIZATION, {
    variables: { id: organizationId },
  });
  return (
    <div className="_OrganizationShowScreen p-4">
      <ScreenTitle title={["Organizations", "View Organization"]} />

      <div className="container mx-auto p-3 rounded-2xl shadow-xl text-left">
        {loading ? (
          <Spinner />
        ) : error || !data?.organization ? (
          <p>Failed to load.</p>
        ) : (
          <>
            <div>
              <div className="flex items-center">
                <div className="ml-2">
                  <CircleIcon icon="hospital-alt" color="blue" />
                </div>
                <h2 className="ml-4 font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                  {data.organization.name}
                </h2>
              </div>
            </div>
            <div className="mt-4">
              <NavTabs>
                <NavTabLink to={`${match.url}/overview`}>Overview</NavTabLink>
                <NavTabLink to={`${match.url}/users`}>Users</NavTabLink>
                {
                  data.organization.aristamdOrganizationId ? (
                    <NavTabLink to={`${match.url}/amd_users`}>AristaMD Users</NavTabLink>
                  ) : null
                }
                <NavTabLink to={`${match.url}/locations`}>Locations</NavTabLink>
                <NavTabLink to={`${match.url}/providers`}>Providers</NavTabLink>
                <NavTabLink to={`${match.url}/forms`}>Forms</NavTabLink>
                <NavTabLink to={`${match.url}/features`}>Features</NavTabLink>
              </NavTabs>
            </div>
          </>
        )}
      </div>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/overview`} />
        </Route>
        <Route path={`${match.path}/overview`}>
          <OrganizationOverview organizationId={organizationId} />
        </Route>
        <Route path={`${match.path}/users`}>
          <OrganizationUsers organizationId={organizationId} />
        </Route>
        <Route path={`${match.path}/amd_users`}>
          <OrganizationAMDUsers organizationId={organizationId} />
        </Route>
        <Route path={`${match.path}/locations`}>
          <OrganizationLocations organizationId={organizationId} />
        </Route>
        <Route path={`${match.path}/providers`}>
          <OrganizationProviders organizationId={organizationId} />
        </Route>
        <Route path={`${match.path}/forms`}>
          <OrganizationForms organizationId={organizationId} />
        </Route>
        <Route path={`${match.path}/features`}>
          <OrganizationFeatures organizationId={organizationId} />
        </Route>
      </Switch>
    </div>
  );
};
