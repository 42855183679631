import React, { FC } from "react";

interface NPPESOrganizationOverviewProps {
  nppesOrganizationId: string;
}

export const NPPESOrganizationOverview: FC<NPPESOrganizationOverviewProps> = props => {
  const { nppesOrganizationId } = props;
  return (
    <div className="NPPESOrganizationOverview bg-gray-100 mt-4 p-4 rounded-2xl text-left">
      <p>NPPESOrganizationOverview</p>
      <p>{nppesOrganizationId}</p>
    </div>
  );
};
