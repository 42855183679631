import React, { useEffect } from "react";
import AsyncSelect, { Props } from "react-select/async";
// import { OptionProps } from "react-select/src/components/Option";
import { OptionProps } from "react-select";
import { components } from "react-select";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { AvatarThumbnail } from "components/Avatar";

const PROVIDER_OPTIONS = gql`
  query ListProviders($filter: ListProvidersFilter) {
    providers(filter: $filter) {
      items {
        id
        nameWithAppellation
        primarySpecialty {
          id
          name
        }
        avatar(size: "thumbnail") {
          base64
          src
          srcSet
        }
      }
    }
  }
`;

interface Data {
  providers: {
    items: {
      id: string;
      primarySpecialty?: {
        id: string;
        name: string;
      };
      nameWithAppellation: string;
    }[];
  };
}

interface Variables {
  filter?: {
    searchTerm?: string;
  };
}

const PROVIDER = gql`
  query GetProvider($id: UUID4!) {
    provider(id: $id) {
      id
      nameWithAppellation
      primarySpecialty {
        id
        name
      }
      avatar(size: "thumbnail") {
        base64
        src
        srcSet
      }
    }
  }
`;

export type ProviderOptionType = {
  id: string;
  primarySpecialty?: {
    id: string;
    name: string;
  };
  nameWithAppellation: string;
};

const ProviderOption: React.FC<OptionProps<ProviderOptionType, false>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center">
        <AvatarThumbnail image={props.data.avatar} />
        <div className="ml-3">
          <p className="leading-tight text-gray-900 font-semibold">
            {props.data.nameWithAppellation}
          </p>
          {props.data.primarySpecialty ? (
            <p className="mt-1 leading-none text-gray-700 text-xs">
              {props.data.primarySpecialty.name}
            </p>
          ) : null}
        </div>
      </div>
    </components.Option>
  );
};

type ProviderSelectProps = {
  value?: string | null;
} & Pick<Props<ProviderOptionType, false>, "id" | "onChange" | "onBlur">;

const ProviderSelect: React.FC<ProviderSelectProps> = ({ value, ...props }) => {
  // NB: Kind of a hack, but I want to use the "initial" data load
  //     value "persistently" as the AsyncSelect defaultOptions value.
  //     And I want to call `refetch` without it setting the original
  //    `query's `loading` to true. So I'm just making 2 apollo queries.
  const { data, loading, error } = useQuery<Data, Variables>(PROVIDER_OPTIONS);
  const { refetch } = useQuery<Data, Variables>(PROVIDER_OPTIONS);
  const [loadValueProvider, { data: valueData }] = useLazyQuery(PROVIDER);

  // This ensures we load the data for the passed in `value` provider id.
  useEffect(() => {
    if (value) {
      loadValueProvider({ variables: { id: value } });
    }
  }, [loadValueProvider, value]);

  // This is passed to AsyncSelect to load options based on
  // the typed inputValue, which we'll use as `searchTerm` in
  // our query filter.
  const asyncLoad = (inputValue: string) => {
    return refetch({ filter: { searchTerm: inputValue } }).then((res) => {
      return res.data.providers.items;
    });
  };

  // This is the ProviderOption data for the selected
  // value id. We're guarding for the case where we have
  // the selected id but haven't quite loaded the data
  // for it.
  const valueProvider: ProviderOptionType | null = value
    ? !(valueData && valueData.provider)
      ? { id: value, nameWithAppellation: "Loading..." }
      : valueData.provider
    : null;

  return loading ? (
    <p>Loading...</p>
  ) : error || !(data && data.providers.items) ? (
    <p>Error</p>
  ) : (
    <AsyncSelect<ProviderOptionType>
      {...props}
      value={valueProvider}
      defaultOptions={data.providers.items}
      loadOptions={asyncLoad}
      getOptionLabel={(p: ProviderOptionType) => p.nameWithAppellation}
      getOptionValue={(p: ProviderOptionType) => p.id}
      components={{ Option: ProviderOption }}
    />
  );
};

export { ProviderSelect };
