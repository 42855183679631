import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner, CircleIcon } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import { NavTabs, NavTabLink } from "components/NavTabs";
import { NetworkOverview } from "./NetworkOverview";
import { NetworkPractices } from "./NetworkPractices";
import { NetworkProviders } from "./NetworkProviders";
import { NetworkRules } from "./NetworkRules";
import { NetworkSettings } from "./NetworkSettings";
import { NetworkSubgroups } from "./NetworkSubgroups";

const QUERY = gql`
  query FetchNetwork($id: UUID4!) {
    network(id: $id) {
      id
      name
      shortName
      subdomain
    }
  }
`;

interface Data {
  network: {
    id: string;
    name: string;
    shortName?: string;
  };
}

interface Variables {
  id: string;
}

interface RouteParams {
  networkId: string;
}

interface NetworkShowScreenProps {}

export const NetworkShowScreen: FC<NetworkShowScreenProps> = (props) => {
  const match = useRouteMatch<RouteParams>();
  const { networkId } = match.params;

  const { data, loading, error } = useQuery<Data, Variables>(QUERY, {
    variables: { id: networkId },
  });

  return (
    <div className="_NetworkShowScreen">
      <ScreenTitle title={["Networks", "View Network"]} />
      <div className="container mx-auto p-4">
        <div className="_Header p-3 rounded-2xl shadow-xl text-left">
          {loading ? (
            <div className="p-12 text-center">
              <Spinner />
            </div>
          ) : error || !data?.network ? (
            <>
              <p>Failed to load</p>
              {/* <pre>{JSON.stringify(error, null, 2)</pre> */}
            </>
          ) : (
            <>
              <div>
                <div className="flex items-center">
                  <div className="ml-2">
                    <CircleIcon icon="project-diagram" color="purple" />
                  </div>
                  <h2 className="ml-4 font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                    {data.network.name}
                  </h2>
                </div>
              </div>
              <div className="mt-4">
                <NavTabs>
                  <NavTabLink to={`${match.url}/overview`}>Overview</NavTabLink>
                  <NavTabLink to={`${match.url}/subnetworks`}>
                    Subnetworks
                  </NavTabLink>
                  {/* <NavTabLink to={`${match.url}/users`}>Users</NavTabLink> */}
                  <NavTabLink to={`${match.url}/practices`}>
                    Practices
                  </NavTabLink>
                  <NavTabLink to={`${match.url}/providers`}>
                    Providers
                  </NavTabLink>
                  <NavTabLink to={`${match.url}/rules`}>Rules</NavTabLink>
                  <NavTabLink to={`${match.url}/settings`}>Settings</NavTabLink>
                </NavTabs>
              </div>
            </>
          )}
        </div>
      </div>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/overview`} />
        </Route>
        <Route path={`${match.path}/overview`}>
          <NetworkOverview networkId={networkId} />
        </Route>
        <Route exact path={`${match.path}/subnetworks`}>
          <NetworkSubgroups networkId={networkId} />
        </Route>
        {/* <Route path={`${match.path}/users`}>
          <NetworkUsers networkId={networkId} />
        </Route> */}
        <Route exact path={`${match.path}/practices`}>
          <NetworkPractices networkId={networkId} />
        </Route>
        <Route path={`${match.path}/providers`}>
          <NetworkProviders networkId={networkId} />
        </Route>
        <Route path={`${match.path}/rules`}>
          <NetworkRules networkId={networkId} />
        </Route>
        <Route path={`${match.path}/settings`}>
          <NetworkSettings networkId={networkId} />
        </Route>
      </Switch>
    </div>
  );
};
