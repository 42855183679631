import React, { FC, InputHTMLAttributes, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ProviderSearchBox, ProviderSearchFilter } from "./ProviderSearchBox";
import { SelectedProvider } from "./SelectedProvider";
import { ProviderReferenceModel } from "./types";

export interface ProviderSearchSelectProps {
  value: ProviderReferenceModel | null | undefined;
  onChange(selectedProvider: ProviderReferenceModel | null): void;
  onBlur?(e: any): void;
  icon?: IconProp;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  filter?: ProviderSearchFilter;
}

export const ProviderSearchSelect: FC<ProviderSearchSelectProps> = (props) => {
  const { value, onChange, onBlur, icon, inputProps = {}, filter = {} } = props;

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      {!!value ? (
        <div className="py-2">
          <SelectedProvider provider={value} onClear={() => onChange(null)} />
        </div>
      ) : (
        <ProviderSearchBox
          icon={icon}
          value={searchTerm}
          onChange={setSearchTerm}
          onBlur={onBlur}
          onProviderClick={onChange}
          inputProps={inputProps}
          filter={filter}
        />
      )}
    </>
  );
};
