import { FC, useEffect } from 'react';
import qs from 'query-string';
import { Redirect, useLocation } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useAuth } from 'context/AuthContext';
import { CircleIcon } from '@preferral/ui';

/**
 * This is used after an admin has requested a login token
 * by trading their SSO resource token for their own admin resource.
 * This page will grabe the resource token from the URL query param,
 * where it was put by a successful SAML SSO redirect, and this
 * page will POST it to the server, in order to trade it for a
 * JWT that will serve for the admin's session.
 *
 * After retrieving the JWT, we'll redirect the browser to an SPA
 * page such as the admin dashboard.
 */

const ISSUE_JWT_FOR_RESOURCE_TOKEN = gql`
  mutation IssueJwtForResourceToken($token: String!) {
    issueJwtForResourceToken(token: $token) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  issueJwtForResourceToken: {
    errors?: InputError[];
    session?: {
      token: string;
    }
  }
}

export const SSORedirectScreen: FC = () => {
  const location = useLocation();
  const params = qs.parse(location.search);
  const { token, return_to: returnToPath = "/admin/accounts" } = params;

  const { login: signIn } = useAuth();

  const [fetchJwtForToken, { data, loading, error }] = useMutation<MutationData>(ISSUE_JWT_FOR_RESOURCE_TOKEN);

  useEffect(() => {
    fetchJwtForToken({
      variables: {
        token
      }
    }).then(
      resp => {
        if (resp.data?.issueJwtForResourceToken.session) {
          const {
            token: jwtToken
          } = resp.data.issueJwtForResourceToken.session;
          signIn(jwtToken);
        }
      },
      err => {
        console.error(err);
      }
    )
  }, []);

  return (
    <div className="_ImpersonationAuthScreen min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 text-left">
      <div className="p-4 flex justify-center">
        <CircleIcon icon="lock" color="purple" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-6xl opacity-25">
        <div>
          <p className="font-semibold mt-3 mb-1">Return Path:</p>
          <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
            {returnToPath}
          </pre>
        </div>
        <div>
          <p className="font-semibold mt-3 mb-1">location.search:</p>
          <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
            {location.search}
          </pre>
        </div>
        <div>
          <p className="font-semibold mt-3 mb-1">params:</p>
          <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
            {JSON.stringify(params, null, 2)}
          </pre>
        </div>
        <div>
          {loading || (!data && !error) ? (
            <p>Loading...</p>
          ) : error ? (
            <div>
              <p className="font-semibold mt-3 mb-1">Error:</p>
              <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
                {JSON.stringify(error, null, 2)}
              </pre>
            </div>
          ) : data && data.issueJwtForResourceToken ? (
            <div>
              <p className="font-semibold mt-3 mb-1">Success:</p>
              <Redirect to={returnToPath as string} push />
              <pre className="text-xs bg-gray-200 overflow-x-scroll p-2 rounded">
                {JSON.stringify(data.issueJwtForResourceToken, null, 2)}
              </pre>
            </div>
          ) : (
            <p>
              {/* This should never happen. "Error Code 91" is arbitrary, but grep-able */}
              Something went wrong. Error Code 91.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
