import React, { FC } from "react";

interface OrganizationLocationsProps {
  organizationId: string;
}

export const OrganizationLocations: FC<OrganizationLocationsProps> = (
  props
) => {
  const { organizationId } = props;
  return (
    <div className="OrganizationLocations">
      <p>OrganizationLocations</p>
      <p>{organizationId}</p>
    </div>
  );
};
