import { FC, useState, useCallback } from "react";
import { FieldArray, FormikProps, ArrayHelpers, useFormikContext } from "formik";
import { FAIcon, Button } from "@preferral/ui";
import { QuestionnaireModel, SectionModel } from "components/Questionnaire";
import { Drawer, DrawerHeader } from "components/Drawer";
import { RulesEditor } from "./RuleEditor";
import { EditSection } from "./EditSection";
import { newSection } from "./helpers";
import { ImportExportJSONDrawer } from "./ImportExportJSONDrawer";

/**
 * Sections.
 */

interface SectionsProps extends ArrayHelpers {
  prefix: string;
  openEditingQuestionRule: (sectionIdx: number, questionIdx: number) => void;
  form: FormikProps<QuestionnaireModel>;
}

const Sections: FC<SectionsProps> = props => {
  const { prefix, openEditingQuestionRule, insert, remove, form } = props;

  // const onDragEnd = useCallback(
  //   (result: DropResult) => {
  //     if (result.destination) {
  //       move(result.source.index, result.destination.index);
  //     }
  //   },
  //   [move]
  // );

  const sections = prefix ? form.values[prefix].sections : form.values.sections;

  return (
    <div>
      {sections.length === 0 ? (
        <div>
          <p className="px-4 py-6 text-gray-700">
            This form is currently empty. Click below to add a section.
          </p>
          <button
            type="button"
            className="border border-gray-400 hover:bg-gray-200 leading-none p-1 px-4 py-2 rounded-full text-blue-600 text-sm focus:outline-none focus:shadow-outline"
            onClick={() =>
              insert(0, newSection({ title: "New Section" }))
            }
          >
            <span className="inline-block mr-2 text-center w-4">
              <FAIcon icon="plus" />
            </span>
            Add Section
          </button>
        </div>
      ) : null}
      {sections.map((section: SectionModel, index: number) => (
        <div key={section.id} className="relative mb-12 flex bg-white border border-gray-300 rounded-lg">
          <div className="py-4 px-2">
            {/* <div {...provided.dragHandleProps}>
                        <Icon icon="reorder" />
                      </div> */}
          </div>
          <div className="flex-1 relative">
            <EditSection
              prefix={prefix}
              index={index}
              section={section}
              openEditingQuestionRule={openEditingQuestionRule}
              onDeleteSection={() => remove(index)}
              onAddSection={() =>
                insert(
                  index + 1,
                  newSection({ title: "Index" + (index + 1) })
                )
              }
            />
            <div
              className="absolute mt-2">
              <div className="relative">
                <div className="-mt-2 absolute bg-gray-300 border-gray-300 border-l h-2 ml-6 top-0" />
                <button
                  type="button"
                  className="border border-gray-400 hover:bg-gray-200 leading-none p-1 px-4 py-2 rounded-full text-blue-600 text-sm focus:outline-none focus:shadow-outline"
                  onClick={() => insert(index + 1, newSection())}
                >
                  <span className="inline-block mr-2 text-center w-4">
                    <FAIcon icon="plus" />
                  </span>
                  Add Section
                </button>
                {index < sections.length - 1 && (
                  <div
                    className="absolute bg-gray-300 border-gray-300 border-l ml-6"
                    style={{ height: 9 }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

/**
 * QuestionnaireTemplateEditor.
 */

interface QuestionnaireTemplateEditorProps {
  name: string;
};

type ActiveModal = { type: "IMPORT_EXPORT" } | { type: "EDIT_QUESTION_RULE", sectionIdx: number, questionIdx: number };

export const QuestionnaireTemplateEditor: FC<QuestionnaireTemplateEditorProps> = props => {
  const { name } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const prefix = name;

  const { values, setFieldValue } = useFormikContext();
  const template = (values as any)[name];

  const onImport = useCallback((template: any) => {
    setFieldValue(name, template)
  }, [name, setFieldValue]);

  return (
    <>
      <ImportExportJSONDrawer
        isOpen={activeModal?.type === "IMPORT_EXPORT"}
        onClose={closeModal}
        onImport={onImport}
        template={template}
      />
      <Drawer
        isOpen={activeModal?.type === "EDIT_QUESTION_RULE"}
        onRequestClose={closeModal}
        width="5xl"
        className="bg-gray-100"
      >
        <DrawerHeader
          icon={["far", "address-card"]}
          title="Question Rules"
          onClose={closeModal}
        />
        {activeModal?.type === "EDIT_QUESTION_RULE" && (
          <FieldArray
            name={`${prefix}.sections.${activeModal.sectionIdx}.questions.${activeModal.questionIdx}.rules`}
            render={(arrayHelpers) => {
              return (
                <RulesEditor
                  prefix={prefix}
                  sectionIndex={activeModal.sectionIdx}
                  questionIndex={activeModal.questionIdx}
                  {...arrayHelpers}
                />
              );
            }}
          />
        )}
      </Drawer>
      <div className="py-2 flex justify-between">
        <div />
        <Button size="xs" type="button" color="teal" onClick={() => setActiveModal({ type: "IMPORT_EXPORT" })}>
          Import/Export Template
        </Button>
      </div>
      <FieldArray
        name={`${prefix}.sections`}
        render={(arrayHelpers) => (
          <Sections
            prefix={prefix}
            openEditingQuestionRule={(sectionIdx, questionIdx) => setActiveModal({ type: "EDIT_QUESTION_RULE", sectionIdx, questionIdx })}
            {...arrayHelpers}
          />
        )}
      />
    </>
  );
};
