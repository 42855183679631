import React, { FC } from "react";

interface NetworkOverviewProps {
  networkId: string;
}

export const NetworkOverview: FC<NetworkOverviewProps> = (props) => {
  return (
    <div className="NetworkOverview">
      <p>NetworkOverview</p>
    </div>
  );
};
