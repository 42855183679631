import React, { FC } from "react";

interface NPPESOrganizationFormsProps {
  nppesOrganizationId: string;
}

export const NPPESOrganizationForms: FC<NPPESOrganizationFormsProps> = props => {
  const { nppesOrganizationId } = props;
  return (
    <div className="NPPESOrganizationForms bg-gray-100 mt-4 p-4 rounded-2xl text-left">
      <p>NPPESOrganizationForms</p>
      <p>{nppesOrganizationId}</p>
    </div>
  );
};
