import React, { FC } from 'react'
import { HorizontalTextField, required, Validator } from '@preferral/ui'
import { TextQuestion } from '../types'
import { useQuestion } from './QuestionnaireFormContext'

interface ShortTextQuestionFieldProps {
  question: TextQuestion
}

export const ShortTextQuestionField: FC<ShortTextQuestionFieldProps> = (
  props,
) => {
  const { question } = props

  const { name, isVisible, isRequired } = useQuestion(question.id)

  if (!isVisible) {
    return null
  }

  const validate = [isRequired ? required : null].filter(Boolean) as Validator[]

  return (
    <div className="_ShortTextQuestionField">
      <HorizontalTextField
        indicateRequired={isRequired}
        label={question.prompt}
        name={name}
        validate={validate}
      />
    </div>
  )
}
