import React, { FC } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, SelectField, PhoneMaskField, Spinner } from "@preferral/ui";
import { CountySelectField } from "components/formik/CountySelectField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { NPPESLocation } from "./model";

const UPDATE_NPPES_LOCATION = gql`
  mutation UpdateNPPESLocation($id: UUID4!, $input: NppesLocationInput!) {
    updateNppesLocation(id: $id, input: $input) {
      errors {
        key
        message
      }
      nppesLocation {
        id
        name
      }
    }
  }
`;

interface MutationData {
  updateNppesLocation: {
    errors?: InputError[];
    nppesLocation?: {
      id: string;
      name: string;
    };
  };
}

interface MutationVariables {
  id: string;
  input: NPPESLocationInput;
}

interface NPPESLocationInput {
  name: string;
  phone: string;
  phoneExtension?: string;
  fax?: string;
  faxExtension?: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
  fipsCode: string;
  timeZoneName: string;
  taxonomyIds: string[];
}

const LOCATION_STATES_TIME_ZONES_AND_SPECIALTIES = gql`
  query LocationStatesTimeZonesAndSpecialties($id: UUID4!) {
    nppesLocation(id: $id) {
      id
      name
      npi
      phone {
        raw
        formatted
      }
      hqPhone {
        raw
        formatted
      }
      phoneExtension
      fax {
        raw
        formatted
      }
      faxExtension
      streetAddress
      streetAddressLine2
      city
      stateAbbreviation
      state {
        id
        name
      }
      zip
      county {
        id
        name
        fipsCode
      }
      taxonomies {
        id,
        name,
        taxonomyCode
      }
      timeZoneName
      verifiedData
      insertedAt
      updatedAt
    }
    timeZoneNames
    states {
      id
      name
      abbreviation
    }
    specialties(filter: { curated: true }) {
      id
      name
    }
  }
`;

interface Data {
  nppesLocation: NPPESLocation;
  timeZoneNames: string[];
  states: {
    id: string;
    name: string;
    abbreviation: string;
  }[];
  specialties: {
    id: string;
    name: string;
  }[];
}

interface NewNPPESLocationFormProps {
  closeModal(): void;
  nppesLocationId: string;
  refetchQueries: any;
}

export const EditNPPESLocationForm: FC<NewNPPESLocationFormProps> = (props) => {
  const { closeModal, nppesLocationId, refetchQueries } = props;
  const { data, loading, error } = useQuery<Data>(LOCATION_STATES_TIME_ZONES_AND_SPECIALTIES, { variables: { id: nppesLocationId } });

  const specialtyOptions =
    data?.specialties.map((s) => ({
      value: s.id,
      label: s.name,
    })) || [];

  const [updateLocation] = useMutation<MutationData, MutationVariables>(
    UPDATE_NPPES_LOCATION
  );

  return (
    loading ? (
      <div className="p-8 text-center">
        <Spinner />
      </div>
    ) : error ? (
      <p>Failed to load.</p>
    ) : (
      <div className="NewNPPESLocationForm">
        <Formik<NPPESLocationInput>
          initialValues={{
            name: data?.nppesLocation.name || '',
            phone: data?.nppesLocation.phone?.formatted || '',
            phoneExtension: data?.nppesLocation.phoneExtension || '',
            fax: data?.nppesLocation.fax?.formatted || '',
            faxExtension: data?.nppesLocation.faxExtension || '',
            streetAddress: data?.nppesLocation.streetAddress || '',
            streetAddressLine2: data?.nppesLocation.streetAddressLine2 || '',
            city: data?.nppesLocation.city || '',
            stateAbbreviation: data?.nppesLocation.stateAbbreviation || '',
            zip: data?.nppesLocation.zip || '',
            fipsCode: data?.nppesLocation.county.fipsCode || '',
            timeZoneName: data?.nppesLocation.timeZoneName || '',
            taxonomyIds: data?.nppesLocation.taxonomies.map(t => t.id) || []
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required"),
            phone: Yup.string()
              .required("Required")
              .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Invalid phone number"),
            fax: Yup.string().matches(
              /^\(\d{3}\)\s\d{3}-\d{4}$/,
              "Invalid fax number"
            ),
            streetAddress: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            stateAbbreviation: Yup.string().required("Required"),
            zip: Yup.string().required("Required"),
            fipsCode: Yup.string().required("Required"),
            timeZoneName: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            updateLocation({
              variables: { id: nppesLocationId, input: values },
              refetchQueries,
            }).then((res) => {
              if (res?.data?.updateNppesLocation.errors) {
                setStatus({
                  errors: res.data.updateNppesLocation.errors,
                });
              } else if (res?.data?.updateNppesLocation?.nppesLocation) {
                // it worked
                // toast.success("Created Location")
                closeModal();
              }
              setSubmitting(false);
            });
          }}
        >
          {({ values, status, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />

              <div className="mt-3">
                <TextField name="name" label="Name" autoFocus />
              </div>

              <div className="mt-3 flex flex-wrap -mx-2">
                <div className="w-full md:w-2/3 px-2">
                  <PhoneMaskField name="phone" label="Phone" icon="phone" />
                </div>
                <div className="w-full md:w-1/3 px-2">
                  <TextField name="phoneExtension" label="Phone Ext." />
                </div>
              </div>

              <div className="mt-3 flex flex-wrap -mx-2">
                <div className="w-full md:w-2/3 px-2">
                  <PhoneMaskField name="fax" label="Fax" icon="fax" />
                </div>
                <div className="w-full md:w-1/3 px-2">
                  <TextField name="faxExtension" label="Fax Ext." />
                </div>
              </div>

              <div className="mt-3">
                <TextField name="streetAddress" label="Address" />
              </div>

              <div className="mt-3">
                <TextField name="streetAddressLine2" label="Address Line 2" />
              </div>

              <div className="mt-3">
                <TextField name="city" label="City" />
              </div>

              <div className="mt-3">
                <SelectField
                  name="stateAbbreviation"
                  label="State"
                  options={
                    data?.states.map((s) => ({
                      value: s.abbreviation,
                      label: s.name,
                    })) || []
                  }
                  isLoading={loading}

                />
              </div>

              <div className="mt-3">
                <TextField name="zip" label="Zip Code" />
              </div>

              <div className="mt-3">
                <CountySelectField
                  name="fipsCode"
                  label="County"
                  zip={values.zip}
                />
              </div>

              <div className="mt-3">
                <SelectField
                  name="timeZoneName"
                  label="Time Zone"
                  isLoading={loading}
                  options={
                    data?.timeZoneNames.map((tz) => ({ value: tz, label: tz })) ||
                    []
                  }
                />
              </div>

              <div className="mt-3">
                <SelectField
                  name="taxonomyIds"
                  label="Specialties"
                  options={specialtyOptions}
                  isLoading={loading}
                  isMulti
                />
              </div>

              <div className="flex items-center justify-end mt-3 py-4">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-blue"
                >
                  Update Location
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    )
  );
};
