import { FC, useCallback, useState } from "react";
import qs from "query-string";
import { gql, useQuery, useMutation } from "@apollo/client";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Button, Spinner } from "@preferral/ui";
import { Modal, ModalHeader } from "components/Modal";

const GET_USER = gql`
  query GetUser($userId: UUID4!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      organization {
        id
        name
      }
    }
  }
`;

interface Data {
  user: UserModel;
}

interface Variables {
  userId: string;
}

interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organization: {
    id: string;
    name: string;
  };
}

const IMPERSONATE_USER = gql`
  mutation ImpersonateUser($userId: UUID4!) {
    impersonateUser(userId: $userId) {
      errors {
        key
        message
      }
      token
    }
  }
`;

interface MutationData {
  impersonateUser: {
    errors?: InputError[];
    token?: string;
  };
}

interface MutationVariables {
  userId: string;
}

interface ImpersonateUserModalProps {
  isOpen: boolean;
  onClose(): void;
  userId: string;
}

export const ImpersonateUserModal: FC<ImpersonateUserModalProps> = (props) => {
  const { isOpen, onClose, userId } = props;

  const [statusErrors, setStatusErrors] = useState<InputError[] | null>(null);

  const { data, loading, error } = useQuery<Data, Variables>(GET_USER, {
    variables: { userId },
  });
  const [getImpersonationToken, { loading: submitting }] = useMutation<
    MutationData,
    MutationVariables
  >(IMPERSONATE_USER);

  const impersonateUser = useCallback(() => {
    setStatusErrors(null);

    return getImpersonationToken({ variables: { userId } }).then((resp) => {
      if (resp.data?.impersonateUser.errors) {
        setStatusErrors(resp.data.impersonateUser.errors);
      } else if (resp.data?.impersonateUser.token) {
        const url = impersonateUrl(resp.data.impersonateUser.token);
        window.open(url, "_blank");
        onClose();
      }
    });
  }, [userId, getImpersonationToken, setStatusErrors, onClose]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="mask" title="Impersonate User" onClose={onClose} />
      <div className="_ImpersonateFormModal px-4 text-center">
        {loading ? (
          <div className="p-3">
            <Spinner />
          </div>
        ) : error || !data?.user ? (
          <p>Failed to load</p>
        ) : (
          <>
            <FormStatusErrors status={{ errors: statusErrors || undefined }} />
            <p className="mt-3">
              Are you sure you want impersonate{" "}
              <span className="font-semibold">
                {data.user.firstName} {data.user.lastName} ({data.user.email})
              </span>{" "}
              at{" "}
              <span className="font-semibold">
                {data.user.organization.name}
              </span>
              ?
            </p>

            <Button
              type="button"
              className="mt-4"
              onClick={impersonateUser}
              disabled={submitting}
            >
              Yes, Impersonate User
            </Button>
            <p className="mt-5 text-xs text-cool-gray-500">
              Impersonations and actions taken while impersonating are logged
              for auditing.
            </p>
          </>
        )}
      </div>
    </Modal>
  );
};

function impersonateUrl(token: string) {
  const host = appHost(window.location.host);
  const params = qs.stringify({ token });
  return `${host}/auth/impersonate?${params}`;
}

function appHost(adminHost: string) {
  if (adminHost.includes(".dev")) {
    return "https://app.preferral.dev";
  } else if (adminHost.includes("sandbox.aristamd.dev")) {
    return "https://next.sandbox.aristamd.dev";
  } else if (adminHost.includes("integration.aristamd.com")) {
    return "https://next.integration.aristamd.com";
  } else if (adminHost.includes("aristamd.com")) {
    return "https://next.aristamd.com";
  } else if (adminHost.includes("preferral.com")) {
    return "https://next.preferral.com";
  } else {
    return "localhost:3000";
  }
}
