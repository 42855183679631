import React, { FC } from "react";
import { Avatar, ImageObject } from "@preferral/ui";
import { ProviderSearchResultModel } from "./types";

export const defaultAvatar: ImageObject = {
  base64: "",
  src: "https://i.imgur.com/mCHMpLT.png?3",
  srcSet: "https://i.imgur.com/mCHMpLT.png?3 1x",
};

interface ProviderSearchResultProps {
  providerResult: ProviderSearchResultModel;
}

export const ProviderSearchResult: FC<ProviderSearchResultProps> = (props) => {
  const { providerResult } = props;
  return (
    <div className="_ProviderSearchResult flex items-center px-3 py-1">
      <Avatar
        size="thumb"
        image={providerResult.avatar || defaultAvatar}
        className="flex-shrink-0 border border-cool-gray-100"
      />
      <div className="flex-grow flex-shrink min-w-0 overflow-hidden ml-3 font-semibold text-gray-800">
        <p className="whitespace-no-wrap truncate">
          {providerResult.nameWithAppellation}
          {providerResult.primarySpecialtyName ? (
            <span className="ml-2 font-light text-xs italic text-cool-gray-600 truncate">
              {providerResult.primarySpecialtyName}
            </span>
          ) : null}
        </p>
        {providerResult.locationCityStates.length > 0 ||
        providerResult.organizationNames.length > 0 ? (
          <p className="font-light text-cool-gray-600 italic text-xs">
            {providerResult.organizationNames[0] || null}
            {providerResult.locationCityStates.length > 0 &&
              providerResult.organizationNames.length > 0 && (
                <span className="mx-2">{"·"}</span>
              )}
            {providerResult.locationCityStates[0] || null}
          </p>
        ) : null}
      </div>
    </div>
  );
};
