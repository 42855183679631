import React, { FC } from "react";

interface NetworkSettingsProps {
  networkId: string;
}

export const NetworkSettings: FC<NetworkSettingsProps> = (props) => {
  return (
    <div className="NetworkSettings">
      <p>NetworkSettings</p>
    </div>
  );
};
