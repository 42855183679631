import React, { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { Spinner, FAIcon } from "@preferral/ui";

const USER_LOGIN_ATTEMPTS = gql`
  query UserLoginAttempts($id: UUID4!) {
    user(id: $id) {
      id
      loginAttempts {
        id
        insertedAt
        successful
        requestInfo {
          remoteIp
          userAgent
          clientLocation
          latitude
          longitude
        }
      }
    }
  }
`;

interface Data {
  user: {
    id: string;
    loginAttempts: LoginAttemptModel[];
  };
}

interface Variables {
  id: string;
}

interface LoginAttemptModel {
  id: string;
  insertedAt: string;
  successful: boolean;
  requestInfo: {
    remoteIp: string;
    userAgent: string;
    clientLocation?: string;
    latitude?: number;
    longitude?: number;
  };
}

/**
 * LoginAttemptDetails.
 */

interface LoginAttemptDetailsProps {
  loginAttempt: LoginAttemptModel;
}

export const LoginAttemptDetails: FC<LoginAttemptDetailsProps> = (props) => {
  const { loginAttempt } = props;

  return (
    <div className="_LoginAttemptDetails p-3">
      <pre>{JSON.stringify(loginAttempt, null, 2)}</pre>
    </div>
  );
};

/**
 * UserLoginAttempts.
 */

interface UserLoginAttemptsProps {
  userId: string;
}

export const UserLoginAttempts: FC<UserLoginAttemptsProps> = (props) => {
  const { userId } = props;

  const { data, loading, error } = useQuery<Data, Variables>(
    USER_LOGIN_ATTEMPTS,
    { variables: { id: userId } }
  );

  const [
    selectedAttempt,
    setSelectedAttempt,
  ] = useState<LoginAttemptModel | null>(null);

  return (
    <div className="_UserLoginAttempts p-4">
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !data?.user ? (
        <p>Failed to load.</p>
      ) : (
        <div>
          <div className="grid grid-cols-2">
            <div>
              {data.user.loginAttempts.map((la) => (
                <div
                  key={la.id}
                  className={`border-b border-cool-gray-300 p-2 ${
                    la.successful ? "bg-green-100" : "bg-red-100"
                  }`}
                  onClick={() => setSelectedAttempt(la)}
                >
                  {la.insertedAt} - {la.requestInfo.remoteIp}
                </div>
              ))}
            </div>
            <div>
              {selectedAttempt ? (
                <LoginAttemptDetails loginAttempt={selectedAttempt} />
              ) : (
                <p>Select a login attempt to view details.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
