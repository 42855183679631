import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CircleIcon } from "components/CircleIcon";

type DrawerHeaderProps = {
  icon: IconProp;
  title: string;
  onClose: () => void;
  color?: AppColor;
};

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  icon,
  color = "blue",
  onClose,
  title,
}) => {
  return (
    <div className="DrawerHeader">
      <div className="flex px-6 py-4 justify-between items-center">
        <div className="flex items-center">
          <div>
            <CircleIcon icon={icon} color={color} />
          </div>
          <div className="ml-4">
            <h4 className="text-gray-700 text-2xl">{title}</h4>
          </div>
        </div>
        <button
          type="button"
          aria-label="Close"
          className="text-cool-gray-500 focus:outline-none focus:text-cool-gray-800 hover:text-gray-800"
          onClick={onClose}
        >
          <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6">
            <path d="M18.2929 19.7071C18.6834 20.0976 19.3166 20.0976 19.7071 19.7071C20.0976 19.3166 20.0976 18.6834 19.7071 18.2929L13.4142 12L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289C19.3166 3.90237 18.6834 3.90237 18.2929 4.29289L12 10.5858L5.70711 4.29289C5.31658 3.90237 4.68342 3.90237 4.29289 4.29289C3.90237 4.68342 3.90237 5.31658 4.29289 5.70711L10.5858 12L4.29289 18.2929C3.90237 18.6834 3.90237 19.3166 4.29289 19.7071C4.68342 20.0976 5.31658 20.0976 5.70711 19.7071L12 13.4142L18.2929 19.7071Z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export { DrawerHeader };
