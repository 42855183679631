import React from "react";
import { ImageWithIEPolyfill as Image } from "components/Image";
import { ImageObject } from "components/Image/Image";
// import "./Avatar.scss";

type AvatarProps = {
  image: ImageObject;
};

const AvatarThumbnail: React.FC<AvatarProps> = ({ image }) => {
  return (
    <div className="Avatar AvatarThumbnail h-12 inline-flex rounded-full shadow-inner overflow-hidden">
      <Image alt="" height={48} width={48} image={image} />
    </div>
  );
};

export { AvatarThumbnail };

const AvatarSmall: React.FC<AvatarProps> = ({ image }) => {
  return (
    <div className="Avatar AvatarSmall h-18 inline-flex rounded-full shadow-inner overflow-hidden">
      <Image alt="" height={72} width={72} image={image} />
    </div>
  );
};

export { AvatarSmall };

const AvatarMedium: React.FC<AvatarProps> = ({ image }) => {
  return (
    <div className="Avatar AvatarMedium">
      <Image alt="" height={128} width={128} image={image} />
    </div>
  );
};

export { AvatarMedium };
