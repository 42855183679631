import { FC, useCallback, useState } from "react";
import { gql } from 'graphql.macro';
import { useQuery } from "@apollo/client";
import { ScreenTitle } from "context/ScreenTitle";
import { Badge, Button, DropdownButton, DropdownItemButton, DropdownItemLink, Spinner, Table, TableContainer, TD, TH } from "@preferral/ui";
import { NoResults } from "components/NoResults";
import { useRouteMatch } from "react-router-dom";
import { EditSpecialtyModal } from "./EditSpecialtyModal";

const LIST_SPECIALTIES = gql`
  query ListSpecialties {
    specialties {
      id
      name
      taxonomyCode
      ageClassification
      ribbonUuid
      curated
      defaultSearchMode
      parentSpecialty {
        id
        name
        taxonomyCode
      }
      isCustom
      organization {
        id
        name
      }
    }
  }
`;

interface Data {
  specialties: SpecialtyModel[];
}

export interface SpecialtyModel {
  id: string;
  name: string;
  taxonomyCode: string;
  ageClassification: null | string;
  ribbonUuid: null | string;
  curated: boolean;
  defaultSearchMode: string;
  parentSpecialty: null | {
    id: string;
    name: string;
    taxonomyCode: string;
  };
  isCustom: boolean;
  organization: null | {
    id: string;
    name: string;
  }
}

interface SpecialtiesScreenProps { };

type ActiveModal = { type: "EDIT_SPECIALTY", specialtyId: string };

export const SpecialtiesScreen: FC<SpecialtiesScreenProps> = props => {
  const { data, loading, error, refetch } = useQuery<Data>(LIST_SPECIALTIES);

  const match = useRouteMatch();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null)

  const closeModal = useCallback(() => setActiveModal(null), []);

  return (
    <>
      {
        activeModal?.type === "EDIT_SPECIALTY" ? (
          <EditSpecialtyModal
            isOpen
            onClose={closeModal}
            specialtyId={activeModal.specialtyId}
            onSuccess={() => {
              closeModal();
              refetch();
            }}
          />
        ) : null
      }
      <ScreenTitle title="Specialties" />
      <div className="_SpecialtiesScreen container p-4">
        {
          loading ? (
            <div className="p-12">
              <Spinner />
            </div>
          ) : error || !data?.specialties ? (
            <div className="p-6 text-center">
              <p className="py-8">Failed to load.</p>
              <Button type="button" size="sm" onClick={refetch}>
                Retry
              </Button>
            </div>
          ) : data.specialties.length === 0 ? (
            <div className="p-6">
              <NoResults icon="stethoscope" text="No specialties" />
            </div>
          ) : (
            <div className="max-w-full">
              <TableContainer>
                <Table className="bg-white">
                  <thead>
                    <tr>
                      <TH>Referrable?</TH>
                      <TH>Name</TH>
                      <TH>Parent Specialty</TH>
                      <TH>Custom?</TH>
                      <TH>Actions</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.specialties.map(s => (
                        <tr key={s.id}>
                          <TD>
                            {s.curated ? (
                              <Badge color="green">Yes</Badge>
                            ) : (
                              <Badge color="gray">No</Badge>
                            )}
                          </TD>
                          <TD wrap>{s.name}</TD>
                          <TD wrap>{s.parentSpecialty?.name}</TD>
                          <TD wrap>{s.organization?.name}</TD>
                          <TD>
                            <DropdownButton>
                              <DropdownItemLink to={`${match.path}/${s.id}/referral_form`}>
                                Edit Referral Form
                              </DropdownItemLink>
                              <DropdownItemButton onClick={() => {
                                setActiveModal({ type: "EDIT_SPECIALTY", specialtyId: s.id });
                              }}>
                                Edit Specialty
                              </DropdownItemButton>
                            </DropdownButton>
                          </TD>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </TableContainer>
            </div>
          )
        }
      </div>
    </>
  );
};
