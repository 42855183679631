import { FC, CSSProperties, useLayoutEffect } from "react";
import { SignInForm } from "./SignInForm";
import { useAuth } from "context/AuthContext";
import { useLocation, useHistory } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { useToggle } from "@preferral/ui";

/**
 * Cross.
 */
interface CrossProps {
  className?: string;
  style?: CSSProperties;
  fill?: string;
}
const Cross: FC<CrossProps> = ({
  className,
  style,
  fill = "rgba(0,0,0,0.2)",
}) => {
  return (
    <svg
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
    >
      <path
        d="M26.2 0c.2 0 .5.2.5.5v12.8h1.7c.1 0 .2.1 0 .2l-3.7 3.8-3.8-3.8c-.1 0 0-.2 0-.2h2v-9c0-.2-.3-.4-.6-.4h-4.6c-.3 0-.5.2-.5.5V13c0 .1 0 .2-.2 0l-3.5-3.4-.2-.5V.5c0-.3.3-.5.5-.5h12.4zM40 26.2c0 .2-.2.5-.5.5H26.7v1.7c0 .1-.1.2-.2 0l-3.8-3.7 3.8-3.8c0-.1.2 0 .2 0v2h9c.2 0 .4-.3.4-.6v-4.6c0-.3-.2-.5-.5-.5H27c-.1 0-.2 0 0-.2l3.4-3.5.5-.2h8.7c.3 0 .5.3.5.5v12.4zM.5 13.3c-.3 0-.5.3-.5.5v12.4c0 .2.2.5.5.5h8.7l.5-.2 3.5-3.5-.1-.2H4.4a.5.5 0 01-.5-.5v-4.6c0-.3.2-.5.5-.5h9V19s0 .1.1 0l3.8-3.8-3.8-3.8-.2.1v1.7H.5zM13.7 40a.5.5 0 01-.5-.5V26.7h-1.7c-.2 0-.2-.1-.1-.2l3.8-3.8 3.8 3.8c.1 0 0 .2 0 .2h-2v9c0 .2.3.4.6.4h4.6c.3 0 .5-.2.5-.5V27c0-.1 0-.2.2 0l3.5 3.4.1.5v8.7c0 .3-.2.5-.4.5H13.7z"
        fill={fill}
      />
    </svg>
  );
};

/**
 * SSOForm.
 */

const SSOForm: FC = () => {
  const ssoUrlBase =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4040"
      : `${window.location.protocol}//${window.location.host}`;

  return (
    <div className="p-6">
      <a
        href={`${ssoUrlBase}/sso/auth/signin/google_workspace`}
        className="bg-blue-600 border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 font-medium hover:bg-blue-700 inline-flex items-center justify-center max-w-md px-4 py-2 rounded-md shadow-sm text-base text-center text-white w-full"
      >
        SSO Sign In
      </a>
    </div>
  );
};

export const SignInScreen: FC = () => {
  const { token } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const { from } = (location.state as any) || {
    from: { pathname: "/admin/accounts" },
  };

  const [sso, toggleSSO] = useToggle(true);

  useLayoutEffect(() => {
    if (token) {
      history.replace(from);
    }
  }, [history, token, from]);

  return (
    <div
      className="SignInScreen flex h-screen items-center justify-center overflow-hidden relative w-screen"
      style={{ background: "linear-gradient(45deg, #0e2f58, #5a0d0d)" }}
    >
      <ScreenTitle title="Preferral Admin Sign In" />
      <div
        className="absolute z-10"
        style={{
          width: "80vw",
          animationDuration: "30s",
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
          animationName: "rotate-forever",
        }}
      >
        <Cross
          className="absolute"
          style={{
            transform: "scale(3) rotate(25deg) translate(4vh, 11vh)",
          }}
          fill="rgba(0,0,0,0.15)"
        />
      </div>
      <div
        className="z-20 flex max-w-5xl rounded-xl shadow-2xl w-full"
        style={{ background: "linear-gradient(45deg, #13223f, #331321)" }}
      >
        <div className="flex-1 py-32 overflow-hidden relative rounded-l-xl flex items-center justify-center">
          <div className="-mt-8">
            <div className="mx-auto my-4 w-12">
              <Cross fill="white" />
            </div>
            <p className="text-blue-200 text-xl">Preferral Admin</p>
          </div>
          {/* <Cross
            className="absolute"
            style={{
              transform: "scale(3) rotate(25deg) translate(4vh, 11vh)"
            }}
          /> */}
        </div>
        <div className="bg-gray-100 flex-1 flex flex-col overflow-hidden p-8 rounded-r-xl">
          <div className="flex flex-col flex-grow justify-center">
            {sso ? <SSOForm /> : <SignInForm refetchQueries={[]} />}
          </div>

          <div className="flex justify-center">
            <button
              type="button"
              onClick={toggleSSO}
              className="inline-block p-1 text-xs text-purple-400 hover:text-purple-600 cursor-pointer"
            >
              {sso ? "Use password-based login" : "Use SSO"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
