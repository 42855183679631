import { FC, useEffect, useCallback, useState } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import Select, {
  Props as SelectProps,
  components,
  OptionProps,
} from "react-select";
import { CircleIcon } from "@preferral/ui";
import { ProviderReferenceModel } from "components/ProviderSearchSelect";
import { useDebounce } from "use-debounce";

const LOCATION_SEARCH = gql`
  query SearchLocations($filter: SearchLocationsFilter) {
    searchLocations(filter: $filter) {
      items {
        id
        kind
        name
        streetAddress
        streetAddressLine2
        city
        stateAbbreviation
        zip
      }
    }
  }
`;

interface Data {
  searchLocations: Paginated<LocationSearchResult>;
}

interface Variables {
  filter?: LocationSearchFilter;
}

interface LocationSearchResult {
  id: string;
  kind: LocationKind;
  name: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
}

export type LocationKind = "platform" | "nppes" | "unlisted";

export interface LocationSearchFilter {
  searchTerm?: string;
  provider?: ProviderReferenceModel;
  organizationId?: string;
}

const LOCATION = gql`
  query GetLocationSearchResult($kind: String!, $id: UUID4!) {
    locationSearchResult(kind: $kind, id: $id) {
      id
      kind
      name
      streetAddress
      streetAddressLine2
      city
      stateAbbreviation
      zip
    }
  }
`;

interface SingleData {
  locationSearchResult: LocationSearchResult;
}

/**
 * LocationReferenceOption.
 */

export interface LocationReferenceOptionModel {
  id: string;
  kind: LocationKind;
  name: string;
}

type LocationReferenceOptionProps = OptionProps<
  LocationReferenceOptionModel,
  false
>;

const LocationReferenceOption: FC<LocationReferenceOptionProps> = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center">
        <CircleIcon icon="map-marker-alt" />
        <div className="ml-3">
          <p className="leading-tight text-gray-900 font-semibold">
            {props.data.name}
          </p>
          <div className="mt-1 leading-none text-gray-700 text-xs">
            <p>
              {props.data.streetAddress}
              {props.data.streetAddressLine2 &&
                `, ${props.data.streetAddressLine2}`}
            </p>
            <p>
              {props.data.city}, {props.data.stateAbbreviation},{" "}
              {props.data.zip}
            </p>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

/**
 * LocationSearchSelect.
 */

type LocationSearchSelectProps = {
  value?: LocationReferenceOptionModel | null;
  filter?: LocationSearchFilter;
} & Pick<
  SelectProps<LocationReferenceOptionModel>,
  "id" | "onChange" | "onBlur" | "placeholder"
>;

const initialSearchTerm = "";

export const LocationSearchSelect: FC<LocationSearchSelectProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);

  const { value, filter = {}, ...rest } = props;
  const { data, loading, refetch } = useQuery<Data, Variables>(
    LOCATION_SEARCH,
    {
      variables: {
        filter: {
          ...filter,
          searchTerm: initialSearchTerm,
        },
      },
    }
  );

  useEffect(() => {
    refetch({
      filter: {
        ...filter,
        searchTerm: debouncedSearchTerm,
      },
    });
  }, [debouncedSearchTerm]);

  const [loadValueLocation, { data: valueData }] = useLazyQuery<SingleData>(
    LOCATION
  );

  // This ensures we load the data for the passed in `value` location id.
  useEffect(() => {
    if (value) {
      loadValueLocation({ variables: { id: value.id, kind: value.kind } });
    }
  }, [loadValueLocation, value]);

  // /**
  //  * This is passed to AsyncSelect to load options based on
  //  * the typed inputValue, which we'll use as `searchTerm` in
  //  * our query filter.
  //  */
  // const asyncLoad = useCallback(
  //   (inputValue: string) => {
  //     return refetch({ filter: { ...filter, searchTerm: inputValue }}).then(
  //       res => {
  //         return res.data.searchLocations.items;
  //       }
  //     );
  //   },
  //   [refetch, filter]
  // );

  const onInputChange = useCallback(
    (inputValue: string, deets: { action: string }) => {
      if (deets.action === "input-change" && inputValue !== searchTerm) {
        setSearchTerm(inputValue);
      }
    },
    [searchTerm, setSearchTerm]
  );

  /**
   * This is the LocationOption data for the selected value id.
   * We're guarding for the case where we have the selected id
   * but haven't quite loaded the data for it.
   */
  const valueLocation: LocationReferenceOptionModel | null = value
    ? !(valueData && valueData.locationSearchResult)
      ? { id: value.id, kind: value.kind, name: "Loading..." }
      : valueData.locationSearchResult
    : null;

  return (
    <Select<LocationReferenceOptionModel>
      {...rest}
      isLoading={loading}
      value={valueLocation}
      options={data?.searchLocations.items || []}
      onInputChange={onInputChange}
      components={{ Option: LocationReferenceOption }}
      getOptionLabel={getOptionLabel}
    />
  );
};

function getOptionLabel(option: LocationReferenceOptionModel): string {
  return option.name;
}
