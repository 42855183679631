import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo
} from "react";
import jwtDecode from "jwt-decode";

const LOCAL_STORAGE_TOKEN_KEY = "preferral-token";

export const tokenStore = {
  /**
   * Stores the given token in localStorage
   * param {Token} token The JSON object representing the auth token (including jwt, exp, etc.)
   */
  storeToken(token: Token) {
    // console.log("::storeToken", token);
    return localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(token));
  },

  /**
   * Gets the token stored in localStorage
   * returns {Token|null} The token object or null if there's no stored token
   */
  getStoredToken(): Token | null {
    const stringToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    const token = stringToken ? JSON.parse(stringToken) : null;
    // console.log("::getStoredToken", token);
    return token;
  },

  /**
   * Drops the currently saved token
   * @returns {void}
   */
  forgetToken() {
    // console.log("::forgetToken");
    return localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }
};

function expandJWT(jwt: string): Token {
  return {
    jwt,
    claims: jwtDecode(jwt)
  };
}

export interface AuthState {
  token: Token | null;
}

export interface AuthOps {
  login(jwt: string, cb?: () => void): void;
  logout(): void;
}

export type AuthContext = AuthState & AuthOps;

const authContext = createContext<AuthContext>(null!);

export const AuthProvider: FC = props => {
  const [token, setToken] = useState<Token | null>(tokenStore.getStoredToken());

  const login = useCallback(
    (jwt: string, cb?: () => void) => {
      const token = expandJWT(jwt);
      tokenStore.storeToken(token);
      setToken(token);
      if (cb) {
        cb();
      }
    },
    [setToken]
  );

  const logout = useCallback(() => {
    tokenStore.forgetToken();
    setToken(null);
  }, [setToken]);

  const value = useMemo(() => ({ token, login, logout }), [token, login, logout]);

  return <authContext.Provider value={value} {...props} />;
};

export function useAuth() {
  const context = useContext(authContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}
