import uuid from "uuid-random";
import { QuestionnaireModel } from "components/Questionnaire";
import { newQuestion, newSection } from "./helpers";

export const defaultReferralForm: QuestionnaireModel = {
  id: uuid(),
  preSubmissionRules: [],
  postSubmissionRules: [],
  sections: [
    newSection({
      title: "Background",
      questions: [
        newQuestion({ kind: "text", prompt: "Reason for referral?" }),
      ],
    }),
  ],
};
