import React, { FC, InputHTMLAttributes } from "react";
import { InputText, Spinner, FAIcon, Popper } from "@preferral/ui";
import { gql, useQuery } from "@apollo/client";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCombobox } from "downshift";
import { useDebounce } from "use-debounce";
import {
  ProviderReferenceModel,
  ProviderSearchResultModel,
  ProviderKindModel,
} from "./types";
import { ProviderSearchResult } from "./ProviderSearchResult";

const PROVIDER_SEARCH = gql`
  query SesarchProviders($filter: SearchProvidersFilter) {
    searchProviders(filter: $filter) {
      id
      npi
      kind
      firstName
      lastName
      nameWithAppellation
      primarySpecialtyName
      locationCityStates
      organizationNames
      avatar {
        base64
        src
        srcSet
      }
    }
  }
`;

interface Data {
  searchProviders: ProviderSearchResultModel[];
}

interface Variables {
  filter?: ProviderSearchFilter;
}

export interface ProviderSearchFilter {
  searchTerm?: string;
  kinds?: ProviderKindModel[];
  organizationId?: string;
}

/**
 * ProviderSearchBox.
 */

export interface ProviderSearchBoxProps {
  value: string;
  icon?: IconProp;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  className?: string;
  onChange(newValue: string): void;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
  onProviderClick(provider: ProviderReferenceModel): void;
  filter?: ProviderSearchFilter;
}

export const ProviderSearchBox: FC<ProviderSearchBoxProps> = (props) => {
  const {
    value,
    onChange,
    onBlur,
    filter = {},
    inputProps = {},
    icon,
    onProviderClick,
  } = props;

  const [debouncedValue] = useDebounce(value, 400);
  const { data, loading } = useQuery<Data, Variables>(PROVIDER_SEARCH, {
    variables: {
      filter: {
        ...filter,
        searchTerm: debouncedValue,
      },
    },
  });

  const {
    isOpen,
    getInputProps,
    getComboboxProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<ProviderSearchResultModel>({
    items: data?.searchProviders || [],
    onInputValueChange({ inputValue }) {
      onChange(inputValue || "");
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        onProviderClick(toProviderReference(selectedItem));
      }
    },
    itemToString(item) {
      return item.nameWithAppellation;
    },
  });

  return (
    <div className="relative">
      <Popper
        placement={{
          horizontal: "left",
          vertical: "bottom",
        }}
        widthFull
        content={
          isOpen && (
            <div className="relative">
              <div
                className="bg-white border mt-1 py-2 rounded shadow-xl w-full"
                {...getMenuProps()}
              >
                <p className="font-semibold leading-tight select-none text-center text-gray-600 text-xs">
                  Type to search
                </p>
                {data?.searchProviders ? (
                  data.searchProviders.length === 0 ? (
                    <p className="font-light py-6 text-center text-gray-600 text-lg">
                      <span className="mr-2 text-base text-gray-500">
                        <FAIcon icon="search" />
                      </span>
                      {debouncedValue === ""
                        ? "Type to Search"
                        : loading
                        ? "Loading..."
                        : "No Results"}
                    </p>
                  ) : (
                    data.searchProviders.map((item, index) => (
                      <div
                        key={item.id}
                        className={`_ProviderSearchBoxItem cursor-pointer hover:bg-blue-200 transition-colors transition-faster ${
                          index === highlightedIndex ? "bg-blue-200" : ""
                        }`}
                        {...getItemProps({
                          item,
                          index,
                        })}
                      >
                        <ProviderSearchResult providerResult={item} />
                      </div>
                    ))
                  )
                ) : loading ? (
                  <div className="text-center p-4">
                    <Spinner />
                  </div>
                ) : (
                  <p>Failed to load.</p>
                )}
              </div>
            </div>
          )
        }
      >
        <div className="_ProviderSearchBox" {...getComboboxProps()}>
          <InputText
            isLoading={loading}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            icon={icon}
            inputProps={{
              ...inputProps,
              ...getInputProps(),
              autoComplete: "off",
            }}
          />
        </div>
      </Popper>
    </div>
  );
};

function toProviderReference(
  searchResult: ProviderSearchResultModel
): ProviderReferenceModel {
  return {
    id: searchResult.id,
    kind: searchResult.kind,
  };
}
