import React, { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  Button,
  TextField,
  useWaitFor,
  useComponentId,
  useFileUploader,
} from "@preferral/ui";
import { FileUploadInput } from "components/formik/FileUploadField";

const ENCRYPT_FILES = gql`
  mutation EncryptFiles($input: EncryptFilesInput!) {
    encryptFiles(input: $input) {
      errors {
        key
        message
      }
      encryptedArchiveUrl
    }
  }
`;

interface MutationData {
  encryptFiles: {
    errors?: InputError[];
    encryptedArchiveUrl?: string;
  };
}

interface MutationVariables {
  input: EncryptFilesInput;
}

interface EncryptFilesInput {
  encryptionPassword: string;
  encryptionPasswordConfirmation: string;
  algorithm: "AES" | "PGP";
  fileUploadIds: string[];
}

type FormValues = EncryptFilesInput;

const validationSchema = Yup.object().shape({
  encryptionPassword: Yup.string()
    .required("Required")
    .min(7),
  encryptionPasswordConfirmation: Yup.string()
    .required("Required")
    .min(7),
  algorithm: Yup.string()
    .required("Required")
    .oneOf(["AES", "PGP"]),
  fileUploadIds: Yup.array()
    .of(Yup.string().required())
    .required("Required"),
});

interface EncryptFilesFormProps {
  onSuccess(archiveUrl: string): void;
}

export const EncryptFilesForm: FC<EncryptFilesFormProps> = (props) => {
  const { onSuccess } = props;
  const [encryptFiles] = useMutation<MutationData, MutationVariables>(
    ENCRYPT_FILES
  );

  /**
   * Grab a promise we can wait on while file uploads
   * are finishing.
   */
  const instanceId = useComponentId();
  const {
    state: { isUploading },
  } = useFileUploader({
    instanceId,
  });

  const checkUploadsComplete = useCallback(() => !isUploading, [isUploading]);
  const [waitForUploads] = useWaitFor(checkUploadsComplete);

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      return waitForUploads()
        .then(() =>
          encryptFiles({
            variables: { input: values },
          })
        )
        .then(
          (resp) => {
            if (!!resp.data?.encryptFiles.errors) {
              setStatus({ errors: resp.data.encryptFiles.errors });
            } else if (resp.data?.encryptFiles.encryptedArchiveUrl) {
              return onSuccess(resp.data.encryptFiles.encryptedArchiveUrl);
            }
            setSubmitting(false);
          },
          (rej) => {
            setStatus({
              errors: [{ key: "", message: "Something went wrong" }],
            });
            setSubmitting(false);
          }
        );
    },
    [waitForUploads, encryptFiles]
  );
  return (
    <div className="EncryptFilesForm">
      <Formik<FormValues>
        initialValues={{
          encryptionPassword: "",
          encryptionPasswordConfirmation: "",
          algorithm: "AES",
          fileUploadIds: [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="mt-3">
              <TextField
                type="password"
                name="encryptionPassword"
                label="Encryption Password"
              />
            </div>
            <div className="mt-3">
              <TextField
                type="password"
                name="encryptionPasswordConfirmation"
                label="Confirm Password"
              />
            </div>
            <div className="mt-6">
              <FileUploadInput name="fileUploadIds" />
            </div>

            <div className="flex items-center justify-center mt-6 p-4">
              <Button
                type="submit"
                kind="primary"
                color="blue"
                size="lg"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
