import { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  TableContainer,
  Table,
  TH,
  TD,
  Spinner,
  FAIcon,
  JsonDebugger,
  Badge,
  Button,
  makeAppendItems
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { NoResults } from "components/NoResults";
import { ImportAmdUserButton } from "./ImportAmdUserButton";

const PAGE_SIZE = 30;

const ORGANIZATION_AMD_USERS = gql`
  query OrganizationAMDUsers(
    $first: Int
    $after: Int
    $filter: ListAmdUsersFilter
  ) {
    amdUsers(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        name
        username
        email
        createdAt
        status
        roles {
          id
          displayName
        }
        preferralUserId
      }
    }
  }
`;

interface Data {
  amdUsers: Paginated<AMDUserModel, number>;
}

interface Variables {
  first?: number;
  after?: number;
  filter: FilterModel;
}

interface AMDUserModel {
  id: number;
  name: string;
  username: string;
  email: string;
  createdAt: string;
  status: string;
  roles: {
    id: number;
    displayName: string;
  }[];
  preferralUserId: string | void;
}

interface FilterModel {
  searchTerm?: string;
  status?: string;
  organizationId?: string;
}

/**
 * OrganizationAMDUsers.
 */

interface OrganizationAMDUsersProps {
  organizationId: string;
}

export const OrganizationAMDUsers: FC<OrganizationAMDUsersProps> = props => {
  const { organizationId } = props;

  const filter = { organizationId };

  const { data, loading, error, fetchMore } = useQuery<Data, Variables>(
    ORGANIZATION_AMD_USERS,
    { variables: { first: PAGE_SIZE, filter } }
  );

  return (
    <>
      <ScreenTitle
        title={["Organization", "View Organization", "eConsult Platform Users"]}
      />

      <div className="_OrganizationAMDUsers bg-gray-100 mt-4 p-4 rounded-2xl text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.amdUsers ? (
          <p>Failed to load</p>
        ) : (
          <div>
            {data.amdUsers.items.length === 0 ? (
              <NoResults icon="users" text="No eConsult Platform Users" />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH>Status</TH>
                      <TH className="w-40 text-center">Sync</TH>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.amdUsers.items.map(user => (
                      <tr key={user.id}>
                        <TD>
                          <p className="flex font-semibold items-center">
                            {user.name}
                          </p>
                          <p className="flex items-center text-xs">
                            <span className="text-gray-400 mr-2">
                              <FAIcon icon="envelope" />
                            </span>
                            {user.email}
                          </p>
                        </TD>
                        <TD>{user.status}</TD>
                        <TD className="w-40 text-center">
                          {user.preferralUserId ? (
                            <Badge color="green">Imported</Badge>
                          ) : user.status === "active" ? (
                            <ImportAmdUserButton amdUserId={+user.id} />
                          ) : (
                            <Badge color="gray">Unavailable</Badge>
                          )}
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {data.amdUsers.items.length > 0 ? (
                  <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                    {data.amdUsers.endOfList ? (
                      <p>End of List</p>
                    ) : (
                      <Button
                        type="button"
                        size="sm"
                        kind="secondary"
                        color="blue"
                        className="w-full"
                        onClick={() => {
                          fetchMore({
                            query: ORGANIZATION_AMD_USERS,
                            variables: {
                              first: PAGE_SIZE,
                              after: data.amdUsers.cursor,
                              filter
                            },
                            updateQuery
                          });
                        }}
                      >
                        Load More
                      </Button>
                    )}
                  </div>
                ) : null}
              </TableContainer>
            )}
            <JsonDebugger data={data} />
          </div>
        )}
      </div>
    </>
  );
};

const updateQuery = makeAppendItems<Data>("amdUsers");
