import React, { FC } from "react";

interface NetworkPracticesProps {
  networkId: string;
}

export const NetworkPractices: FC<NetworkPracticesProps> = (props) => {
  return (
    <div className="NetworkPractices">
      <p>NetworkPractices</p>
    </div>
  );
};
