import React, { FC } from "react";
import { RuleModel } from "components/Questionnaire";
import { EditConditionalGroup } from "./EditConditionalGroup";
import { EditActions } from "./EditActions"

interface EditRuleProps {
  prefix: string;
  name: string;
  rule: RuleModel;
  onDelete(): void;
};

export const EditRule: FC<EditRuleProps> = props => {
  const {
    prefix,
    rule,
    onDelete,
    name,
  } = props;

  return (
    <div className="EditRule bg-white border border-gray-300 shadow rounded p-4">
      <div className="flex justify-end">
        <button
          type="button"
          className="btn btn-sm btn-red-alt"
          onClick={onDelete}
        >
          Delete Rule
        </button>
      </div>
      <div className="flex">
        <div className="font-mono pt-5 px-2 text-3xl text-gray-600 text-right w-32">
          IF:
        </div>
        <div className="flex-1 p-2">
          <EditConditionalGroup
            prefix={prefix}
            namePrefix={`${name}.conditionalGroup`}
            conditionalGroup={rule.conditionalGroup}
            canDelete={false}
          />
        </div>
      </div>
      <div className="flex">
        <div className="font-mono pt-1 px-2 text-3xl text-gray-600 text-right w-32">
          THEN:
        </div>
        <div className="flex-1 p-2">
          <EditActions namePrefix={name} rule={rule} />
        </div>
      </div>
    </div>
  );
};
