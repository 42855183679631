import { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Modal, ModalHeader } from "components/Modal";
import { NewNetworkForm } from "./NewNetworkForm";
import { Spinner, FAIcon, TableContainer, Table, TH, TD } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { defaultFilter, Filter, FilterPanel } from './FilterPanel';

const PAGE_SIZE = 30;

const LIST_NETWORKS = gql`
  query ListNetworks($first: Int, $after: UUID4, $filter: ListNetworksFilter) {
    networks(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        name
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  networks: Paginated<Network>;
}

interface Network {
  id: string;
  name: string;
  insertedAt: string;
  updatedAt: string;
}

type ActiveModal = { type: "NEW" } | { type: "EDIT"; networkId: string };

interface NetworksScreenProps { }

export const NetworksScreen: FC<NetworksScreenProps> = (props) => {
  const [currentModal, setCurrentModal] = useState<ActiveModal | null>(null);

  const closeModal = () => setCurrentModal(null);
  const openNewModal = () => setCurrentModal({ type: "NEW" });
  const openEditModal = (id: string) =>
    setCurrentModal({ type: "EDIT", networkId: id });

  const [filter, setFilter] = useState<Filter>(defaultFilter);

  const { loading, data, error } = useQuery<Data>(LIST_NETWORKS, {
    variables: {
      first: PAGE_SIZE,
      filter,
    },
  });

  return (
    <div className="_NetworksScreen">
      <ScreenTitle title="Networks" />
      <Modal isOpen={currentModal?.type === "NEW"} onRequestClose={closeModal}>
        <ModalHeader
          icon="project-diagram"
          title="New Network"
          onClose={closeModal}
        />
        <div className="p-4">
          <NewNetworkForm
            refetchQueries={[
              {
                query: LIST_NETWORKS,
                variables: {
                  first: PAGE_SIZE,
                  filter,
                },
              },
            ]}
            onSuccess={closeModal}
          />
        </div>
      </Modal>
      <div className="container mx-auto">
        <div
          spec-id="networks-control-panel"
          className="flex justify-between items-end p-4"
        >
          <FilterPanel
            value={filter}
            onChange={setFilter}
            isLoading={loading}
          />
          <div>
            <button
              type="button"
              className="btn btn-blue"
              onClick={openNewModal}
            >
              <span className="mr-2">
                <FAIcon icon="plus" />
              </span>
              New Network
            </button>
          </div>
        </div>

        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.networks ? (
          <p>Failed to load</p>
        ) : (
          <div className="pb-8 lg:px-4">
            <TableContainer>
              <Table>
                <thead>
                  <tr className="text-sm">
                    <TH>Name</TH>
                    <TH />
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data.networks.items.map((network) => (
                    <tr key={network.id}>
                      <TD>
                        <Link
                          to={`/admin/networks/${network.id}`}
                          className="font-bold text-blue-700"
                        >
                          {network.name}
                        </Link>
                      </TD>
                      <TD>Actions</TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {data.networks.items.length > 0 && data.networks.endOfList ? (
                <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                  <p>End of List</p>
                </div>
              ) : null}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};
