import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Modal, ModalHeader } from "components/Modal";
import { Spinner, TextField, Button, DateMaskField } from "@preferral/ui";
import { AccountStatusModel } from "screens/AccountsScreen/FilterPanel";
import parseISO from "date-fns/parseISO";
import lightFormat from "date-fns/lightFormat";

const ACCOUNT_INFO = gql`
  query GetAccountInfo($id: UUID4!) {
    account(id: $id) {
      id
      name
      status
      trialFinishAt
      stripeCustomerId
      salesforceAccountId
      quickbooksCustomerId
    }
  }
`;

interface Data {
  account: {
    id: string;
    name: string;
    status: AccountStatusModel;
    trialFinishAt?: string;
    stripeCustomerId?: string;
    salesforceAccountId?: string;
    quickbooksCustomerId?: string;
  };
}

const UPDATE_ACCOUNT_INFO = gql`
  mutation UpdateAccount($id: UUID4!, $input: AccountInput!) {
    updateAccount(id: $id, input: $input) {
      errors {
        key
        message
      }
      account {
        id
      }
    }
  }
`;

interface MutationData {
  updateAccount: {
    errors?: InputError[];
    account?: {
      id: string;
    };
  };
}

interface MutationVariables {
  id: string;
  input: FormValues;
}

interface FormValues {
  name: string;
  status: AccountStatusModel;
  salesforceAccountId: string;
  quickbooksCustomerId: string;
}

interface UpdateAccountInfoModalProps {
  accountId: string;
  isOpen: boolean;
  onClose(): void;
  refetchQueries: any;
}

export const UpdateAccountInfoModal: FC<UpdateAccountInfoModalProps> = (
  props
) => {
  const { accountId, isOpen, onClose, refetchQueries } = props;
  const { loading, data, error } = useQuery<Data>(ACCOUNT_INFO, {
    variables: { id: accountId },
  });

  const [updateAccount] = useMutation<MutationData, MutationVariables>(
    UPDATE_ACCOUNT_INFO
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      return updateAccount({
        variables: { id: accountId, input: values },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp.data?.updateAccount.errors) {
            setStatus({ errors: resp.data.updateAccount.errors });
          } else if (resp.data?.updateAccount.account) {
            // it worked...
            return onClose();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({ errors: [{ key: "", message: "Something went wrong" }] });
          setSubmitting(false);
          throw new Error(rej);
        }
      );
    },
    [updateAccount, accountId, onClose]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="pencil-alt" title="Edit Account" onClose={onClose} />
      <div className="p-4">
        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !data?.account ? (
          <p>Failed to load</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              name: data.account.name || "",
              status: data.account.status || "",
              salesforceAccountId: data.account.salesforceAccountId || "",
              quickbooksCustomerId: data.account.quickbooksCustomerId || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              status: Yup.string().required("Required"),
            })}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />

                <TextField name="name" label="Account Name" />

                <div className="mt-3">
                  <TextField
                    name="salesforceAccountId"
                    label="Salesforce Acct ID"
                  />
                </div>

                <div className="mt-3">
                  <TextField
                    name="quickbooksCustomerId"
                    label="Quickbooks Cust. ID"
                  />
                </div>

                <div className="mt-5 flex justify-end">
                  <span className="rounded-md shadow-sm">
                    <Button type="button" color="gray" onClick={onClose}>
                      Cancel
                    </Button>
                  </span>
                  <span className="rounded-md shadow-sm ml-2">
                    <Button type="submit" color="blue" disabled={isSubmitting}>
                      Update
                    </Button>
                  </span>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
