import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import "fontsource-inter/latin.css";
import "./styles/tailwind.dist.css";
import { EnvironmentNotice } from "@preferral/ui";
import App from "./App";
import { AppProviders } from "./context";
import * as serviceWorker from "./serviceWorker";
import createIconLibrary from "./createIconLibrary";
import { ENV } from 'config/constants';

createIconLibrary();

ReactModal.setAppElement("#root");

ReactDOM.render(
  <AppProviders>
    <App />
    <EnvironmentNotice isProduction={ENV === "PROD"} />
  </AppProviders>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
