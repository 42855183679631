import React, { FC } from "react";
import { Field, useFormikContext } from "formik";
import {
  FAIcon,
  SelectInput,
  TextInput,
  PhoneMaskInput,
  DateMaskInput,
  NumberInput,
} from "@preferral/ui";
import {
  ConditionalModel,
  OperatorModel,
  QuestionnaireModel,
  QuestionModel,
  getQuestion
} from "components/Questionnaire";
import { ProviderSelectInput } from "components/formik/ProviderSelectField";
import { EditValueSource } from "./EditValueSource";

const mapping: { [x in QuestionModel["kind"]]: string[] } = {
  address: ["includes", "does not include"],
  checkboxes: ["==", "!=", "includes", "does not include"],
  date: ["==", "!=", "<", "<=", ">", ">="],
  dropdown: ["==", "!=", "includes", "does not include"],
  number: ["==", "!=", "<", "<=", ">", ">="],
  email: ["==", "!=", "starts with", "ends with", "includes", "does not include"],
  phone_number: ["==", "!=", "starts with", "ends with", "includes", "does not include"],
  preferred_provider: ["==", "!="],
  radio: ["==", "!="],
  text: ["==", "!=", "includes", "does not include"],
  tooth_selector: ["==", "!=", "includes", "does not include"],
  signature: ["is present"],
  medication_list: ["includes", "does not include"],
};

function operatorOptionsForQuestionType(
  questionType: QuestionModel["kind"]
): OperatorOption[] {
  const operatorsForQuestionType = mapping[questionType];
  return operatorOptions.filter((o) =>
    operatorsForQuestionType.includes(o.value)
  );
}

type OperatorOption = {
  value: OperatorModel;
  label: string;
};

const operatorOptions: OperatorOption[] = [
  { value: "==", label: "is" },
  { value: "!=", label: "is not" },
  { value: "includes", label: "includes" },
  { value: "does not include", label: "doesn't include" },
  { value: "<", label: "is less than" },
  { value: "<=", label: "is less than or equal to" },
  { value: ">", label: "is greater than" },
  { value: ">=", label: "is greater than or equal to" },
];

/**
 * EditCompareValueForQuestion.
 */

interface EditCompareValueForQuestionProps {
  name: string;
  question: QuestionModel;
}

const EditCompareValueForQuestion: FC<EditCompareValueForQuestionProps> = props => {
  const { name, question } = props;

  switch (question.kind) {
    case "phone_number": {
      return <PhoneMaskInput name={name} />;
    }
    case "date": {
      return <DateMaskInput name={name} placeholder="mm/dd/yyyy" />;
    }
    case "number": {
      return <NumberInput name={name} />;
    }
    case "preferred_provider": {
      return <Field name={name} component={ProviderSelectInput} />;
    }
    case "email":
    case "tooth_selector":
    case "address":
    case "medication_list":
    case "text": {
      return <TextInput name={name} />;
    }
    case "checkboxes":
    case "dropdown":
    case "radio": {
      const options = question.options.map((o) => ({ value: o, label: o }));
      return <SelectInput name={name} options={options} />;
    }
    case "signature":
      return null;
  }
};

/**
 * EditOperationForQuestion.
 */

interface EditOperationForQuestionProps {
  prefix: string;
  namePrefix: string;
  conditional: ConditionalModel;
}

const EditOperationForQuestion: FC<EditOperationForQuestionProps> = props => {
  const { prefix, namePrefix, conditional } = props;

  const { values } = useFormikContext<QuestionnaireModel>();

  // NB: If there's no selected question, return null.
  if (!conditional.valueSource.meta.questionId) {
    return null;
  }

  const questionnaire = values[prefix];

  const question = getQuestion(
    conditional.valueSource.meta.questionId,
    questionnaire
  );

  if (!question) {
    return <p>Question not found.</p>;
  }

  const operatorOptions = operatorOptionsForQuestionType(question.kind);
  return (
    <div className="flex">
      <div className="ml-1 w-48 text-sm">
        <SelectInput
          name={`${namePrefix}.operator`}
          options={operatorOptions}
        />
      </div>
      {!!conditional.operator ? (
        <div className="ml-1 w-48 text-sm">
          <EditCompareValueForQuestion
            name={`${namePrefix}.compareValue`}
            question={question}
          />
        </div>
      ) : null}
    </div>
  );
};

/**
 * EditOperation.
 */

interface EditOperationProps {
  prefix: string;
  namePrefix: string;
  conditional: ConditionalModel;
}

const EditOperation: FC<EditOperationProps> = props => {
  const { prefix, namePrefix, conditional } = props;

  if (!(conditional.valueSource && conditional.valueSource.source)) {
    return null;
  }

  if (conditional.valueSource.source === "QUESTIONNAIRE") {
    return (
      <EditOperationForQuestion
        prefix={prefix}
        namePrefix={namePrefix}
        conditional={conditional}
      />
    );
  } else if (conditional.valueSource.source === "OTHER_SOURCE???") {
    return <p>Edit Other Source</p>;
  } else {
    return null;
  }
};

/**
 * EditConditional.
 */

interface EditConditionalProps {
  prefix: string;
  namePrefix: string;
  conditional: ConditionalModel;
  onDelete(): void;
};

export const EditConditional: FC<EditConditionalProps> = props => {
  const {
    prefix,
    onDelete,
    conditional,
    namePrefix,
  } = props;

  return (
    <div className="EditConditional flex mt-1">
      <div className="text-sm">
        <EditValueSource
          prefix={prefix}
          namePrefix={`${namePrefix}.valueSource`}
          conditional={conditional}
        />
      </div>
      <div>
        <EditOperation
          prefix={prefix}
          namePrefix={namePrefix}
          conditional={conditional}
        />
      </div>
      {/* <div className="ml-1 w-48 text-sm">
        <EditOperator
          name={`${namePrefix}.operator`}
          conditional={conditional}
        />
      </div>
      <div className="ml-1">
        <EditCompareValue
          name={`${namePrefix}.compareValue`}
          conditional={conditional}
        />
      </div> */}
      <button
        type="button"
        className="ml-2 btn btn-red-alt w-10"
        onClick={onDelete}
      >
        <FAIcon icon="trash" />
      </button>
    </div>
  );
};
