import React, { FC, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import Select, { ValueType } from "react-select";
import { QuestionnaireModel, ConditionalModel, SectionModel } from "components/Questionnaire";
import {
  Dropdown,
  DropdownTrigger,
  DropdownContent,
} from "components/Dropdown";

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const groupBadgeStyles: any = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

/**
 * QuestionSelect.
 */

interface QuestionSelectProps {
  prefix: string;
  name: string
}

// TODO: Extract this out into a GroupedSelectField formik component.
const QuestionSelect: FC<QuestionSelectProps> = props => {
  const {
    prefix,
    name,
  } = props;

  const [field] = useField(name);
  const {
    values,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<QuestionnaireModel>();

  const onChange = useCallback(
    (value: ValueType<Option, false>) => {
      setFieldValue(field.name, value?.value);
    },
    [field.name, setFieldValue]
  );

  const onBlur = useCallback(() => {
    // HACK: setFieldTouched's type is incorrect for nested fields
    setFieldTouched(field.name as "id", true);
  }, [field.name, setFieldTouched]);

  const groupedOptions = (values[prefix].sections as SectionModel[]).map(
    (section) => ({
      label: section.title,
      options: section.questions.map((question) => ({
        value: question.id,
        label: question.prompt,
      })),
    })
  );

  let selected = null;
  groupedOptions.find((g) =>
    g.options.find((o) => {
      if (o.value === field.value) {
        selected = o;
        return true;
      }
      return false;
    })
  );

  return (
    <Select<Option>
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      onChange={onChange}
      onBlur={onBlur}
      value={selected}
    />
  );
};

/**
 * EditValueSource.
 */

interface EditValueSourceProps {
  prefix: string;
  namePrefix: string;
  conditional: ConditionalModel;
};

export const EditValueSource: FC<EditValueSourceProps> = props => {
  const {
    prefix,
    namePrefix,
    conditional,
  } = props;

  const [field] = useField(`${namePrefix}.source`);

  return (
    <div className="EditValueSource flex">
      <div>
        <Dropdown>
          <DropdownTrigger className="p-2">
            {conditional.valueSource.source === "QUESTIONNAIRE" ? (
              <p>The answer to:</p>
            ) : (
              <p>UNKNOWN</p>
            )}
          </DropdownTrigger>
          <DropdownContent className="absolute">
            <div className="bg-white border rounded shadow p-2">
              <select {...field}>
                <option value="QUESTIONNAIRE" label="Question Answer:" />
                <option value="PATIENT_INFO" label="Patient Info:" />
              </select>
            </div>
          </DropdownContent>
        </Dropdown>
      </div>
      {conditional.valueSource.source === "QUESTIONNAIRE" ? (
        <div className="w-48">
          <QuestionSelect
            prefix={prefix}
            name={`${namePrefix}.meta.questionId`}
          />
        </div>
      ) : null}
    </div>
  );
};
