import React, { FC } from "react";

interface UserOverviewProps {
  userId: string;
}

export const UserOverview: FC<UserOverviewProps> = (props) => {
  const { userId } = props;
  return (
    <div className="UserOverview">
      <p>UserOverview</p>
      <p>{userId}</p>
    </div>
  );
};
