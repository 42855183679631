import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { StandardOption, SelectField, Button, Spinner } from "@preferral/ui";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { ProviderSearchSelectField } from "components/formik/ProviderSearchField";

const NETWORK_SUBGROUPS = gql`
  query GetNetworkSubgroups($networkId: UUID4!) {
    network(id: $networkId) {
      id
      networkSubgroups {
        id
        name
      }
    }
  }
`;

interface Data {
  network: {
    id: string;
    networkSubgroups: {
      id: string;
      name: string;
    }[];
  };
}

interface Variables {
  networkId: string;
}

const ADD_NETWORK_PROVIDER = gql`
  mutation MutationName($input: SomeInput!) {
    addNetworkProviderMembership(input: $input) {
      errors {
        key
        message
      }
      network {
        id
      }
    }
  }
`;

interface MutationData {
  addNetworkProviderMembership: {
    errors?: InputError[];
    network?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: any;
}

interface FormValues {
  providerId: string;
  networkSubgroupId: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    providerId: Yup.string().required("Required"),
    networkSubgroupId: Yup.string().required("Required"),
  })
  .required();

interface AddNetworkProviderFormProps {
  networkId: string;
  onSuccess(): void;
}

export const AddNetworkProviderForm: FC<AddNetworkProviderFormProps> = (
  props
) => {
  const { networkId, onSuccess } = props;
  const { data, loading, error } = useQuery<Data, Variables>(
    NETWORK_SUBGROUPS,
    { variables: { networkId } }
  );

  const networkSubgroupOptions: StandardOption[] =
    data?.network.networkSubgroups.map((ns) => ({
      value: ns.id,
      label: ns.name,
    })) || [];

  const [addNetworkProvider] = useMutation<MutationData, MutationVariables>(
    ADD_NETWORK_PROVIDER
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await addNetworkProvider({
          variables: { input: values },
        });

        if (data?.addNetworkProviderMembership.errors) {
          setStatus({ errors: data.addNetworkProviderMembership.errors });
        } else if (data?.addNetworkProviderMembership.network) {
          // it worked...
          toast.success("Success!");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [addNetworkProvider, onSuccess]
  );
  return (
    <div>
      {loading ? (
        <div className="p-6 text-center">
          <Spinner />
        </div>
      ) : error || !data?.network ? (
        <p>Failed to load.</p>
      ) : (
        <Formik<FormValues>
          initialValues={{
            providerId: "",
            networkSubgroupId:
              networkSubgroupOptions.length === 1
                ? networkSubgroupOptions[0].value
                : "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, status }) => (
            <form onSubmit={handleSubmit}>
              <FormStatusErrors status={status} />
              <div className="mt-3">
                <ProviderSearchSelectField
                  name="providerReference"
                  label="Provider"
                  placeholder="Search for provider..."
                />
              </div>

              <div className="mt-3">
                <SelectField
                  name="networkSubgroupId"
                  label="Network Subgroup"
                  options={networkSubgroupOptions}
                />
              </div>

              <div className="flex items-center justify-center mt-3">
                <Button
                  type="submit"
                  kind="primary"
                  color="blue"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Add Network Provider
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
