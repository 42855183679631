import React, { FC, useState, useCallback } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  ImageObject,
  DetailList,
  DetailItem,
  Spinner,
  CopyText,
  FAIcon,
  Button,
} from "@preferral/ui";
import { lightFormat, parseISO } from "date-fns";

const NPPES_PROVIDER = gql`
  query FetchNPPESProvider($id: UUID4!) {
    nppesProvider(id: $id) {
      id
      npi
      prefix
      firstName
      middleInitial
      lastName
      suffix
      credentialText
      gender
      nameWithAppellation
      nppesUpdatesLocked
      converted
      primarySpecialty {
        id
        name
        taxonomyCode
        parentSpecialty {
          id
          name
          ageClassification
          curated
          isCustom
          taxonomyCode
        }
      }
      specialties {
        id
        name
        ageClassification
        curated
        isCustom
        taxonomyCode
        parentSpecialty {
          id
          name
          ageClassification
          curated
          isCustom
          taxonomyCode
        }
      }
      avatar(size: "small") {
        base64
        src
        srcSet
      }
    }
  }
`;

interface Data {
  nppesProvider: NPPESProvider;
}

interface NPPESProvider {
  id: string;
  npi: string;
  prefix?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  suffix?: string;
  credentialText?: string;
  gender?: string;
  nameWithAppellation: string;
  primarySpecialty?: Specialty;
  specialties: Specialty[];
  nppesUpdatesLocked: boolean;
  converted: boolean;
  avatar: ImageObject;
}

interface Specialty {
  id: string;
  name: string;
  ageClassification?: string;
  curated: boolean;
  isCustom: boolean;
  taxonomyCode?: string;
  parentSpecialty?: Specialty;
}

/**
 * SpecialtyCard.
 */

interface SpecialtyCardProps {
  isPrimary: boolean;
  specialty: Specialty;
}

const SpecialtyCard: FC<SpecialtyCardProps> = (props) => {
  const { isPrimary, specialty } = props;

  return (
    <div className="_SpecialtyCard p-2 border rounded-md shadow relative">
      <div className="_curation-indicator absolute inset-1 right-1 text-right">
        {specialty.isCustom ? (
          <p className="text-xs font-semibold">Custom</p>
        ) : specialty.curated ? (
          <p className="text-xs font-semibold">Curated</p>
        ) : (
          <p className="text-xs font-semibold">Not Curated</p>
        )}
      </div>
      {specialty.parentSpecialty &&
        specialty.parentSpecialty.id !== specialty.id ? (
        <p className="text-xs">{specialty.parentSpecialty.name}:</p>
      ) : null}
      <p className="text-gray-800 font-semibold">{specialty.name}</p>
      <div className="flex justify-around">
        {isPrimary ? (
          <p className="text-sm">
            ⭐ <span className="font-semibold text-yellow-400">Primary</span>
          </p>
        ) : null}
        <p>{specialty.ageClassification}</p>
        <p className="italic text-cool-gray-500 text-sm">
          {specialty.taxonomyCode}
        </p>
      </div>
    </div>
  );
};

type ActiveModal = "EDIT_NPPES_PROVIDER";

interface NPPESProviderDetailsProps {
  nppesProviderId: string;
}

export const NPPESProviderDetails: FC<NPPESProviderDetailsProps> = (props) => {
  const { nppesProviderId } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const { data, loading, error } = useQuery<Data>(NPPES_PROVIDER, {
    variables: { id: nppesProviderId },
  });

  const refetchQueries = [
    { query: NPPES_PROVIDER, variables: { id: nppesProviderId } },
  ];

  return (
    <>
      <div className="_NPPESProviderDetails container mx-auto bg-gray-100 mt-4 md:p-4 rounded-2xl text-left">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.nppesProvider ? (
          <p>Failed to load</p>
        ) : (
          <div className="md:px-4 grid lg:grid-cols-3 gap-4">
            <div className="rounded-lg shadow-lg p-4 bg-white">
              <div className="flex justify-between items-center">
                <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8 truncate">
                  Provider Info
                </h4>
                <Button
                  kind="tertiary"
                  size="xs"
                  color="indigo"
                  onClick={() => setActiveModal("EDIT_NPPES_PROVIDER")}
                >
                  <span className="mr-2">
                    <FAIcon icon="pencil-alt" />
                  </span>{" "}
                  Edit Info
                </Button>
              </div>
              <div className="py-6">
                <DetailList>
                  <DetailItem label="ID">
                    <p className="text-xs text-cool-gray-500">
                      <CopyText
                        text={data.nppesProvider.id}
                        label={data.nppesProvider.id}
                      />
                    </p>
                  </DetailItem>
                  <DetailItem label="NPI">
                    <p className="text-xs text-cool-gray-500">
                      <CopyText
                        text={data.nppesProvider.npi}
                        label={data.nppesProvider.npi}
                      />
                    </p>
                  </DetailItem>
                  <DetailItem label="Name">
                    <p>{data.nppesProvider.nameWithAppellation}</p>
                  </DetailItem>
                  <DetailItem label="Gender">
                    <p>{data.nppesProvider.gender || "-"}</p>
                  </DetailItem>
                  <DetailItem label="Specialties">
                    {data.nppesProvider.specialties.length === 0
                      ? "-"
                      : combinedSpecialties(
                        data.nppesProvider.primarySpecialty,
                        data.nppesProvider.specialties
                      ).map((s) => (
                        <div className="mb-1" key={s.specialty.id}>
                          <SpecialtyCard
                            isPrimary={s.isPrimary}
                            specialty={s.specialty}
                          />
                        </div>
                      ))}
                  </DetailItem>
                </DetailList>
              </div>
            </div>
            <div className="rounded-lg shadow-lg p-4 bg-white">
              <div className="flex justify-between items-center">
                <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8">
                  TODO
                </h4>
              </div>
              <div className="py-6"></div>
            </div>
            <div className="rounded-lg shadow-lg p-4 bg-white">
              <div className="flex justify-between items-center">
                <h4 className="font-bold text-cool-gray-500 text-sm uppercase leading-8">
                  TODO
                </h4>
                <Button kind="tertiary" size="xs" color="indigo">
                  <span className="mr-2">
                    <FAIcon icon="pencil-alt" />
                  </span>{" "}
                  Edit TODO
                </Button>
              </div>
              <div className="py-6"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function combinedSpecialties(
  primarySpecialty: Specialty | undefined,
  specialties: Specialty[]
): { isPrimary: boolean; specialty: Specialty }[] {
  return [...specialties]
    .sort((a, b) => (a.name <= b.name ? -1 : 1))
    .map((s) => ({ isPrimary: s.id === primarySpecialty?.id, specialty: s }));
}
