import { Modal, ModalHeader } from "components/Modal";
import { FC } from "react";
import { NewNPIAvatarForm } from "./NewNPIAvatarForm";

interface NewNPIAvatarModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
};

export const NewNPIAvatarModal: FC<NewNPIAvatarModalProps> = props => {
  const { isOpen, onClose, onSuccess } = props;
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="user-md" onClose={onClose} title="New NPI Avatar" />

      <div className="p-4">
        <NewNPIAvatarForm onSuccess={onSuccess} />
      </div>
    </Modal>
  );
};
