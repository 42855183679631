import { FC, useState, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { FilterPanel, Filter, defaultFilter } from "./FilterPanel";
import { Modal, ModalHeader } from "components/Modal";
import { EditNPPESProviderMappingsForm } from "./EditNPPESProviderMappingsForm";
import { ScreenTitle } from "context/ScreenTitle";
import {
  Spinner,
  FAIcon,
  TableContainer,
  Table,
  TH,
  TD,
  Avatar,
  ImageObject,
  DropdownButton,
  DropdownItemButton,
  VerticalField,
  Button
} from "@preferral/ui";
import { NoResults } from "components/NoResults";
import { RemoveNPPESProviderForm } from "./RemoveNPPESProviderForm";
import { ZERO_WIDTH_SPACE } from "@preferral/common";
import { AddProviderModal } from "./AddProviderModal";

const PAGE_SIZE = 30;

const LIST_NPPES_ORG_PROVIDERS = gql`
  query ListNPPESOrgProviders(
    $first: Int
    $after: UUID4
    $filter: ListNppesProvidersFilter
  ) {
    nppesProviders(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        npi
        nameWithAppellation
        avatar(size: "small") {
          base64
          src
          srcSet
        }
        specialties {
          id
          name
        }
        locations {
          id
          name
          city
        }
      }
    }
  }
`;

interface NPPESProvider {
  id: string;
  npi: string;
  nameWithAppellation: string;
  avatar: ImageObject;
  specialties: {
    id: string;
    name: string;
  }[];
  locations: {
    id: string;
    name: string;
    city: string;
  }[];
}

interface Data {
  nppesProviders: Paginated<NPPESProvider>;
}

const REMOVE_PROVIDER_EMPLOYMENT = gql`
  mutation RemoveNPPESProviderEmployment(
    $nppesProviderId: UUID4!
    $nppesOrganizationId: UUID4!
  ) {
    removeNppesProviderEmployment(
      nppesOrganizationId: $nppesOrganizationId
      nppesProviderId: $nppesProviderId
    ) {
      errors {
        key
        message
      }
      nppesProvider {
        id
      }
    }
  }
`;

interface MutationData {
  removeNppesProviderEmployment: {
    errors?: InputError[];
    nppesProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  nppesProviderId: string;
  nppesOrganizationId: string;
}

type ActiveModal =
  | "ADD"
  | "NEW"
  | "EDIT"
  | { modal: "EDIT_MAPPINGS"; nppesProviderId: string }
  | { modal: "REMOVE_PROVIDER"; nppesProviderId: string };

interface NPPESOrganizationProvidersProps {
  nppesOrganizationId: string;
}

export const NPPESOrganizationProviders: FC<NPPESOrganizationProvidersProps> = (
  props
) => {
  const { nppesOrganizationId } = props;
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const openAddModal = useCallback(() => {
    setActiveModal("ADD");
  }, [setActiveModal]);

  const openNewModal = useCallback(() => {
    setActiveModal("NEW");
  }, [setActiveModal]);

  const openEditModal = useCallback(() => {
    setActiveModal("EDIT");
  }, [setActiveModal]);

  const openEditMappingsModal = useCallback(
    (nppesProviderId: string) => {
      setActiveModal({ modal: "EDIT_MAPPINGS", nppesProviderId });
    },
    [setActiveModal]
  );

  const openRemoveModal = useCallback(
    (nppesProviderId: string) => {
      setActiveModal({ modal: "REMOVE_PROVIDER", nppesProviderId });
    },
    [setActiveModal]
  );

  const closeModal = useCallback(() => {
    setActiveModal(null);
  }, [setActiveModal]);

  const [filter, setFilter] = useState<Filter>(defaultFilter);

  const { loading, data, error, refetch } = useQuery<Data>(LIST_NPPES_ORG_PROVIDERS, {
    variables: {
      first: PAGE_SIZE,
      filter: {
        ...filter,
        nppesOrganizationId
      },
    },
  });

  const [removeEmploymentMut] = useMutation<MutationData, MutationVariables>(
    REMOVE_PROVIDER_EMPLOYMENT
  );

  const removeEmployment = useCallback(
    (organizationId: string, providerId: string) => {
      return removeEmploymentMut({
        variables: {
          nppesOrganizationId: organizationId,
          nppesProviderId: providerId,
        },
        refetchQueries: [
          {
            query: LIST_NPPES_ORG_PROVIDERS,
            variables: {
              first: PAGE_SIZE,
              filter,
            },
          },
        ],
      });
    },
    [removeEmploymentMut]
  );

  return (
    <>
      <ScreenTitle title="NPPES Organizations » View Organization » Providers" />
      <AddProviderModal
        isOpen={activeModal === "ADD"}
        onClose={closeModal}
        onSuccess={refetch}
        nppesOrganizationId={nppesOrganizationId}
      />
      <div className="NPPESOrganizationProviders bg-gray-100 mt-4 p-4 rounded-2xl text-left">

        <Modal
          isOpen={
            typeof activeModal !== "string" &&
            activeModal?.modal === "REMOVE_PROVIDER"
          }
          onRequestClose={closeModal}
        >
          <ModalHeader
            icon="user-doctor"
            color="red"
            title="Remove Provider"
            onClose={closeModal}
          />
          <div className="px-8 pt-6 pb-8 flex flex-col">
            {typeof activeModal !== "string" && !!activeModal?.nppesProviderId && (
              <RemoveNPPESProviderForm
                nppesOrganizationId={nppesOrganizationId}
                nppesProviderId={activeModal.nppesProviderId}
                refetchQueries={[
                  {
                    query: LIST_NPPES_ORG_PROVIDERS,
                    variables: {
                      first: PAGE_SIZE,
                      filter,
                    },
                  },
                ]}
                closeModal={closeModal}
              />
            )}
          </div>
        </Modal>

        <Modal
          isOpen={
            typeof activeModal !== "string" &&
            activeModal?.modal === "EDIT_MAPPINGS"
          }
          onRequestClose={closeModal}
          className="max-w-4xl"
        >
          <ModalHeader
            icon="map-marker-alt"
            title="Update Locations"
            onClose={closeModal}
          />
          <div className="px-8 pb-8 flex flex-col">
            {typeof activeModal !== "string" &&
              !!activeModal?.nppesProviderId && (
                <EditNPPESProviderMappingsForm
                  nppesProviderId={activeModal.nppesProviderId}
                  nppesOrganizationId={nppesOrganizationId}
                />
              )}
          </div>
        </Modal>
        <div>
          <div>
            <div className="flex justify-between px-3 pb-4">

              <FilterPanel
                value={filter}
                onChange={setFilter}
                isLoading={loading}
                nppesOrganizationId={nppesOrganizationId}
              />

              <VerticalField label={ZERO_WIDTH_SPACE}>
                <Button color="blue" onClick={openAddModal}>
                  <span className="mr-2">
                    <FAIcon icon="plus" />
                  </span>
                  Add Provider
                </Button>
              </VerticalField>
            </div>

            {loading ? (
              <div className="p-12 text-center">
                <Spinner />
              </div>
            ) : error || !(data && data.nppesProviders) ? (
              <p>Failed to load.</p>
            ) : data.nppesProviders.items.length === 0 ? (
              <NoResults icon="user-doctor" text="No Providers" />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH />
                      <TH>Name</TH>
                      <TH>Locations</TH>
                      <TH />
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.nppesProviders.items.map((provider) => (
                      <tr key={provider.id}>
                        {/* <TD><Avatar size="sm" image={provider.avatar</TD> */}
                        <TD className="w-10">
                          <Avatar size="sm" image={provider.avatar} />
                        </TD>
                        <TD className="px-1">
                          <p className="font-black text-gray-800 text-lg">
                            {provider.nameWithAppellation}
                          </p>
                          <p className="leading-none text-gray-600 text-xs italic">
                            NPI: {provider.npi}
                          </p>
                          <p>
                            {provider.specialties.map((s) => s.name).join(", ")}
                          </p>
                        </TD>
                        <TD>
                          {provider.locations.length === 0 ? (
                            <p className="text-xs text-gray-500 italic">
                              No locations
                            </p>
                          ) : (
                            <ul className="font-medium text-blue-500 text-xs">
                              {provider.locations.map((loc) => (
                                <li key={loc.id}>
                                  <Link to={`/${loc.id}`}>
                                    {loc.name} ({loc.city})
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </TD>
                        <TD>
                          <DropdownButton label="Actions">
                            <DropdownItemButton
                              onClick={() => openEditMappingsModal(provider.id)}
                            >
                              Edit Locations
                            </DropdownItemButton>
                            <DropdownItemButton
                              color="red"
                              onClick={() => openRemoveModal(provider.id)}
                            >
                              Remove Provider
                            </DropdownItemButton>
                          </DropdownButton>
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
