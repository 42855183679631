import { SignInScreen } from "./screens/SignInScreen/SignInScreen";

interface UnauthenticatedAppProps { };

export const UnauthenticatedApp: React.FC<UnauthenticatedAppProps> = () => {
  return (
    <div className="UnauthenticatedApp w-screen h-screen">
      <SignInScreen />
    </div>
  );
};
