import React, { useContext, useState, useRef } from "react";
import cx from "classnames";
import useOnClickOutside from "use-onclickoutside";

type DropdownContextState = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
];

const DropdownContext = React.createContext<DropdownContextState>([
  false,
  () => null
]);

function useDropdown() {
  return useContext(DropdownContext);
}

type DropdownProps = {
  className?: string;
  isOpen?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  className,
  isOpen: propsOpen = false,
  children
}) => {
  const [isOpen, setIsOpen] = useState(propsOpen);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownContext.Provider value={[isOpen, setIsOpen]}>
      <div ref={ref} className={cx("relative", className)}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

const DropdownTrigger: React.FC<{ className?: string }> = ({
  className,
  children
}) => {
  const [isOpen, setIsOpen] = useDropdown();

  return (
    <div className={className} onClick={() => setIsOpen(!isOpen)}>
      {children}
    </div>
  );
};

const DropdownContent: React.FC<{ className?: string }> = ({
  className,
  children
}) => {
  // eslint-disable-next-line
  const [isOpen, _setIsOpen] = useDropdown();

  return (
    <div className={cx("DropdownContent", className)}>
      {isOpen ? <>{children}</> : null}
    </div>
  );
};

export { Dropdown, DropdownTrigger, DropdownContent, useDropdown };
