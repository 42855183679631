import React, { useState, FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import {
  Spinner,
  TableContainer,
  Table,
  TH,
  TD,
  FAIcon,
  DropdownButton,
  DropdownItemButton,
} from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { ImpersonateUserModal } from "components/ImpersonateUserModal";
import { UserStatusModel, defaultFilter, FilterPanel, Filter } from "./FilterPanel";
import { EditUserModal } from "components/EditUserModal";
import { UnlockUserModal } from "components/UnlockUserModal";

const LIST_USERS = gql`
  query ListUsers($first: Int, $after: UUID4, $filter: ListUsersFilter) {
    users(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        firstName
        lastName
        email
        insertedAt
        lockedFromFailedLoginAttempts
        organization {
          id
          name
        }
      }
    }
  }
`;

interface Data {
  users: Paginated<UserModel>;
}

interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  insertedAt: string;
  lockedFromFailedLoginAttempts: boolean;
  organization: {
    id: string;
    name: string;
  }
}

/**
 * Badge.
 */

interface BadgeProps {
  color: AppColor;
  className?: string;
}

const Badge: FC<BadgeProps> = (props) => {
  const { color, children } = props;

  return (
    <p
      className={`bg-${color}-100 border border-${color}-200 text-${color}-600 capitalize font-semibold inline-block leading-none ml-2 px-2 py-1 rounded-full text-xs`}
    >
      {children}
    </p>
  );
};

/**
 * UserStatusBadge
 */
export interface UserStatusBadgeProps {
  status: string;
}

export const UserStatusBadge: FC<UserStatusBadgeProps> = (props) => {
  const { status } = props;
  const colorForStatus: Record<UserStatusModel, AppColor> = {
    trial: "blue",
    active: "green",
  };
  return <Badge color={colorForStatus[status] || "blue"}>{status}</Badge>;
};

type ActiveModal =
  | { name: "IMPERSONATE_USER"; userId: string }
  | { name: "EDIT_USER"; userId: string }
  | { name: "UNLOCK_USER"; userId: string };

interface UsersScreenProps { }

export const UsersScreen: React.FC<UsersScreenProps> = () => {
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const { loading, data, error, refetch } = useQuery<Data>(LIST_USERS, {
    variables: { filter },
  });

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  function closeModal() {
    setActiveModal(null);
  }

  function openImpersonateUserModal(userId: string) {
    setActiveModal({ name: "IMPERSONATE_USER", userId });
  }

  function openEditUserModal(userId: string) {
    setActiveModal({ name: "EDIT_USER", userId });
  }

  function openUnlockUserModal(userId: string) {
    setActiveModal({ name: "UNLOCK_USER", userId });
  }

  return (
    <div className="UsersScreen">
      <ScreenTitle title="Users" />

      {activeModal?.userId ? (
        <>
          <ImpersonateUserModal
            isOpen={activeModal.name === "IMPERSONATE_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
          />
          <EditUserModal
            isOpen={activeModal.name === "EDIT_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
            onSuccess={() => {
              refetch();
              closeModal();
            }}
          />
          <UnlockUserModal
            isOpen={activeModal.name === "UNLOCK_USER"}
            onClose={closeModal}
            userId={activeModal.userId}
            onSuccess={() => {
              refetch();
              closeModal();
            }}
          />
        </>
      ) : null}
      <div className="container mx-auto">
        <div
          spec-id="accounts-table-control-panel"
          className="flex justify-between p-4"
        >
          <FilterPanel value={filter} onChange={setFilter} isLoading={loading} />
          <div>{/* Maybe add a "Create Account" button here? */}</div>
        </div>

        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.users ? (
          <p>Failed to load</p>
        ) : (
          <div className="pb-8 lg:px-4">
            <TableContainer>
              <Table>
                <thead>
                  <tr className="text-sm">
                    <TH className="px-3 py-2">User</TH>
                    <TH>Organization</TH>
                    {/* <TH>Tags</TH> */}
                    <TH>CSM</TH>
                    {/* <TH>Trial Ends At</TH> */}
                    <TH />
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data.users.items.map((user) => (
                    <tr key={user.id}>
                      <TD>
                        <div className="flex items-center">
                          <Link
                            to={`/admin/users/${user.id}`}
                            className="font-bold text-blue-700"
                          >
                            {user.firstName} {user.lastName}
                          </Link>
                          <UserStatusBadge status={"active"} />
                          {
                            user.lockedFromFailedLoginAttempts ? (
                              <Badge color="red" className="mr-2">Locked</Badge>
                            ) : null
                          }
                        </div>
                        <p className="leading-tight pt-1 text-gray-500 text-xs">
                          <span className="mr-2 text-gray-400">
                            <FAIcon icon="envelope" />
                          </span>
                          {user.email}
                        </p>
                      </TD>
                      <TD>
                        <Link
                          to={`/admin/organizations/${user.organization.id}`}
                          className="text-blue-600 hover:text-blue-700"
                        >
                          {user.organization.name}
                        </Link>
                        {/* <ul>
                          {account.accountTags.map((tag) => (
                            <li
                              id={tag.id}
                              style={{ backgroundColor: tag.color }}
                            >
                              {tag.label}
                            </li>
                          ))}
                        </ul> */}
                      </TD>
                      {/* <TD>Pro</TD> */}
                      <TD>
                        {/* {account.csm ? (
                          <div>
                            {account.csm.firstName} {account.csm.lastName}
                          </div>
                        ) : (
                          <p className="text-gray-400">Not assigned</p>
                        )} */}
                      </TD>
                      {/* <TD>
                        {format(parseISO(user.insertedAt), "yyyy-MM-dd")}
                        <p>{formatDistance(parseISO(user.insertedAt), now)}</p>
                      </TD> */}
                      <TD>
                        <DropdownButton label="Actions">
                          <DropdownItemButton
                            onClick={() => openEditUserModal(user.id)}
                          >
                            Edit User
                          </DropdownItemButton>
                          <DropdownItemButton
                            onClick={() => openImpersonateUserModal(user.id)}
                          >
                            Impersonate User
                          </DropdownItemButton>
                          {
                            user.lockedFromFailedLoginAttempts ? (
                              <DropdownItemButton
                                onClick={() => openUnlockUserModal(user.id)}
                              >
                                Unlock User
                              </DropdownItemButton>
                            ) : null
                          }
                        </DropdownButton>
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {data.users.items.length > 0 && data.users.endOfList ? (
                <div className="bg-white border-gray-300 border-t p-2 text-center text-gray-500">
                  <p>End of List</p>
                </div>
              ) : null}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};
