import { FC, useCallback, useEffect, useState } from "react";
import { gql } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, TextMaskField, VerticalField, } from "@preferral/ui";
import { npiMask } from '../FilterPanel';
import toast from "react-hot-toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const CREATE_NPI_AVATAR = gql`
  mutation CreateNPIAvatar($input: AvatarInput!) {
    createNpiAvatar(input: $input) {
      errors {
        key
        message
      }
      avatar {
        id
      }
    }
  }
`;

interface MutationData {
  createNpiAvatar: {
    errors?: InputError[];
    avatar?: {
      id: string;
    }
  }
}

interface MutationVariables {
  input: FormValues;
}

/**
 * Preview.
 */

interface PreviewProps {
  file: any;
}

const Preview: FC<PreviewProps> = (props) => {
  const { file } = props;
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState<any>();

  useEffect(() => {
    if (file) {
      let reader = new FileReader();
      reader.onload = () => {
        setLoading(false);
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
      return () => reader.abort();
    }
    return () => { };
  }, [file, setLoading, setPreview]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <img
      src={preview}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={200}
      width={200}
    />
  );
};

/**
 * NewNPIAvatarForm.
 */

interface FormValues {
  npi: string;
  image: any;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    npi: Yup.string().required("Required"),
    image: Yup.mixed().required("Required"),
  })
  .required();

interface NewNPIAvatarFormProps {
  onSuccess(): void;
};

export const NewNPIAvatarForm: FC<NewNPIAvatarFormProps> = props => {
  const { onSuccess } = props;
  const [createNpiAvatar] = useMutation<MutationData, MutationVariables>(CREATE_NPI_AVATAR);

  const onSubmit = useCallback(async (values: FormValues, formikHelpers) => {
    const { setStatus, setSubmitting } = formikHelpers;
    setStatus(null);

    try {
      const { data } = await createNpiAvatar({ variables: { input: values } });

      if (data?.createNpiAvatar.errors) {
        setStatus({ errors: data.createNpiAvatar.errors });
      } else if (data?.createNpiAvatar.avatar) {
        // it worked...
        toast.success("NPI Avatar Created.")
        return onSuccess();
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong.")
    }
    setSubmitting(false);
  }, [createNpiAvatar, onSuccess]);

  return (
    <Formik<FormValues>
      initialValues={{ npi: "", image: null }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, status, values, setFieldValue }) => (
        <form className="_NewNPIAvatarForm" onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />

          <TextMaskField
            name="npi"
            label="NPI"
            mask={npiMask}
          />

          <div className="mt-3">
            <div className="mt-3">
              <VerticalField label="Upload New Logo">
                <input
                  id="image"
                  name="image"
                  type="file"
                  onChange={(event: any) => {
                    console.log(event)
                    setFieldValue("image", event.currentTarget.files[0]);
                  }}
                  className="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                />
                {values.image && (
                  <div className="mt-2">
                    <Preview file={values.image} />
                  </div>
                )}
              </VerticalField>
            </div>
          </div>

          <div className="mt-3 pt-3 text-center">
            <Button
              type="submit"
              kind="primary"
              color="blue"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>

  );
};
