import { FC, memo } from "react";
import { components, OptionProps, SingleValueProps } from "react-select";
import {
  TextInput,
  SelectInput,
  ToggleSwitchInput,
  PlainSelectInput,
  Icon,
  FAIcon
} from "@preferral/ui";
import { QuestionModel } from "components/Questionnaire";
import { ConfigMeta, OptionsMeta } from "./EditMeta";
import MailIcon from '@heroicons/react/outline/MailIcon';

interface EditQuestionProps {
  prefix: string;
  sectionIndex: number;
  index: number;
  question: QuestionModel;
  onDelete(): void;
  openEditingQuestionRule(sectionIdx: number, questionIdx: number): void;
};

interface QuestionTypeOption {
  value: QuestionModel["kind"];
  label: string;
};

const questionTypeOptions: QuestionTypeOption[] = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "dropdown",
    label: "Dropdown",
  },
  {
    value: "radio",
    label: "Picklist (Pick 1)",
  },
  {
    value: "checkboxes",
    label: "Picklist (Pick Multiple)",
  },
  {
    value: "number",
    label: "Number",
  },
  {
    value: "tooth_selector",
    label: "Tooth Selector",
  },
  {
    value: "email",
    label: "Email Address"
  },
  {
    value: "phone_number",
    label: "Phone Number",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "address",
    label: "Address",
  },
  {
    value: "preferred_provider",
    label: "Preferred Provider",
  },
  {
    value: "signature",
    label: "Signature",
  },
  {
    value: "medication_list",
    label: "Medication List",
  },
];

const QuestionTypeIcon: FC<{ questionType: QuestionModel["kind"] }> = ({
  questionType,
}) => {
  switch (questionType) {
    case "address":
      return <Icon icon="location-pin-alt" />;
    case "checkboxes":
      return <Icon icon="checkbox" />;
    case "date":
      return <Icon icon="date" />;
    case "dropdown":
      return <Icon icon="dropdown" />;
    case "number":
      return <Icon icon="number-sign" />;
    case "email":
      return <MailIcon className="h-6 w-6" />
    case "phone_number":
      return <Icon icon="phone" />;
    case "preferred_provider":
      return <Icon icon="user-md" />;
    case "radio":
      return <Icon icon="radio-button" />;
    case "text":
      return <Icon icon="text" />;
    case "tooth_selector":
      return <Icon icon="tooth" />;
    case "signature":
      return <Icon icon="signature" />;
    case "medication_list":
      return <Icon icon="medication" />;
  }
};

/**
 * QuestionMeta.
 */

interface QuestionMetaProps {
  prefix: string;
  sectionIndex: number;
  questionIndex: number;
  question: QuestionModel;
}

const QuestionMeta: FC<QuestionMetaProps> = props => {
  const { prefix, question, sectionIndex, questionIndex } = props;

  // if (["dropdown", "checkboxes", "radio"].includes(question.kind)) {
  return (
    <>
      <ConfigMeta
        prefix={prefix}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
        question={question}
      />
      <OptionsMeta
        prefix={prefix}
        sectionIndex={sectionIndex}
        questionIndex={questionIndex}
        question={question}
      />
    </>
  );
};

const Option: FC<OptionProps<QuestionTypeOption, false>> = memo(
  (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center">
          <QuestionTypeIcon questionType={props.data.value} />
          <div className="ml-2">
            <p>{props.data.label}</p>
          </div>
        </div>
      </components.Option>
    );
  }
);

const SingleValue: FC<SingleValueProps<QuestionTypeOption>> = memo(
  (props) => {
    const { children, ...rest } = props;

    return (
      <components.SingleValue {...rest}>
        <div className="flex items-center">
          <QuestionTypeIcon questionType={props.data.value} />
          <div className="ml-2">{children}</div>
        </div>
      </components.SingleValue>
    );
  })

const semanticOptions = [
  {
    value: "",
    label: "Select...",
  },
  {
    value: "patient.first_name",
    label: "Patient First Name",
  },
  {
    value: "patient.last_name",
    label: "Patient Last Name",
  },
];

export const EditQuestion: FC<EditQuestionProps> = memo(
  props => {
    const {
      prefix,
      sectionIndex,
      index,
      question,
      onDelete,
      openEditingQuestionRule,
    } = props;

    return (
      <div data-spect-id={question.id} className="EditQuestion p-4 rounded">
        <div className="flex justify-between">
          <div className="flex">
            <div className={question.kind === "signature" ? "flex-1" : ""}>
              <div>
                <label className="text-xs font-semibold text-gray-500">
                  Question Prompt
                </label>
                <div className="border-b border-gray-400">
                  <TextInput
                    name={`${prefix}.sections.${sectionIndex}.questions.${index}.prompt`}
                    className="border-0 font-bold text-lg"
                    icon="pencil-alt"
                  />
                </div>
              </div>
              <div>
                <PlainSelectInput
                  name={`${prefix}.sections.${sectionIndex}.questions.${index}.semanticMapping`}
                  options={semanticOptions}
                />
              </div>
              <div className="mt-6 text-center">
                <div className="flex items-center justify-center">
                  <p className="text-right text-xs w-24">Required?</p>
                  <div className="px-3  w-24">
                    <ToggleSwitchInput
                      name={`${prefix}.sections.${sectionIndex}.questions.${index}.required`}
                      toggleSwitchProps={{
                        size: 20,
                        fontSize: 12,
                        showLabel: true,
                        checkedLabel: "Yes",
                        uncheckedLabel: "No",
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2 flex items-center justify-center">
                  <p className="text-right text-xs w-24">Default Visible?</p>
                  <div className="px-3 w-24">
                    <ToggleSwitchInput
                      name={`${prefix}.sections.${sectionIndex}.questions.${index}.defaultVisible`}
                      toggleSwitchProps={{
                        size: 20,
                        fontSize: 12,
                        showLabel: true,
                        checkedLabel: "Yes",
                        uncheckedLabel: "No",
                      }}
                    />
                  </div>
                </div>
                {!question.defaultVisible ? (
                  <div className="mt-2 flex items-center justify-center">
                    <p className="text-right text-xs w-24">Print Visible?</p>
                    <div className="px-3 w-24">

                      <ToggleSwitchInput
                        name={`${prefix}.sections.${sectionIndex}.questions.${index}.forcePrintVisible`}
                        toggleSwitchProps={{
                          size: 20,
                          fontSize: 12,
                          showLabel: true,
                          checkedLabel: "Yes",
                          uncheckedLabel: "No",
                        }}
                      />
                    </div>
                  </div>
                ) : null}

                <button
                  type="button"
                  className="border border-white focus:outline-none focus:shadow-outline font-semibold hover:bg-gray-200 hover:border-gray-200 inline-block mt-2 mx-auto px-3 py-1 rounded text-purple-500 text-sm"
                  onClick={() => openEditingQuestionRule(sectionIndex, index)}
                >
                  {question.rules.length > 0 ? "Edit Rules" : "Add Rule"}
                </button>
              </div>
            </div>
            <div>
              <div className="ml-8 w-64">
                <label className="text-xs font-semibold text-gray-500">
                  Question Type
                </label>
                <div className="mt-1 text-lg">
                  <SelectInput
                    name={`${prefix}.sections.${sectionIndex}.questions.${index}.kind`}
                    options={questionTypeOptions}
                    components={{ Option, SingleValue }}
                  />
                </div>
                <div>
                  <QuestionMeta
                    prefix={prefix}
                    sectionIndex={sectionIndex}
                    questionIndex={index}
                    question={question}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-sm btn-red-alt"
              onClick={onDelete}
            >
              <span className="mr-2">
                <FAIcon icon="trash" />
              </span>{" "}
              Delete
            </button>
          </div>
        </div>
        {/* {question.questions.map(question => (
        <div key={question.id} className="border border-gray-300 p-4 rounded">
          <div className="flex">
            <div className="p-4">{question.prompt}</div>
            <div className="border-r border-gray-500 p-4">{question.kind}</div>
          </div>
        </div>
      ))} */}
      </div>
    );
  }
);
