import { FC } from "react";

interface ProviderCreateAddFormProps {
  onSuccess?(): void;
};

export const ProviderCreateAddForm: FC<ProviderCreateAddFormProps> = props => {
  return (
    <div className="ProviderCreateAddForm">
      <p>ProviderCreateAddForm</p>
    </div>
  );
};
