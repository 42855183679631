import React, { FC, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, Spinner, SelectField } from "@preferral/ui";

const LIST_SPECIALTIES = gql`
  query Specialties {
    specialties(filter: { curated: true }) {
      id
      name
      taxonomyCode
    }
  }
`;

interface Data {
  specialties: {
    id: string;
    name: string;
    taxonomyCode: string;
  }[];
}

const MUTATION = gql`
  mutation CreateNPPESProvider($input: NppesProviderInput!) {
    createNppesProvider(input: $input) {
      errors {
        key
        message
      }
      nppesProvider {
        id
      }
    }
  }
`;

interface MutationData {
  createNppesProvider: {
    errors?: InputError[];
    nppesProvider?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: FormValues;
}

type FormValues = {
  npi: string;
  firstName: string;
  lastName: string;
  specialtyIds: string[];
};

type NewProviderFormProps = {
  refetchQueries: any;
  onSuccess: () => void;
};

export const NewNPPESProviderForm: FC<NewProviderFormProps> = (props) => {
  const { refetchQueries, onSuccess } = props;
  const { data, loading, error } = useQuery<Data>(LIST_SPECIALTIES);

  const specialtyOptions =
    data?.specialties.map((s) => ({
      value: s.id,
      label: s.name,
    })) || [];

  const [createNppesProvider] = useMutation<MutationData, MutationVariables>(
    MUTATION
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      createNppesProvider({
        variables: { input: values },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp && resp.data && resp.data.createNppesProvider.errors) {
            setStatus({ errors: resp.data.createNppesProvider.errors });
          } else if (resp?.data?.createNppesProvider.nppesProvider) {
            // it worked
            // toast.success("Success");
            // closeModal();
            onSuccess();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong" }],
          });
          setSubmitting(false);
        }
      );
    },
    [createNppesProvider, refetchQueries]
  );

  return (
    <div className="NewProviderForm">
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.specialties) ? (
        <>
          <p>Failed to load</p>
          {/* <pre>{JSON.stringify(error, null, 2)</pre> */}
        </>
      ) : (
        <div>
          <Formik<FormValues>
            initialValues={{
              npi: "",
              firstName: "",
              lastName: "",
              specialtyIds: [],
            }}
            validationSchema={Yup.object().shape({
              npi: Yup.string()
                .trim()
                .required("Required"),
            })}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <div className="mt-3">
                  <TextField name="npi" label="NPI" autoFocus />
                </div>
                <div className="mt-3 flex -mx-2">
                  <div className="flex-1 mx-2">
                    <TextField name="firstName" label="First Name" />
                  </div>
                  <div className="flex-1 mx-2">
                    <TextField name="lastName" label="Last Name" />
                  </div>
                </div>

                <div className="mt-3">
                  <SelectField
                    name="specialtyIds"
                    label="Specialties"
                    options={specialtyOptions}
                    isLoading={loading}
                    isMulti
                  />
                </div>

                <div className="flex items-center justify-end mt-3 py-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-blue"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
