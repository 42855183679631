import { FC, useCallback } from "react";
import { useField, ErrorMessage } from "formik";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  ProviderSearchSelect,
  ProviderSearchFilter,
  ProviderReferenceModel,
} from "components/ProviderSearchSelect";
import { VerticalField, HorizontalField } from "@preferral/ui";

/**
 * ProviderSearchSelectInput.
 */

interface ProviderSearchSelectInputProps {
  id?: string;
  name: string;
  filter?: ProviderSearchFilter;
  placeholder?: string;
  icon?: IconDefinition;
}

export const ProviderSearchSelectInput: FC<ProviderSearchSelectInputProps> = (
  props
) => {
  const { name, placeholder, filter, icon } = props;

  const [field, , helpers] = useField<
    ProviderReferenceModel | undefined | null
  >(name);
  const { value } = field;
  const { setValue, setTouched } = helpers;

  const handleChange = setValue;

  const handleBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  return (
    <>
      <ProviderSearchSelect
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        icon={icon || "search"}
        inputProps={{
          placeholder: placeholder,
        }}
        filter={filter}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  );
};

/**
 * ProviderSearchSelectField.
 */

interface ProviderSearchSelectFieldProps
  extends ProviderSearchSelectInputProps {
  label: string;
  indicateOptional?: boolean;
  indicateRequired?: boolean;
}

export const ProviderSearchSelectField: FC<ProviderSearchSelectFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ProviderSearchSelectInput {...rest} />
    </VerticalField>
  );
};

export const HorizontalProviderSearchSelectField: FC<ProviderSearchSelectFieldProps> = (
  props
) => {
  const { label, indicateOptional, indicateRequired, ...rest } = props;

  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      label={label}
      indicateOptional={indicateOptional}
      indicateRequired={indicateRequired}
    >
      <ProviderSearchSelectInput {...rest} />
    </HorizontalField>
  );
};
