import React, { cloneElement } from "react";
import Portal from "@reach/portal";
import Tooltip, { useTooltip, TooltipPopup } from "@reach/tooltip";
import "@reach/tooltip/styles.css";

const centered = (triggerRect: DOMRect, tooltipRect: DOMRect): DOMRect => {
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    ...tooltipRect,
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.top - tooltipRect.height - 8 + window.scrollY
  };
};

type TriangleTooltipProps = {
  label: string;
  ariaLabel?: string;
  children: React.ReactElement;
};

const TriangleTooltip: React.FC<TriangleTooltipProps> = ({
  children,
  label,
  ariaLabel
}) => {
  // get the props from useTooltip
  const [trigger, tooltip] = useTooltip();

  // destructure off what we need to position the triangle
  const { isVisible, triggerRect } = tooltip;

  return (
    <>
      {cloneElement(children, trigger)}

      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <span
            style={{
              display: "block",
              position: "absolute",
              left:
                triggerRect && triggerRect.left - 10 + triggerRect.width / 2,
              top: triggerRect && triggerRect.top - 10 + window.scrollY,
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid black"
            }}
          />
        </Portal>
      )}
      <TooltipPopup
        {...tooltip}
        label={label}
        ariaLabel={ariaLabel || label}
        style={{
          background: "black",
          color: "white",
          border: "none",
          borderRadius: "3px",
          padding: "0.5em 1em"
        }}
        position={centered}
      />
    </>
  );
};

type NewTooltipProps = {
  tip: string;
};

const NewTooltip: React.FC<NewTooltipProps> = ({ tip, children }) => {
  return (
    <TriangleTooltip label={tip}>
      <span aria-hidden>{children}</span>
    </TriangleTooltip>
  );
};

export { NewTooltip };
