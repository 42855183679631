import { FC } from "react";
import { Button, FilterField, FilterForm, TextMaskInput } from "@preferral/ui";

export const npiMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export interface FilterModel {
  npi?: string;
}

export const defaultFilter: FilterModel = {
  npi: ""
};

interface FilterPanelProps {
  value: FilterModel;
  onChange(filter: FilterModel): void;
  isLoading: boolean;
};

export const FilterPanel: FC<FilterPanelProps> = props => {
  const { value, onChange, isLoading } = props;

  return (
    <FilterForm<FilterModel>
      defaultValue={defaultFilter}
      value={value}
      onChange={onChange}
    >
      <div className="flex items-end gap-2">
        <FilterField htmlFor="npi" icon="search" label="NPI Search">
          <TextMaskInput
            name="npi"
            mask={npiMask}
            inputProps={{
              placeholder: "Search..."
            }}
          />
        </FilterField>

        <input type="submit" style={{ display: "none" }} />
        <FilterField>
          <Button type="submit" color="blue" disabled={isLoading} isLoading={isLoading}>
            Search
          </Button>
        </FilterField>
      </div>
    </FilterForm>
  );
};

export function removeVoidKeys(
  filterWithNulls: Partial<Record<keyof FilterModel, any>>
): FilterModel {
  let filter = {};
  for (const key in filterWithNulls) {
    if (filterWithNulls[key] !== null && filterWithNulls[key] !== undefined) {
      filter[key] = filterWithNulls[key];
    }
  }
  return filter;
}

/**
 * Returns filter without keys that have value "ANY" or "ALL".
 * This is useful because the GraphQL API for AristaMD ID fields (e.g. specialtyId)
 * are integers, but we use "ANY" and "ALL" as the "unselected" value.
 */
export function removeUnusedFilterKeys(filter: Partial<Record<keyof FilterModel, any>>): FilterModel {
  let newFilter = {};
  for (const key in filter) {
    if (filter[key] !== "ANY" && filter[key] !== "ALL") {
      newFilter[key] = filter[key];
    }
  }
  return newFilter;
}
