import React, { FC } from "react";

interface UserAccountProps {
  userId: string;
}

export const UserAccount: FC<UserAccountProps> = (props) => {
  const { userId } = props;
  return (
    <div className="UserAccount">
      <p>UserAccount</p>
      <p>{userId}</p>
    </div>
  );
};
