import React, { FC, useState } from "react";
import { ScreenTitle } from "context/ScreenTitle";
import { EncryptFilesForm } from "./EncryptFilesForm";
import { CircleIcon } from "@preferral/ui";

interface QuickEncryptScreenProps {}

export const QuickEncryptScreen: FC<QuickEncryptScreenProps> = (props) => {
  const [archiveUrl, setArchiveUrl] = useState<string | null>(null);

  return (
    <>
      <ScreenTitle title="Encrypt Files" />
      <div className="QuickEncryptScreen">
        <div className="w-full max-w-lg p-4 bg-white rounded-lg shadow-lg">
          <div className="pt-3 pb-6 flex items-center">
            <CircleIcon icon="key" />
            <h3 className="ml-2 text-2xl font-bold text-cool-gray-700">
              Encrypt Files
            </h3>
          </div>
          {archiveUrl ? (
            <div>
              <a
                href={archiveUrl}
                target="_blank"
                rel="noref nofollow"
                className="ml-2 text-sm text-blue-500 underline"
              >
                Download Encrypted Archive
              </a>
            </div>
          ) : (
            <EncryptFilesForm onSuccess={setArchiveUrl} />
          )}
        </div>
      </div>
    </>
  );
};
