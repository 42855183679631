import React, { FC } from "react";
import { FieldArray, useField } from "formik";
import { FAIcon } from "@preferral/ui";
import { ConditionalGroupModel } from "components/Questionnaire";
import { EditConditional } from "./EditConditional";
import { newConditional, newConditionalGroup } from "../helpers";

const OperatorSelect: FC<{ name: string }> = ({ name }) => {
  const [field] = useField(name);
  return (
    <select {...field} className="bg-white font-semibold text-lg text-teal-600">
      <option value="AND" label="ALL" />
      <option value="OR" label="ANY" />
    </select>
  );
};

interface EditConditionalGroupProps {
  prefix: string;
  namePrefix: string;
  conditionalGroup: ConditionalGroupModel;
  canDelete?: boolean;
  onDelete?(): void;
};

export const EditConditionalGroup: FC<EditConditionalGroupProps> = props => {
  const {
    prefix,
    namePrefix,
    conditionalGroup,
    canDelete = true,
    onDelete,
  } = props;

  return (
    <div className="EditConditionalGroup mt-2 border border-gray-300 p-2 rounded-lg shadow-md">
      <div className="flex justify-between">
        <div>
          {conditionalGroup.conditionals &&
            conditionalGroup.conditionals.length > 1 ? (
            <div className="flex items-center px-4">
              <div className="border-b border-teal-600 border-dashed">
                <OperatorSelect name={`${namePrefix}.operator`} />
              </div>
              <p className="ml-2 font-semibold py-2 text-gray-700 text-lg">
                of the following:
              </p>
            </div>
          ) : null}
        </div>
        <div>
          {canDelete && onDelete ? (
            <button
              type="button"
              className="btn btn-sm btn-red-alt"
              onClick={onDelete}
            >
              Delete Group
            </button>
          ) : null}
        </div>
      </div>
      <div>
        <FieldArray
          name={`${namePrefix}.conditionals`}
          render={(arrayHelpers) => {
            return (
              <div className="p-2">
                <div>
                  {conditionalGroup.conditionals.map((c, idx) =>
                    c._type === "CG" ? (
                      <EditConditionalGroup
                        key={c.id}
                        prefix={prefix}
                        conditionalGroup={c}
                        namePrefix={`${namePrefix}.conditionals.${idx}`}
                        onDelete={() => arrayHelpers.remove(idx)}
                      />
                    ) : (
                      <EditConditional
                        key={idx}
                        prefix={prefix}
                        conditional={c}
                        namePrefix={`${namePrefix}.conditionals.${idx}`}
                        onDelete={() => arrayHelpers.remove(idx)}
                      />
                    )
                  )}
                </div>
                <div className="mt-4 flex justify-around">
                  <div className="flex">
                    <button
                      type="button"
                      className="btn btn-sm btn-purple-alt"
                      onClick={() =>
                        arrayHelpers.insert(
                          conditionalGroup.conditionals.length,
                          newConditional()
                        )
                      }
                    >
                      <span className="mr-2">
                        <FAIcon icon="plus" />
                      </span>
                      Add Conditional
                    </button>
                    <button
                      type="button"
                      className="ml-2 btn btn-sm btn-purple-alt"
                      onClick={() =>
                        arrayHelpers.insert(
                          conditionalGroup.conditionals.length,
                          newConditionalGroup()
                        )
                      }
                    >
                      <span className="mr-2">
                        <FAIcon icon="plus" />
                      </span>
                      Add Conditional Group
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};
