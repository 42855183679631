import { FC } from 'react'
import { useField, ErrorMessage } from 'formik'
import { SignaturePad } from 'components/SignaturePad'
import { compose, ValidateProp } from '@preferral/ui'

/**
 * SignatureInput.
 */

export interface SignatureInputProps {
  name: string
  id?: string
  editable?: boolean
  className?: string
  validate?: ValidateProp
}

export const SignatureInput: FC<SignatureInputProps> = (props) => {
  const { id, name, editable, className, validate: validators } = props

  const validate = validators && compose([validators].flat())
  const [field, meta, helpers] = useField({ name, validate })
  const { value = '', onBlur } = field
  const { setValue } = helpers

  return (
    <>
      <SignaturePad
        id={id}
        editable={editable}
        value={value}
        onChange={setValue}
        onBlur={onBlur}
        className={
          meta && meta.touched && meta.error
            ? `${className} border border-red-500`
            : className
        }
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-red-500 text-xs italic"
      />
    </>
  )
}
