import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { TableContainer, Table, TH, TD, Spinner, FAIcon } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
// import {} from 'components/Modal';
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { NoResults } from "components/NoResults";

const PAGE_SIZE = 30;

const ORGANIZATION_FORMS = gql`
  query FormsForOrg(
    $first: Int
    $after: UUID4
    $filter: ListPatientFormsFilter
  ) {
    patientForms(first: $first, after: $after, filter: $filter) {
      cursor
      endOfList
      items {
        id
        title
        logoUrl
        submissionCount
        department {
          id
          name
        }
        insertedAt
        updatedAt
      }
    }
  }
`;

interface Data {
  patientForms: Paginated<PatientFormModel>;
}

interface Variables {
  first?: number;
  after?: string;
  filter?: FilterModel;
}

interface PatientFormModel {
  id: string;
  title: string;
  logoUrl?: string;
  submissionCount: number;
  department: {
    id: string;
    name: string;
  };
  insertedAt: string;
  updatedAt: string;
}

interface FilterModel {
  organizationId?: string;
  departmentId?: string;
}

interface OrganizationFormsProps {
  organizationId: string;
}

export const OrganizationForms: FC<OrganizationFormsProps> = (props) => {
  const { organizationId } = props;

  const { data, loading, error } = useQuery<Data, Variables>(
    ORGANIZATION_FORMS,
    {
      variables: {
        first: PAGE_SIZE,
        filter: {
          organizationId,
        },
      },
    }
  );

  return (
    <div className="_OrganizationForms bg-gray-100 mt-4 p-4 rounded-2xl text-left">
      <ScreenTitle title={["Organizations", "View Organization", "Forms"]} />
      <div>
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.patientForms ? (
          <p>Failed to load.</p>
        ) : (
          <div>
            <div className="flex justify-between px-3 pb-4">
              <div className="_FilterPanel" />
              {/* <button
                type="button"
                className="btn btn-blue"
                onClick={openNewModal}
              >
                <span className="mr-2">
                  <FAIcon icon="plus" />
                </span>
                Add Location
              </button> */}
            </div>
            {data.patientForms.items.length === 0 ? (
              <NoResults icon="file-alt" text="No forms added yet" />
            ) : (
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Form Name</TH>
                      <TH># Submissions</TH>
                      <TH>Department</TH>
                      <TH>Created At</TH>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.patientForms.items.map((form) => (
                      <tr key={form.id}>
                        <TD>
                          {form.title}
                          <a
                            href={`${appHost(window.location.host)}/pg/forms/${
                              form.id
                            }`}
                            target="_blank"
                            rel="noref nofollow"
                            className="ml-2 text-sm text-blue-500 underline"
                          >
                            <FAIcon icon="external-link-alt" />
                          </a>
                        </TD>
                        <TD>{form.submissionCount}</TD>
                        <TD>{form.department.name}</TD>
                        <TD>{format(parseISO(form.insertedAt), "M/d/yy")}</TD>
                        {/* <TD className="w-40">Actions</TD> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function appHost(adminHost: string) {
  if (adminHost.includes(".dev")) {
    return "https://app.preferral.dev";
  } else if (adminHost.includes("preferral.com")) {
    return "https://next.preferral.com";
  } else {
    return "localhost:3000";
  }
}
