import uuid from "uuid-random";
import {
  QuestionModel,
  TextQuestion,
  SectionModel,
  ConditionalModel,
  ConditionalGroupModel,
  RuleModel
} from "components/Questionnaire";

export function newQuestion(attrs: Partial<TextQuestion> = {}): QuestionModel {
  const q: TextQuestion = {
    id: uuid(),
    defaultVisible: true,
    forcePrintVisible: false,
    prompt: "New Question",
    kind: "text",
    rules: [],
    required: false,
    answer: null,
    ...attrs
  };
  return q;
}

export function newSection(attrs: Partial<SectionModel> = {}): SectionModel {
  return {
    id: uuid(),
    defaultVisible: true,
    forcePrintVisible: false,
    title: "New Section",
    questions: [],
    rules: [],
    ...attrs
  };
}

export function newConditional(
  attrs: Partial<ConditionalModel> = {}
): ConditionalModel {
  return {
    _type: "C",
    operator: "<",
    compareValue: "",
    valueSource: {
      source: "QUESTIONNAIRE",
      meta: {
        _type: "Q",
        questionId: ""
      }
    },
    ...attrs
  };
}

export function newConditionalGroup(
  attrs: Partial<ConditionalGroupModel> = {}
): ConditionalGroupModel {
  return {
    _type: "CG",
    id: uuid(),
    operator: "OR",
    conditionals: [newConditional()],
    ...attrs
  };
}

export function newRule(attrs: Partial<RuleModel> = {}): RuleModel {
  return {
    id: uuid(),
    conditionalGroup: newConditionalGroup(),
    thenActions: ["UNHIDE"],
    ...attrs
  };
}
