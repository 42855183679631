import { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField, TextMaskField, TextInput, VerticalField, FAIcon } from "@preferral/ui";

const MUTATION = gql`
  mutation CreateNPPESOrganization($input: NppesOrganizationInput!) {
    createNppesOrganization(input: $input) {
      errors {
        key
        message
      }
      nppesOrganization {
        id
      }
    }
  }
`;

interface MutationData {
  createNppesOrganization: {
    errors?: InputError[];
    nppesOrganization?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: FormValues;
}

interface FormValues {
  npi: string;
  name: string;
  alternateNames: string[];
}

const npiRegex = /^\d{10}$/;
const npiMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    npi: Yup.string().matches(npiRegex),
    name: Yup.string().required("Required"),
  })
  .required();

interface NewNPPESOrganizationFormProps {
  refetchQueries: any;
  onSuccess?(): void;
}

export const NewNPPESOrganizationForm: FC<NewNPPESOrganizationFormProps> = (
  props
) => {
  const { refetchQueries, onSuccess } = props;
  const [createNppesOrganization] = useMutation<
    MutationData,
    MutationVariables
  >(MUTATION);

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      createNppesOrganization({
        variables: { input: values },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp?.data?.createNppesOrganization.errors) {
            setStatus({ errors: resp.data.createNppesOrganization.errors });
          } else if (resp?.data?.createNppesOrganization.nppesOrganization) {
            // it worked
            // toast.success("Success")
            if (onSuccess) onSuccess();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
        }
      );
    },
    [createNppesOrganization, refetchQueries]
  );

  return (
    <div className="NewNPPESOrganizationForm">
      <Formik<FormValues>
        initialValues={{
          npi: "",
          name: "",
          alternateNames: [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="mt-3">
              <TextMaskField
                name="npi"
                label="NPI"
                mask={npiMask}
                placeholder="NPI"
                autoFocus
              />
            </div>
            <div className="mt-3">
              <TextField name="name" label="Name" />
            </div>

            <div className="mt-3">
              <VerticalField label="Alternate Names">
                <FieldArray name="alternateNames">
                  {({ insert, remove }) => (
                    <>
                      {values.alternateNames.map((name, idx) => (
                        <div key={idx} className="mt-2 flex items-center">
                          <div className="flex-1">
                            <TextInput
                              name={`alternateNames.${idx}`}
                              placeholder={`Alternate Name #${idx + 1}`}
                            />
                          </div>
                          <div className="ml-2">
                            <button
                              type="button"
                              className="btn border btn-red-alt"
                              onClick={() => remove(idx)}
                            >
                              <FAIcon icon="trash" />
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="mt-2 text-center">
                        <button
                          type="button"
                          className="btn border btn-sm btn-teal-alt"
                          onClick={() =>
                            insert(values.alternateNames.length - 1, "")
                          }
                        >
                          Add Name +
                        </button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </VerticalField>
            </div>

            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
