import React, { FC } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, SelectField, PhoneMaskField } from "@preferral/ui";
import { CountySelectField } from "components/formik/CountySelectField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";

const CREATE_NPPES_LOCATION = gql`
  mutation CreateNPPESLocation($orgId: UUID4!, $input: NppesLocationInput!) {
    createNppesLocation(nppesOrganizationId: $orgId, input: $input) {
      errors {
        key
        message
      }
      nppesLocation {
        id
        name
      }
    }
  }
`;

interface MutationData {
  createNppesLocation: {
    errors?: InputError[];
    nppesLocation?: {
      id: string;
      name: string;
    };
  };
}

interface MutationVariables {
  orgId: string;
  input: NPPESLocationInput;
}

interface NPPESLocationInput {
  name: string;
  phone: string;
  phoneExtension?: string;
  fax?: string;
  faxExtension?: string;
  streetAddress: string;
  streetAddressLine2?: string;
  city: string;
  stateAbbreviation: string;
  zip: string;
  fipsCode: string;
  timeZoneName: string;
  taxonomyIds: string[];
}

const STATES_TIME_ZONES_AND_SPECIALTIES = gql`
  query StatesTimeZonesAndSpecialties {
    timeZoneNames
    states {
      id
      name
      abbreviation
    }
    specialties(filter: { curated: true }) {
      id
      name
    }
  }
`;

interface Data {
  timeZoneNames: string[];
  states: {
    id: string;
    name: string;
    abbreviation: string;
  }[];
  specialties: {
    id: string;
    name: string;
  }[];
}

interface NewNPPESLocationFormProps {
  closeModal(): void;
  nppesOrganizationId: string;
  refetchQueries: any;
}

export const NewNPPESLocationForm: FC<NewNPPESLocationFormProps> = (props) => {
  const { closeModal, nppesOrganizationId, refetchQueries } = props;
  const { data, loading } = useQuery<Data>(STATES_TIME_ZONES_AND_SPECIALTIES);

  const specialtyOptions =
    data?.specialties.map((s) => ({
      value: s.id,
      label: s.name,
    })) || [];

  const [createLocation] = useMutation<MutationData, MutationVariables>(
    CREATE_NPPES_LOCATION
  );

  return (
    <div className="NewNPPESLocationForm">
      <Formik<NPPESLocationInput>
        initialValues={{
          name: "",
          phone: "",
          phoneExtension: "",
          fax: "",
          faxExtension: "",
          streetAddress: "",
          streetAddressLine2: "",
          city: "",
          stateAbbreviation: "",
          zip: "",
          fipsCode: "",
          timeZoneName: "",
          taxonomyIds: []
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          phone: Yup.string()
            .required("Required")
            .matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, "Invalid phone number"),
          fax: Yup.string().matches(
            /^\(\d{3}\)\s\d{3}-\d{4}$/,
            "Invalid fax number"
          ),
          streetAddress: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          stateAbbreviation: Yup.string().required("Required"),
          zip: Yup.string().required("Required"),
          fipsCode: Yup.string().required("Required"),
          timeZoneName: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          createLocation({
            variables: { orgId: nppesOrganizationId, input: values },
            refetchQueries,
          }).then((res) => {
            if (res?.data?.createNppesLocation.errors) {
              setStatus({
                errors: res.data.createNppesLocation.errors,
              });
            } else if (res?.data?.createNppesLocation?.nppesLocation) {
              // it worked
              // toast.success("Created Location")
              closeModal();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <TextField name="name" label="Name" autoFocus />
            </div>

            <div className="mt-3 flex flex-wrap -mx-2">
              <div className="w-full md:w-2/3 px-2">
                <PhoneMaskField name="phone" label="Phone" icon="phone" />
              </div>
              <div className="w-full md:w-1/3 px-2">
                <TextField name="phoneExtension" label="Phone Ext." />
              </div>
            </div>

            <div className="mt-3 flex flex-wrap -mx-2">
              <div className="w-full md:w-2/3 px-2">
                <PhoneMaskField name="fax" label="Fax" icon="fax" />
              </div>
              <div className="w-full md:w-1/3 px-2">
                <TextField name="faxExtension" label="Fax Ext." />
              </div>
            </div>

            <div className="mt-3">
              <TextField name="streetAddress" label="Address" />
            </div>

            <div className="mt-3">
              <TextField name="streetAddressLine2" label="Address Line 2" />
            </div>

            <div className="mt-3">
              <TextField name="city" label="City" />
            </div>

            <div className="mt-3">
              <SelectField
                name="stateAbbreviation"
                label="State"
                options={
                  data?.states.map((s) => ({
                    value: s.abbreviation,
                    label: s.name,
                  })) || []
                }
                isLoading={loading}
              />
            </div>

            <div className="mt-3">
              <TextField name="zip" label="Zip Code" />
            </div>

            <div className="mt-3">
              <CountySelectField
                name="fipsCode"
                label="County"
                zip={values.zip}
              />
            </div>

            <div className="mt-3">
              <SelectField
                name="timeZoneName"
                label="Time Zone"
                isLoading={loading}
                options={
                  data?.timeZoneNames.map((tz) => ({ value: tz, label: tz })) ||
                  []
                }
              />
            </div>

            <div className="mt-3">
              <SelectField
                name="taxonomyIds"
                label="Specialties"
                options={specialtyOptions}
                isLoading={loading}
                isMulti
              />
            </div>

            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Create Location
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
