import React, { FC } from "react";

interface NetworkRulesProps {
  networkId: string;
}

export const NetworkRules: FC<NetworkRulesProps> = (props) => {
  return (
    <div className="NetworkRules">
      <p>NetworkRules</p>
    </div>
  );
};
