import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { NavTabs, NavTabLink } from "components/NavTabs";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import { UserOverview } from "./UserOverview";
import { UserActivity } from "./UserActivity";
import { UserAccount } from "./UserAccount";
import { UserLoginAttempts } from "./UserLoginAttempts";

const FETCH_USER = gql`
  query FetchUser($id: UUID4!) {
    user(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

interface Data {
  user: UserModel;
}

interface Variables {
  id: string;
}

interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
}

interface RouteParams {
  userId: string;
}

interface UserShowScreenProps {}

export const UserShowScreen: FC<UserShowScreenProps> = (props) => {
  const match = useRouteMatch<RouteParams>();
  const { userId } = match.params;

  const { data, loading, error } = useQuery<Data, Variables>(FETCH_USER, {
    variables: { id: userId },
  });

  return (
    <div className="_UserShowScreen p-4">
      <ScreenTitle title={["Users", "View User"]} />

      <div className="container mx-auto p-3 rounded-2xl shadow-xl text-left">
        {loading ? (
          <div className="p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data?.user ? (
          <p>Failed to load</p>
        ) : (
          <>
            <div>
              <div className="flex items-center">
                <div className="ml-2">
                  <div className="relative">
                    {/* <Avatar size="sm" image={data.nppesProvider.avatar} /> */}
                    {/* {data.nppesProvider.converted ? (
                      <BadgeContainer>
                        <ConvertedBadge />
                      </BadgeContainer>
                    ) : data.nppesProvider.nppesUpdatesLocked || true ? (
                      <BadgeContainer>
                        <LockedBadge />
                      </BadgeContainer>
                    ) : null} */}
                  </div>
                </div>
                <h2 className="ml-4 font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                  {data.user.firstName} {data.user.lastName}
                </h2>
              </div>
            </div>
            <div className="mt-4">
              <NavTabs>
                <NavTabLink to={`${match.url}/overview`}>Overview</NavTabLink>
                <NavTabLink to={`${match.url}/account`}>Account</NavTabLink>
                <NavTabLink to={`${match.url}/activity`}>Activity</NavTabLink>
                <NavTabLink to={`${match.url}/login_attempts`}>
                  Logins
                </NavTabLink>
              </NavTabs>
            </div>
          </>
        )}
      </div>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/overview`} />
        </Route>
        <Route path={`${match.path}/overview`}>
          <UserOverview userId={userId} />
        </Route>
        <Route path={`${match.path}/activity`}>
          <UserActivity userId={userId} />
        </Route>
        <Route path={`${match.path}/account`}>
          <UserAccount userId={userId} />
        </Route>
        <Route path={`${match.path}/login_attempts`}>
          <UserLoginAttempts userId={userId} />
        </Route>
      </Switch>
    </div>
  );
};
