import { FC } from "react";
import { useRouteMatch, Redirect, Switch, Route } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { ImageObject, Spinner, Avatar, FAIcon } from "@preferral/ui";
import { ScreenTitle } from "context/ScreenTitle";
import { NavTabs, NavTabLink } from "components/NavTabs/NavTabs";
import BadgeCheckIcon from '@heroicons/react/solid/BadgeCheckIcon';
import LockClosedIcon from '@heroicons/react/solid/LockClosedIcon';
import styled from "styled-components";
import { NPPESProviderDetails } from "./NPPESProviderDetails";

const NPPES_PROVIDER = gql`
  query FetchNPPESProvider($id: UUID4!) {
    nppesProvider(id: $id) {
      id
      npi
      prefix
      firstName
      middleInitial
      lastName
      suffix
      credentialText
      gender
      nameWithAppellation
      nppesUpdatesLocked
      converted
      primarySpecialty {
        id
        name
      }
      avatar(size: "small") {
        base64
        src
        srcSet
      }
    }
  }
`;

interface Data {
  nppesProvider: NPPESProvider;
}

interface NPPESProvider {
  id: string;
  npi: string;
  prefix?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  suffix?: string;
  credentialText?: string;
  gender?: string;
  nameWithAppellation: string;
  primarySpecialty?: Specialty;
  nppesUpdatesLocked: boolean;
  converted: boolean;
  avatar: ImageObject;
}

interface Specialty {
  id: string;
  name: string;
}

/**
 * BadgeContainer.
 */
const BadgeContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  translate: translate(14%, 0%);
`;

/**
 * ConvertedBadge.
 */
const ConvertedBadge = () => {
  return <BadgeCheckIcon className="h-8 text-blue-500" />;
};

/**
 * LockedBadge.
 */
const LockedBadge = () => {
  return (
    <div className="rounded-full p-1 bg-indigo-500">
      <LockClosedIcon className="h-4 text-indigo-50" />
    </div>
  );
};

interface RouteParams {
  nppesProviderId: string;
}

interface NPPESProviderShowScreenProps { }

export const NPPESProviderShowScreen: FC<NPPESProviderShowScreenProps> = (
  props
) => {
  const match = useRouteMatch<RouteParams>();
  const { nppesProviderId } = match.params;

  const { loading, data, error } = useQuery<Data>(NPPES_PROVIDER, {
    variables: { id: nppesProviderId },
  });

  return (
    <div className="_NPPESProviderShowScreen p-4">
      <ScreenTitle title={["Providers", "View Provider"]} />

      <div className="container mx-auto p-3 rounded-2xl shadow-xl text-left">
        {loading ? (
          <div className="p-4 text-center">
            <Spinner />
          </div>
        ) : error || !data?.nppesProvider ? (
          <p>Failed to load.</p>
        ) : (
          <>
            <div>
              <div className="flex items-center">
                <div className="ml-2">
                  <div className="relative">
                    <Avatar size="sm" image={data.nppesProvider.avatar} />
                    {data.nppesProvider.converted ? (
                      <BadgeContainer>
                        <ConvertedBadge />
                      </BadgeContainer>
                    ) : data.nppesProvider.nppesUpdatesLocked || true ? (
                      <BadgeContainer>
                        <LockedBadge />
                      </BadgeContainer>
                    ) : null}
                  </div>
                </div>
                <h2 className="ml-4 font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                  {data.nppesProvider.nameWithAppellation}
                </h2>
              </div>
              {data.nppesProvider.primarySpecialty && (
                <p>
                  <span className="mr-2">
                    <FAIcon icon="stethoscope" />
                  </span>
                  {data.nppesProvider.primarySpecialty.name}
                </p>
              )}
            </div>
            <div className="mt-4">
              <NavTabs>
                <NavTabLink to={`${match.url}/overview`}>Overview</NavTabLink>
                <NavTabLink to={`${match.url}/locations`}>Locations</NavTabLink>
                <NavTabLink to={`${match.url}/networks`}>Networks</NavTabLink>
              </NavTabs>
            </div>
          </>
        )}
      </div>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/overview`} />
        </Route>
        <Route path={`${match.path}/overview`}>
          <NPPESProviderDetails nppesProviderId={nppesProviderId} />
        </Route>
        <Route path={`${match.path}/locations`}>
          <h1>LOcations</h1>
        </Route>
        <Route path={`${match.path}/networks`}>
          <h1>Networks</h1>
        </Route>
      </Switch>
    </div>
  );
};
