import React, { FC, useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "@preferral/ui";

const CREATE_NETWORK = gql`
  mutation CreateNetwork($input: NetworkInput!) {
    createNetwork(input: $input) {
      errors {
        key
        message
      }
      network {
        id
      }
    }
  }
`;

interface MutationData {
  createNetwork: {
    errors?: InputError[];
    network?: {
      id: string;
    };
  };
}

interface MutationVariables {
  input: FormValues;
}

interface FormValues {
  name: string;
  shortName: string;
  subdomain: string;
}

interface NewNetworkFormProps {
  refetchQueries: any;
  onSuccess(): void;
}

export const NewNetworkForm: FC<NewNetworkFormProps> = (props) => {
  const { refetchQueries, onSuccess } = props;
  const [createNetwork] = useMutation<MutationData, MutationVariables>(
    CREATE_NETWORK
  );

  const onSubmit = useCallback(
    (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });
      createNetwork({
        variables: { input: values },
        refetchQueries,
      }).then(
        (resp) => {
          if (resp?.data?.createNetwork.errors) {
            setStatus({ errors: resp.data.createNetwork.errors });
          } else if (resp?.data?.createNetwork.network) {
            // it worked
            // toast.success("Success")
            if (onSuccess) onSuccess();
          }
          setSubmitting(false);
        },
        (rej) => {
          setStatus({
            errors: [{ key: "", message: "Something went wrong." }],
          });
          setSubmitting(false);
        }
      );
    },
    [createNetwork, refetchQueries]
  );
  return (
    <div className="_NewNetworkForm">
      <Formik<FormValues>
        initialValues={{ name: "", shortName: "", subdomain: "" }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="mt-3">
              <TextField name="name" label="Name" autoFocus />
            </div>
            <div className="mt-3">
              <TextField name="shortName" label="Short Name" indicateOptional />
            </div>
            <div className="mt-3">
              <TextField name="subdomain" label="Subdomain" />
            </div>
            <div className="flex items-center justify-end mt-3 py-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-blue"
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
