import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@preferral/ui";

const ADMIN_CSM_ACCOUNTS = gql`
  query FetchAdminCSMAccounts($id: UUID4!) {
    admin(id: $id) {
      id
    }
  }
`;

interface Data {
  admin: {
    id: string;
  };
}

interface Variables {
  id: string;
}

interface AdminCSMAccountsProps {
  adminId: string;
}

export const AdminCSMAccounts: FC<AdminCSMAccountsProps> = (props) => {
  const { adminId } = props;
  const { data, loading, error } = useQuery<Data, Variables>(
    ADMIN_CSM_ACCOUNTS,
    { variables: { id: adminId } }
  );

  return (
    <div className="AdminCSMAccounts">
      <p>AdminCSMAccounts</p>
      {loading ? (
        <div className="p-12 text-center">
          <Spinner />
        </div>
      ) : error || !data?.admin ? (
        <>
          <p>Failed to load</p>
          {/* <pre>{JSON.stringify(error, null, 2)</pre> */}
        </>
      ) : (
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
