import { FC, useLayoutEffect } from "react";
import { SingleCheckboxInput } from "@preferral/ui";
import {
  useFormikContext,
  getIn,
  FieldArray
} from "formik";
import { QuestionnaireModel, QuestionModel } from "components/Questionnaire";
import { AnswerOptions } from "./AnswerOptions";

/**
 * ConfigMeta.
 */
interface ConfigMetaProps {
  prefix: string;
  sectionIndex: number;
  questionIndex: number;
  question: QuestionModel;
};

export const ConfigMeta: FC<ConfigMetaProps> = (props) => {
  const { prefix, sectionIndex, questionIndex, question } = props;
  const ctx = useFormikContext<QuestionnaireModel>();
  const questionName = `${prefix}.sections.${sectionIndex}.questions.${questionIndex}`;
  const configName = `${questionName}.config`;
  const { kind: questionType } = question;

  useLayoutEffect(() => {
    const defaultConfig = defaultConfigForQuestionType(questionType);
    const validConfig = mergeConfig(question.config || {}, defaultConfig);
    ctx.setFieldValue(configName, validConfig);
  }, [configName, questionType]);

  return (
    <div>
      {question.kind === "medication_list" ? (
        <>
          <h6 className="text-xs font-semibold text-gray-500 pt-4 pb-2">Question Options</h6>
          <SingleCheckboxInput
            name={`${configName}.includeDosage`}
            checkboxLabel="Include Dosage"
          />
          <div className="mt-2">
            <SingleCheckboxInput
              name={`${configName}.includeFrequency`}
              checkboxLabel="Include Frequency"
            />
          </div>
        </>
      ) : null}
      {question.kind === "date" ? (
        <>
          <h6 className="text-xs font-semibold text-gray-500 pt-4 pb-2">Question Options</h6>
          <SingleCheckboxInput
            name={`${configName}.prepopulateToday`}
            checkboxLabel={`Prepopulate "Today"`}
          />
        </>
      ) : null}
    </div>
  );
};

function defaultConfigForQuestionType(
  questionType: QuestionModel["kind"]
): Record<string, any> {
  switch (questionType) {
    case "medication_list":
      return {
        includeDosage: true,
        includeFrequency: false,
      };
    case "date":
      return {
        prepopulateToday: false
      };
    default:
      return {};
  }
}

function mergeConfig(
  existingConfig: Record<string, any>,
  defaultConfig: Record<string, any>
): Record<string, any> {
  const newConfig = { ...existingConfig };
  Object.keys(defaultConfig).forEach((key) => {
    newConfig[key] = existingConfig[key] ?? defaultConfig[key];
  });
  return newConfig;
}

/**
 * OptionsMeta.
 */

interface OptionsMetaProps {
  prefix: string;
  sectionIndex: number;
  questionIndex: number;
  question: QuestionModel;
};

const withOptions: QuestionModel["kind"][] = ["checkboxes", "radio", "dropdown"];

export const OptionsMeta: FC<OptionsMetaProps> = ({
  prefix,
  sectionIndex,
  questionIndex,
  question,
}) => {
  const ctx = useFormikContext<QuestionnaireModel>();
  const questionName = `${prefix}.sections.${sectionIndex}.questions.${questionIndex}`;
  const optionsName = `${questionName}.options`;

  const hasOptions = withOptions.includes(question.kind);

  useLayoutEffect(() => {
    if (!hasOptions) {
      const question = getIn(ctx.values, questionName);
      if (question.options) {
        delete question.options;
      }
      ctx.setFieldValue(questionName, question);
    } else {
      const question = getIn(ctx.values, questionName);
      const options = question.options ?? ["Option 1", "Option 2"];
      ctx.setFieldValue(optionsName, options);
    }
  }, [hasOptions]);

  return (
    <div className="DropdownQuestion__meta-editor py-4">
      {hasOptions ? (
        <FieldArray
          name={optionsName}
          render={(arrayHelpers) => (
            <AnswerOptions
              prefix={prefix}
              sectionIndex={sectionIndex}
              questionIndex={questionIndex}
              question={question}
              options={getIn(ctx.values, optionsName)}
              {...arrayHelpers}
            />
          )}
        />
      ) : null}
    </div>
  );
};
