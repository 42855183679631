import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@preferral/ui";
import { NewNetworkSubgroupForm } from "./NewNetworkSubgroupForm";

const NETWORK_SUBGROUPS = gql`
  query GetNetwork($id: UUID4!) {
    network(id: $id) {
      id
      name
      subdomain
      networkSubgroups {
        id
        name
      }
    }
  }
`;

interface Data {
  network: {
    id: string;
  };
}

interface Variables {
  id: string;
}

interface NetworkSubgroupsProps {
  networkId: string;
}

export const NetworkSubgroups: FC<NetworkSubgroupsProps> = (props) => {
  const { networkId } = props;

  const { data, loading, error, refetch } = useQuery<Data, Variables>(
    NETWORK_SUBGROUPS,
    {
      variables: { id: networkId },
    }
  );

  return (
    <div className="NetworkSubgroups grid grid-cols-12 gap-4 px-4">
      <div className="_list col-span-8">
        {loading ? (
          <div className="p-6 text-center">
            <Spinner />
          </div>
        ) : error || !data?.network ? (
          <p>Failed to load.</p>
        ) : (
          <pre>{JSON.stringify(data, null, 2)}</pre>
        )}
      </div>
      <div className="_add-form col-span-4">
        <div className="rounded-lg shadow-lg bg-white p-4">
          <h4 className="text-lg font-semibold text-gray-700">
            Create Subnetwork
          </h4>
          <NewNetworkSubgroupForm networkId={networkId} onSuccess={refetch} />
        </div>
      </div>
    </div>
  );
};
