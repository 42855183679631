import React, { FC, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Spinner, Avatar, FAIcon, ImageObject } from "@preferral/ui";
import { Link } from "react-router-dom";
import { ScreenTitle } from "context/ScreenTitle";
import { NoResults } from "components/NoResults";

const LIST_ADMINS = gql`
  query ListAdmins($first: Int, $after: UUID4, $filter: ListAdminsFilter) {
    admins(first: $first, after: $after, filter: $filter) {
      endOfList
      cursor
      items {
        id
        email
        firstName
        lastName
        avatar(size: "thumbnail") {
          base64
          src
          srcSet
        }
      }
    }
  }
`;

interface Data {
  admins: Paginated<AdminModel>;
}

interface Variables {
  first?: number;
  after?: string;
  filter?: ListAdminsFilterModel;
}

interface AdminModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: ImageObject;
}

interface ListAdminsFilterModel {
  searchTerm?: string;
}

/**
 * AdminRow.
 */

interface AdminRowProps {
  admin: AdminModel;
}

const AdminRow: FC<AdminRowProps> = (props) => {
  const { admin } = props;

  return (
    <li>
      <Link
        to={`/admin/admins/${admin.id}`}
        className="block hover:bg-gray-50 border-b border-gray-200 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
      >
        <div className="flex items-center px-2 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <Avatar size="thumb" image={admin.avatar} />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-sm leading-5 font-medium text-indigo-600 truncate">
                  {admin.firstName} {admin.lastName}
                  {/* <span className="italic ml-2 text-gray-400 text-xs">
                    {admin.email}
                  </span> */}
                </div>
                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                  <span className="mr-2">
                    <FAIcon icon="envelope" />
                  </span>
                  <span className="truncate">{admin.email}</span>
                </div>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-gray-900">
                    Applied on
                    <time dateTime="2020-01-07">January 7, 2020</time>
                  </div>
                  <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                    <svg
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Completed phone screening
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

type ActiveModal = "NEW_ADMIN";

interface AdminsScreenProps {}

export const AdminsScreen: FC<AdminsScreenProps> = (props) => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const { data, loading, error } = useQuery<Data, Variables>(LIST_ADMINS);

  const openNewModal = () => setActiveModal("NEW_ADMIN");
  const closeModal = () => setActiveModal(null);

  return (
    <div className="_AdminsScreen">
      <ScreenTitle title="Admins" />
      <div className="p-4 flex justify-end">
        <button type="button" className="btn btn-blue" onClick={openNewModal}>
          <FAIcon icon="plus" />
          <span className="hidden md:visible ml-2">New Provider</span>
        </button>
      </div>
      <div className="mt-4 lg:px-4">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.admins ? (
          <p>Failed to load</p>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            {data.admins.items.length === 0 ? (
              <NoResults icon="user" text="No Results" />
            ) : (
              <>
                <ul>
                  {data.admins.items.map((admin) => (
                    <AdminRow key={admin.id} admin={admin} />
                  ))}
                </ul>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
