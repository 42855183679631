import { Button, FAIcon, InputText } from '@preferral/ui'
import React, { FC } from 'react'
import { MedicationModel } from './model'

type State = MedicationModel[]

export interface MedicationListInputProps {
  id?: string
  className?: string
  value: State
  onChange(newState: State): void
  includeFrequency?: boolean
  includeDosage?: boolean
}

export const newMedication: MedicationModel = {
  name: '',
  dosage: '',
  frequency: '',
}

export const MedicationListInput: FC<MedicationListInputProps> = (props) => {
  const {
    id,
    className = '',
    value,
    onChange,
    includeDosage = false,
    includeFrequency = false,
  } = props

  function addMedication() {
    return onChange([...value, newMedication])
  }

  function removeMedication(idx: number) {
    let nextState = [...value]
    nextState.splice(idx, 1)
    return onChange(nextState)
  }

  function updateName(idx: number, inputValue: string) {
    let nextState = [...value]
    nextState[idx] = { ...nextState[idx], name: inputValue }
    return onChange(nextState)
  }

  function updateDosage(idx: number, inputValue: string) {
    let nextState = [...value]
    nextState[idx] = { ...nextState[idx], dosage: inputValue }
    return onChange(nextState)
  }

  function updateFrequency(idx: number, inputValue: string) {
    let nextState = [...value]
    nextState[idx] = { ...nextState[idx], frequency: inputValue }
    return onChange(nextState)
  }

  return (
    <div id={id} className={`_MedicationListInput ${className}`}>
      {value.map((med, idx) => (
        <div key={idx} className="flex items-center gap-1 mb-2">
          <div className="flex-grow">
            <InputText
              value={med.name}
              onChange={(value) => updateName(idx, value)}
              inputProps={{ placeholder: 'Medication Name' }}
            />
          </div>
          {includeDosage ? (
            <div className="w-24">
              <InputText
                value={med.dosage}
                onChange={(value) => updateDosage(idx, value)}
                inputProps={{ placeholder: 'Dose' }}
              />
            </div>
          ) : null}
          {includeFrequency ? (
            <div className="w-28">
              <InputText
                value={med.frequency}
                onChange={(value) => updateFrequency(idx, value)}
                inputProps={{ placeholder: 'Freq.' }}
              />
            </div>
          ) : null}
          <div className="w-8 h-full">
            {idx > 0 ? (
              <Button
                type="button"
                kind="tertiary"
                size="sm"
                color="red"
                onClick={() => removeMedication(idx)}
              >
                <FAIcon icon="times" />
              </Button>
            ) : null}
          </div>
        </div>
      ))}
      <div className="text-center">
        <Button
          type="button"
          kind="tertiary"
          size="xs"
          color="blue"
          onClick={addMedication}
        >
          <span className="mr-2">
            <FAIcon icon="plus" />
          </span>
          Add Medication
        </Button>
      </div>
    </div>
  )
}

export function filterEmpties(value: MedicationModel[]): MedicationModel[] {
  return value.filter((m) => !!m.name || !!m.dosage || !!m.frequency)
}
