import { FC } from 'react';
import { Modal, ModalHeader } from "components/Modal";
import { EditSpecialtyForm } from './EditSpecialtyForm';

/**
 * EditSpecialtyModal.
 */

interface EditSpecialtyModalProps {
  isOpen: boolean;
  onClose(): void;
  specialtyId: string;
  onSuccess(): void;
}

export const EditSpecialtyModal: FC<EditSpecialtyModalProps> = (props) => {
  const { isOpen, onClose, specialtyId, onSuccess } = props;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader icon="pencil-alt" title="Edit Specialty" onClose={onClose} />
      <div className="px-4 pt-5 pb-4">
        <EditSpecialtyForm
          specialtyId={specialtyId}
          onSuccess={onSuccess}
        />
      </div>
    </Modal>
  )
}
