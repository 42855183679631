import React, { FC } from "react";
import { gql, useQuery } from "@apollo/client";
import { Redirect, useRouteMatch, Switch, Route } from "react-router-dom";
import { Spinner, CircleIcon } from "@preferral/ui";
import { NavTabs, NavTabLink } from "components/NavTabs";
import { ScreenTitle } from "context/ScreenTitle";
import { NPPESOrganizationOverview } from "./NPPESOrganizationOverview/NPPESOrganizationOverview";
import { NPPESOrganizationLocations } from "./NPPESOrganizationLocations/NPPESOrganizationLocations";
import { NPPESOrganizationProviders } from "./NPPESOrganizationProviders/NPPESOrganizationProviders";
import { NPPESOrganizationForms } from "./NPPESOrganizationForms/NPPESOrganizationForms";
import { NPPESOrganizationRules } from "./NPPESOrganizationRules/NPPESOrganizationRules";

const NPPES_ORGANIZATION = gql`
  query FetchNPPESOrganization($id: UUID4!) {
    nppesOrganization(id: $id) {
      id
      name
      alternateNames
      insertedAt
      updatedAt
    }
  }
`;

interface NPPESOrganization {
  id: string;
  name: string;
  alternateNames?: string[];
  insertedAt: string;
  updatedAt: string;
}

interface Data {
  nppesOrganization: NPPESOrganization;
}

interface RouteParams {
  nppesOrganizationId: string;
}

interface NPPESOrganizationShowScreenProps { }

export const NPPESOrganizationShowScreen: FC<NPPESOrganizationShowScreenProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { nppesOrganizationId } = match.params;

  const { loading, data, error } = useQuery<Data>(NPPES_ORGANIZATION, {
    variables: { id: nppesOrganizationId },
  });

  return (
    <div className="NPPESOrganizationShowScreen p-4">
      <ScreenTitle title={["NPPES Organizations", "View Organization"]} />

      <div className="container mx-auto p-3 rounded-2xl shadow-xl text-left">
        {loading ? (
          <Spinner />
        ) : error || !data || (data && !data.nppesOrganization) ? (
          <>
            <p>Failed to load.</p>
          </>
        ) : (
          <>
            <div>
              <div className="flex items-center">
                <div className="ml-2">
                  <CircleIcon icon="hospital-alt" color="purple" />
                </div>
                <h2 className="ml-4 font-black text-xl sm:text-2xl lg:text-4xl text-gray-800">
                  {data.nppesOrganization.name}
                </h2>
              </div>
              {data.nppesOrganization.alternateNames?.map((name) => (
                <p key={name}>
                  AKA:{" "}
                  <span className="font-semibold text-gray-700 text-lg">
                    {name}
                  </span>
                </p>
              ))}
            </div>
            <div className="mt-4">
              <NavTabs>
                <NavTabLink to={`${match.url}/overview`}>Overview</NavTabLink>
                <NavTabLink to={`${match.url}/locations`}>Locations</NavTabLink>
                <NavTabLink to={`${match.url}/providers`}>Providers</NavTabLink>
                <NavTabLink to={`${match.url}/forms`}>Forms</NavTabLink>
                <NavTabLink to={`${match.url}/rules`}>Rules</NavTabLink>
              </NavTabs>
            </div>
          </>
        )}
      </div>

      <Switch>
        <Route exact path={match.path}>
          <Redirect to={`${match.url}/overview`} />
        </Route>
        <Route path={`${match.path}/overview`}>
          <NPPESOrganizationOverview
            nppesOrganizationId={nppesOrganizationId}
          />
        </Route>
        <Route exact path={`${match.path}/locations`}>
          <NPPESOrganizationLocations
            nppesOrganizationId={nppesOrganizationId}
          />
        </Route>
        <Route path={`${match.path}/providers`}>
          <NPPESOrganizationProviders
            nppesOrganizationId={nppesOrganizationId}
          />
        </Route>
        <Route path={`${match.path}/forms`}>
          <NPPESOrganizationForms nppesOrganizationId={nppesOrganizationId} />
        </Route>
        <Route path={`${match.path}/rules`}>
          <NPPESOrganizationRules nppesOrganizationId={nppesOrganizationId} />
        </Route>
      </Switch>
    </div>
  );
};
